import 'rc-tooltip/assets/bootstrap.css';
import React, { useEffect, useState } from 'react';
import Slider  from 'rc-slider';
import './tierslider.scss';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

export default function TierSlider({
  tierPlans,
  setCurrentProgressTier
})

{
  const [currentPt, setCurrentPt] = useState(null);

  useEffect(() => {
    setCurrentProgressTier(0);
    setCurrentPt(800);
  }, [])

  if(tierPlans) {
    const sliderObj = { min: 0, max: 14,
      values: {...tierPlans}};

    const sliderChange = (value) => {
      const maxValueSelected = sliderObj.values[value[1]];
      setCurrentPt(maxValueSelected);
      setCurrentProgressTier(value[1]);
    }

    const toolTipFormat = () => {
      if (currentPt) {
        return <div className="nos">{currentPt} <span> Visitors</span></div>
      }
      return null;
    }
    return (
      <div className="myslider">
        <Range 
          min={sliderObj.min} 
          defaultValue={[0,0]}
          max={sliderObj.max} 
          marks={sliderObj.values} 
          onChange={sliderChange}
          tipFormatter={toolTipFormat}/>
      </div>
    )
  }
  return null;
}
