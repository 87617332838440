import React, { useState, useEffect } from 'react';
import './videouploader.scss';
import { getSessionStorage } from '../../utils';
import { postReq, getReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import { Storage } from 'aws-amplify';

// Assets
import dragHandleBtn from '../../assets/drag-handle.png';
import CloseBtn from '../../assets/close-btn.png';
import LoadingSpin from '../../components/LoadingSpin';

const VideoUploader = ({
  file,
  id,
  fileNumber,
  DeleteFile,
  videoUploaded,
  setVideoUploaded,
  setCardSkills,
  setVideoFiles,
  editProducts,
  isReplace,
  setIsReplace,
  replaceSkillId,
}) => {
   const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
   
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [name, setName] = useState(file.rename ? file.rename : '');
  const [videoId, setVideoId] = useState('');

  let StoragePromise;

  useEffect(() => {
    if (file && !file.uploaded) {
      videoDurationHandler();
    }
    if (file.src) {
      var video = document.getElementById(`video${fileNumber}`);
      video.src = file.src;
    }
  }, []);

  useEffect(() => {
    if (loadingPercentage === 100) {
      setVideoUploaded(videoUploaded + 1);
      file.uploaded = true;
    }
  }, [loadingPercentage]);

  useEffect(() => {
    file.rename = name;
  }, [name]);

  // GettingVideoDuration
  const videoDurationHandler = () => {
    var video = document.getElementById(`video${fileNumber}`);
    video.preload = 'metadata';
    video.addEventListener('loadedmetadata', function () {
      FileUpload(Math.round(video.duration));
    });
    if (!file.videoDuration) {
      video.src = URL.createObjectURL(file);
    }
  };

  // Uploading File
  const FileUpload = (duration) => {
    try {
      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      // Replace Video
      if (editProducts && isReplace) {
        let url = `${DOMAIN}/v1/dashboard/product/skill/video-path`;
        const req = {
          url,
          requestHeaders,
        };
        getReq(req).then((fileName) => {
          if (fileName.data) {
            setCardSkills((cardSkills) =>
              cardSkills.filter((cardSkill) => cardSkill.id !== replaceSkillId)
            );
            const { path } = fileName.data;
            let videoExt = file.type.split('/')[1];
            if (videoExt !== 'mov' && videoExt !== 'mp4') {
              videoExt = videoExt.includes('.mp4') ? 'mp4' : 'mov';
            }
            StoragePromise = Storage.put(`${path}.${videoExt}`, file, {
              contentType: `video/${videoExt}`,
              progressCallback(progress) {
                let lp = (progress.loaded / progress.total) * 100;
                setLoadingPercentage(lp);
              },
            })
              .then(() => {
                console.log('updating-path');
                url = `${DOMAIN}/v1/dashboard/product/skill/${replaceSkillId}/update-path`;
                const newReq = {
                  url,
                  requestHeaders,
                  payload: {
                    path,
                    videoExt,
                    videoDuration: duration,
                  },
                };
                postReq(newReq).then((resp) => {
                  setIsReplace(false);
                  setCardSkills((cardSkills) => [...cardSkills, resp.data]);
                  setVideoFiles((videoFiles) =>
                    videoFiles.filter((item) => item.skillId === resp.data.id)
                  );
                });
              })
              .catch((e) => {
                console.log('error uploading', e);
              });
          }
        });
      } else {
        let url = `${DOMAIN}/v1/dashboard/product/skill/name`;
        let payload = {
          position: fileNumber,
          id: id,
        };
        const req = {
          url,
          payload,
          requestHeaders,
        };
        postReq(req).then((fileName) => {
          if (fileName.data) {
            const { path, skillId } = fileName.data;
            setVideoId(skillId);
            file.skillId = skillId;
            let videoExt = file.type.split('/')[1];
            if (videoExt !== 'mov' && videoExt !== 'mp4') {
              videoExt = videoExt.includes('.mp4') ? 'mp4' : 'mov';
            }
            console.log('created-skill', path, videoExt, file);
            StoragePromise = Storage.put(`${path}.${videoExt}`, file, {
              contentType: `video/${videoExt}`,
              progressCallback(progress) {
                let lp = (progress.loaded / progress.total) * 100;
                setLoadingPercentage(lp);
              },
            })
              .then(() => {
                console.log('uploaded');
                url = `${DOMAIN}/v1/dashboard/product/skill/${skillId}/update-path`;
                const newReq = {
                  url,
                  requestHeaders,
                  payload: {
                    path,
                    videoExt,
                    videoDuration: duration,
                  },
                };
                postReq(newReq).then((resp) => {
                  file.src = resp.data.content.video;
                  if (editProducts) {
                    setCardSkills((cardSkills) => [...cardSkills, resp.data]);
                    setVideoFiles((videoFiles) =>
                      videoFiles.filter(
                        (videoFile) => videoFile.skillId !== resp.data.id
                      )
                    );
                  }
                });
              })
              .catch((e) => {
                console.log('error uploading', e);
              });
          }
        });
      }
    } catch (e) {
      console.log('some error');
    }
  };

  return (
    <li className="video-uploader" key={fileNumber}>
      {loadingPercentage !== 100 && !file.uploaded && (
        <div className="video-upload-wrapper">
          <div className="video-upload-content">
            <LoadingSpin />
            <span
              style={{
                width: '50px',
                height: '14px',
                textAlign: 'center',
                marginBottom: '5px',
              }}
            >
              {' '}
              {Math.round(loadingPercentage)} %
            </span>
          </div>
          <div className="video-upload-progressbar">
            <div
              style={{ width: `${loadingPercentage}%` }}
              className="video-upload-progress"
            ></div>
          </div>
        </div>
      )}
      {loadingPercentage === 100 && (
        <button
          className="close-btn"
          onClick={() => DeleteFile(videoId, fileNumber)}
        >
          <img src={CloseBtn}></img>
        </button>
      )}

      <video id={`video${fileNumber}`}>
        <source></source>
      </video>
      <div className="video-uploader-title-wrapper">
        <img src={dragHandleBtn}></img>
        <input
          type="text"
          placeholder="Add Video Title"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
      </div>
    </li>
  );
};

export default VideoUploader;
