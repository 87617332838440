import React, { useState, useContext } from 'react';
import './popupeditmember.scss';
import Pulse from 'react-reveal/Pulse';
import ButtonAtom from '../ButtonAtom';
import {
  isEmpty,
  validateEmail,
  getSessionStorage,
  getFormData,
  setSessionStorage,
} from '../../utils';
import Input from '../Input';
import PhnInputAtom from '../PhnInputAtom';
import { DOMAIN } from '../../constants/base';
import { postFormReq } from '../../utils/api';
import { EditMemberContext, GlobalProfile } from '../../context';
import Avatar from 'react-avatar';

// Assets
import BackImg from '../../assets/back.png';
import PopUpRemoveMember from '../PopUpRemoveMember';

const PopUpEditMember = ({
  setEditMemberPopUp,
  isUserProfileEdit,
  fetchActiveMembers,
  userDetails,
}) => {
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
  const [currentMember] = useContext(EditMemberContext);
  const [globalProfile, dispatch] = useContext(GlobalProfile);
  const [deleteAccountPopUp, setDeleteAccountPopUp] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  // Edit Member Details
  const [editMemberName, setEditMemberName] = useState(
    currentMember.currentEditMemberName
      ? currentMember.currentEditMemberName
      : ''
  );
  const [editMemberEmail, setEditMemberEmail] = useState(
    currentMember.currentEditMemberEmail
      ? currentMember.currentEditMemberEmail
      : ''
  );
  const [editMemberPhone, setEditMemberPhone] = useState(
    currentMember.currentEditMemberPhone
      ? currentMember.currentEditMemberPhone
      : {
          phone: '',
          countryCode: '',
          countryName: '',
        }
  );

  const [editMemberId] = useState(
    currentMember.currentEditMemberId ? currentMember.currentEditMemberId : null
  );
  const [editMemberInvitee] = useState(currentMember.currentEditMemberInvitee);
  const [editMemberAdmin] = useState(currentMember.currentEditMemberAdmin);
  const [editMemberOwner] = useState(currentMember.currentEditMemberOwner);
  const [editMemberVerified] = useState(
    currentMember.currentEditMemberVerified
  );

  // Edit Global Profile State

  const [globalProfileId] = useState(
    globalProfile.globalProfileId
      ? globalProfile.globalProfileId
      : userDetails.id
  );
  const [globalProfileAvatar] = useState(
    globalProfile.globalProfileAvatar
      ? globalProfile.globalProfileAvatar
      : userDetails.avatar
  );
  const [globalProfileName, setGlobalProfileName] = useState(
    globalProfile.globalProfileName
      ? globalProfile.globalProfileName
      : userDetails.name
  );
  const [globalProfileEmail, setGlobalProfileEmail] = useState(
    globalProfile.globalProfileEmail
      ? globalProfile.globalProfileEmail
      : userDetails.email
  );
  const [globalProfileAdmin] = useState(
    globalProfile.globalProfileAdmin ? true : false
  );
  const [globalProfileOwner] = useState(
    globalProfile.globalProfileOwner ? true : false
  );

  const [globalProfilePhone, setGlobalProfilePhone] = useState(
    globalProfile.globalProfilePhone?.phone
      ? {
          phone: globalProfile.globalProfilePhone.phone.slice(
            globalProfile.globalProfilePhone.countryCode.length - 1
          ),
          countryCode: globalProfile.globalProfilePhone.countryCode,
          countryName: globalProfile.globalProfilePhone.countryName,
        }
      : {
          phone: userDetails.phone,
          countryCode: userDetails.countryCode,
          countryName: userDetails.countryName,
        }
  );

  //Errors
  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  //Blur Handler
  const fullNameBlurHandler = () => {
    if (!isUserProfileEdit) {
      if (isEmpty(editMemberName)) {
        setFullNameError(true);
      }
    } else {
      if (isEmpty(globalProfileName)) {
        setFullNameError(true);
      }
    }
  };

  const emailBlurHandler = () => {
    if (!isUserProfileEdit) {
      const isValid = !validateEmail(editMemberEmail);
      setEmailError(isValid);
    } else {
      const isValid = !validateEmail(globalProfileEmail);
      setEmailError(isValid);
    }
  };

  const phoneBlurHandler = () => {
    if (!isUserProfileEdit) {
      if (isEmpty(editMemberPhone.phone)) {
        setErrorPhone(true);
      }
    } else {
      if (isEmpty(globalProfilePhone.phone)) {
        setErrorPhone(true);
      }
    }
  };

  // Edit Member

  const handleEditMember = async () => {
    setShowLoader(true);
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    let payload = {
      name: isUserProfileEdit ? globalProfileName : editMemberName,
      countryName: isUserProfileEdit
        ? globalProfilePhone.countryName.toUpperCase()
        : editMemberPhone.countryName
        ? editMemberPhone.countryName.toUpperCase()
        : '',
      countryCode: isUserProfileEdit
        ? globalProfilePhone.countryCode
        : editMemberPhone.countryCode
        ? editMemberPhone.countryCode
        : '',
      phone: isUserProfileEdit
        ? globalProfilePhone.phone
          ? globalProfilePhone.phone.slice(
              globalProfilePhone.countryCode.length - 1
            )
          : ''
        : editMemberPhone.phone
        ? editMemberPhone.phone
        : '',
      id: isUserProfileEdit ? globalProfileId : editMemberId,
    };
    if (
      currentMember.currentEditMemberName !== editMemberName ||
      globalProfile.globalProfileName !== globalProfileName
    ) {
      payload = {
        ...payload,
        name: isUserProfileEdit ? globalProfileName : editMemberName,
      };
    } else if (
      currentMember.currentEditMemberEmail !== editMemberEmail ||
      globalProfile.globalProfileEmail !== globalProfileEmail
    ) {
      payload = {
        ...payload,
        email: isUserProfileEdit ? globalProfileEmail : editMemberEmail,
      };
    } else if (
      (currentMember.currentEditMemberName !== editMemberName &&
        currentMember.currentEditMemberEmail !== editMemberEmail) ||
      (globalProfile.globalProfileName !== globalProfileName &&
        globalProfile.globalProfileEmail !== globalProfileEmail)
    ) {
      payload = {
        ...payload,
        name: isUserProfileEdit ? globalProfileName : editMemberName,
        email: isUserProfileEdit ? globalProfileEmail : editMemberEmail,
      };
    }
    if (!isUserProfileEdit) {
      payload = {
        ...payload,
        invitee: editMemberInvitee,
        entity: 'product',
      };
    }
    const url = `${DOMAIN}/v1/dashboard/product/user/${
      isUserProfileEdit ? 'edit-profile' : 'edit-member'
    }`;

    if (payload.name.length <= 0) {
      setShowLoader(false);
      return;
    }

    const formData = getFormData(payload);
    if (
      !isEmpty(isUserProfileEdit ? globalProfileName : editMemberName) &&
      validateEmail(isUserProfileEdit ? globalProfileEmail : editMemberEmail)
    ) {
      const editResponse = await postFormReq({
        url,
        payload: formData,
        requestHeaders,
      });
      const { error = '', successCode = '', data = {} } = editResponse;
      if (error === 'ERR403') {
        console.log('Error Editing The Member');
        setShowLoader(false);
        return;
      }
      if (error === 'ERR404') {
        console.log('User Not Found');
        setShowLoader(false);
        return;
      }
      if (successCode === 'SUCC200') {
        if (!isUserProfileEdit) {
          fetchActiveMembers();
        } else {
          dispatch({
            type: 'EDIT_GLOBAL_PROFILE',
            payload: {
              id: globalProfileId,
              avatar: data.avatar ? data.avatar : globalProfileAvatar,
              name: data.name ? data.name : globalProfileName,
              email: data.email ? data.email : globalProfileEmail,
              admin: data.admin ? data.admin : globalProfileAdmin,
              phone: globalProfilePhone,
            },
          });
          // localStorage.setItem('ss-auth', JSON.stringify({
          //   ...userDetails,
          //   name: data.name ? data.name : globalProfileName,
          //   email: data.email ? data.email : globalProfileEmail,
          //   phone: globalProfilePhone,
          // }));
          setSessionStorage({
            key: 'ss-user',
            value: {
              ...userDetails,
              name: data.name ? data.name : globalProfileName,
              email: data.email ? data.email : globalProfileEmail,
              phone: globalProfilePhone,
            },
          });
        }
        setEditMemberPopUp(false);
        setShowLoader(false);
        setFullNameError(false);
        setErrorPhone(false);
        setEmailError(false);
      }
    }
  };

  return (
    <>
      <div className="popups-wrapper">
        <Pulse duration="150">
          <div className="form-card-wrapper">
            {' '}
            <button
              className="back-button w-100p d-flex"
              onClick={() => setEditMemberPopUp(false)}
            >
              <img src={BackImg} alt="back"></img>
            </button>
            <div className="image-wrapper-editmember">
              {isUserProfileEdit ? (
                globalProfileAvatar ? (
                  <div className="image-wrapper">
                    <img src={globalProfileAvatar}></img>
                  </div>
                ) : (
                  <Avatar
                    name={
                      isUserProfileEdit ? globalProfileName : editMemberName
                    }
                    size="142"
                    round={true}
                    color="black"
                    maxInitials={1}
                    textSizeRatio={2}
                    textMarginRatio={0}
                    style={{ fontWeight: '700' }}
                  />
                )
              ) : currentMember.currentEditMemberAvatar ? (
                <div className="image-wrapper">
                  <img src={currentMember.currentEditMemberAvatar}></img>
                </div>
              ) : (
                <Avatar
                  name={editMemberName}
                  size="142"
                  round={true}
                  color="black"
                  maxInitials={1}
                  textSizeRatio={2}
                  textMarginRatio={0}
                  style={{ fontWeight: '700' }}
                />
              )}

              <h1 className="mt-20">
                {isUserProfileEdit ? globalProfileName : editMemberName}
              </h1>
              <span
                className={
                  isUserProfileEdit
                    ? globalProfileAdmin
                      ? globalProfileOwner
                        ? 'type-of-user c-dblue'
                        : 'type-of-user c-black'
                      : 'type-of-user basic-user'
                    : editMemberAdmin
                    ? editMemberOwner
                      ? 'type-of-user c-dblue'
                      : 'type-of-user c-black'
                    : 'type-of-user basic-user'
                }
              >
                {isUserProfileEdit
                  ? globalProfileAdmin
                    ? globalProfileOwner
                      ? 'OWNER'
                      : 'ADMIN'
                    : 'MEMBER'
                  : editMemberAdmin
                  ? editMemberOwner
                    ? 'OWNER'
                    : 'ADMIN'
                  : 'MEMBER'}
              </span>
            </div>
            <div className="form-wrapper">
              <div className="input-wrapper mt-20">
                <Input
                  type="text"
                  label="Full Name"
                  labelStyle="uc mb-5"
                  placeholder="John Doe"
                  value={isUserProfileEdit ? globalProfileName : editMemberName}
                  onChange={(e) =>
                    isUserProfileEdit
                      ? setGlobalProfileName(e.target.value)
                      : editMemberInvitee && setEditMemberName(e.target.value)
                  }
                  info={fullNameError ? "CAN'T BE BLANK" : ''}
                  onBlur={fullNameBlurHandler}
                />

                <Input
                  type="text"
                  label="Email"
                  labelStyle="uc mb-5"
                  placeholder="john@holofy.io"
                  value={
                    isUserProfileEdit ? globalProfileEmail : editMemberEmail
                  }
                  info={emailError ? 'ENTER VALID EMAIL' : ''}
                  // onChange={(e) =>
                  //   isUserProfileEdit
                  //     ? setGlobalProfileEmail(globalProfileEmail)
                  //     : editMemberInvitee &&
                  //       setEditMemberEmail(e.target.value)
                  // }
                  onBlur={emailBlurHandler}
                  disabled={true}
                />
                {isUserProfileEdit ? (
                  <PhnInputAtom
                    phone={globalProfilePhone}
                    setPhone={setGlobalProfilePhone}
                    info={errorPhone ? 'PLEASE ENTER PHONE NO' : ''}
                    onBlur={phoneBlurHandler}
                  ></PhnInputAtom>
                ) : (
                  editMemberVerified && (
                    <PhnInputAtom
                      phone={editMemberPhone}
                      setPhone={setEditMemberPhone}
                      info={errorPhone ? 'PLEASE ENTER PHONE NO' : ''}
                      onBlur={phoneBlurHandler}
                    ></PhnInputAtom>
                  )
                )}

                <div className="mt-40">
                  <ButtonAtom
                    label="SAVE DETAILS"
                    onClick={handleEditMember}
                    showLoader={showLoader}
                  ></ButtonAtom>
                </div>
                {isUserProfileEdit && (
                  <div className="mt-20">
                    <button
                      className="link-btn-cta c-red"
                      onClick={() => setDeleteAccountPopUp(true)}
                    >
                      DELETE ACCOUNT
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Pulse>
        {deleteAccountPopUp && (
          <PopUpRemoveMember
            setRemoveMemberPopUp={setDeleteAccountPopUp}
            isUserProfileEdit={isUserProfileEdit}
          ></PopUpRemoveMember>
        )}
      </div>
    </>
  );
};

export default PopUpEditMember;
