import {
  SET_OVERALL_IMPRESSION,
  SET_OVERALL_RETENTION,
  SET_OVERALL_CTAACTIONRATE,
  SET_MOST_VIEWED_TIMES,
  SET_TOP_TRAFFIC_SOURCES,
  SET_SHOW_POPUP,
  SET_LAST_UPDATED,
  SET_PRODUCT_SKILLS,
  SET_PRODUCT_IMPRESSION_CTA,
  SET_PRODUCT_VIDEO_PERFORMANCE,
  SET_SKILL_STATS_POPUP,
  SET_TRAFFIC_SOURCES_BY_PRODUCT,
  SET_ACTIVE_PRODUCT,
  SET_RETENTION_RATE_BY_PRODUCT,
} from './types.js';

const setLastUpdated = ({data}, state) => {
  const newLastUpdated = {
    ...state,
    lastUpdated: data,
  };
  return {
    ...newLastUpdated,
  };
};

const setOverallImpression = (
  { value, graphPlot, startDate, endDate, accountCreated },
  state
) => {
  const newOverallImpression = {
    ...state,
    overallImpressions: {
      value: value === null ? 0 : value,
      graphPlot,
      startDate: startDate,
      endDate: endDate,
      accountCreated: accountCreated,
    },
  };
  return {
    ...newOverallImpression,
  };
};
const setOverallRetention = (
  { value, graphPlot, startDate, endDate, accountCreated },
  state
) => {
  const newOverallRetention = {
    ...state,
    overallRetention: {
      value: value === null ? 0 : value,
      graphPlot,
      startDate: startDate,
      endDate: endDate,
      accountCreated: accountCreated,
    },
  };
  return {
    ...newOverallRetention,
  };
};

const setOverallCTAActionRate = (
  { value, graphPlot, startDate, endDate, accountCreated },
  state
) => {
  const newCTA = {
    ...state,
    overallCTA: {
      value: value === null ? 0 : value,
      graphPlot,
      startDate: startDate,
      endDate: endDate,
      accountCreated: accountCreated,
    },
  };
  return {
    ...newCTA,
  };
};

const setMostViewedTimes = ({ graph, xAxis, yAxis }, state) => {
  const newMostViewedTimes = {
    ...state,
    mostViewedTimes: {
      graph,
      xAxis,
      yAxis,
    },
  };
  return {
    ...newMostViewedTimes,
  };
};

const setTopTrafficSources = ({ graph, startDate, endDate , accountCreated }, state) => {
  const newTopTrafficSources = {
    ...state,
    topTrafficSources: {
      graph,
      startDate,
      endDate,
      accountCreated: accountCreated,
    },
  };
  return {
    ...newTopTrafficSources,
  };
};

// Pop Up updates
const setShowPopUp = ({ showPopUp, currentGraphType, isDetail }, state) => {
  const newShowPopUp = {
    ...state,
    currentGraphType,
    showPopUp,
    isDetail,
  };
  return {
    ...newShowPopUp,
  };
};

// detail product stats
const setProductSkills = ({ skills }, state) => {
  const newProductSkills = {
    ...state,
    skills,
  };
  return {
    ...newProductSkills,
  };
};

const setImpressionCTARate = (
  { graphPlot, impression, ctaRate, name, productId },
  state
) => {
  const newIMPCTA = {
    ...state,
    impressionCTARate: {
      graphPlot,
      impression: impression === null ? 0 : impression,
      ctaRate: ctaRate === null ? 0 : ctaRate,
      name,
      productId,
    },
  };
  return {
    ...newIMPCTA,
  };
};

const setProductVideoPerformance = (
  { avgWatchTime, avgRetentionRate, skills },
  state
) => {
  const newVideoPerformance = {
    ...state,
    productVideoPerformance: {
      avgWatchTime: avgWatchTime === null ? 0 :avgWatchTime,
      avgRetentionRate: avgRetentionRate === null ? 0 : avgRetentionRate,
      skills,
    },
  };
  return newVideoPerformance;
};

const setSkillStatsPopup = (
  { name, poster, views, engagement, avgWatchTime, graphPlot, endTimeInSecond},
  state
) => {
  const newSkillStats = {
    ...state,
    skillStats: {
      name,
      poster,
      views,
      engagement: engagement === null ? 0 : engagement,
      avgWatchTime: avgWatchTime === null ? 0 : avgWatchTime,
      graphPlot,
      endTimeInSecond,
    },
  };
  return newSkillStats;
};

const setTrafficSourcesByProduct = (
  { graphPlot, startDate, endDate },
  state
) => {
  const newActiveProduct = {
    ...state,
    productTrafficSources: {
      graphPlot: graphPlot,
      startDate: startDate,
      endDate: endDate,
    },
  };
  return newActiveProduct;
};

const setActiveProductDetails = ({ productName, poster, productId,createdDate }, state) => {
  const newActiveProduct = {
    ...state,
    activeProduct: {
      productName,
      poster,
      productId,
      createdDate,
    },
  };
  return newActiveProduct;
};

const setRetentionRateByProduct = (
  { value, graphPlot, startDate, endDate },
  state
) => {
  const newProductRetention = {
    ...state,
    productRetention: {
      value: value === null ? 0 : value,
      graphPlot,
      startDate,
      endDate,
    },
  };
  return newProductRetention;
};

export default (state, action) => {
  switch (action.type) {
    case SET_OVERALL_IMPRESSION:
      return setOverallImpression(action.payload, state);
    case SET_OVERALL_RETENTION:
      return setOverallRetention(action.payload, state);
    case SET_OVERALL_CTAACTIONRATE:
      return setOverallCTAActionRate(action.payload, state);
    case SET_MOST_VIEWED_TIMES:
      return setMostViewedTimes(action.payload, state);
    case SET_TOP_TRAFFIC_SOURCES:
      return setTopTrafficSources(action.payload, state);
    case SET_SHOW_POPUP:
      return setShowPopUp(action.payload, state);
    case SET_LAST_UPDATED:
      return setLastUpdated(action.payload, state);
    case SET_PRODUCT_SKILLS:
      return setProductSkills(action.payload, state);
    case SET_PRODUCT_IMPRESSION_CTA:
      return setImpressionCTARate(action.payload, state);
    case SET_PRODUCT_VIDEO_PERFORMANCE:
      return setProductVideoPerformance(action.payload, state);
    case SET_SKILL_STATS_POPUP:
      return setSkillStatsPopup(action.payload, state);
    case SET_TRAFFIC_SOURCES_BY_PRODUCT:
      return setTrafficSourcesByProduct(action.payload, state);
    case SET_ACTIVE_PRODUCT:
      return setActiveProductDetails(action.payload, state);
    case SET_RETENTION_RATE_BY_PRODUCT:
      return setRetentionRateByProduct(action.payload, state);
    default:
      return state;
  }
};
