import React, { useState } from 'react';
import './checkbox.scss';

const CheckboxAtom = ({ isChecked, setIsChecked }) => {
  return (
    <>
      <label className="label-checkbox">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <span class="fake-checkbox"></span>
        <span class="text">
          I agree with the{' '}
          <a href="https://www.holofy.io/termsandconditions" target="_blank">
            Terms & Conditions
          </a>
          <br></br> and{' '}
          <a
            onClick={() => {
              window.open('https://www.holofy.io/privacypolicy', '_blank');
            }}
          >
            Privacy Policy
          </a>
        </span>
      </label>
    </>
  );
};

export default CheckboxAtom;
