import React, { useReducer } from 'react';
import TopBar from '../../components/TopBar';
import TeamsComponent from '../../containers/Teams';

// State Management
import { EditMemberContext } from '../../context';
import memberReducer from '../../context/memberReducer';

function Teams() {
  const initialState = {
    currentEditMemberId: '',
    currentEditMemberAvatar: '',
    currentEditMemberName: '',
    currentEditMemberEmail: '',
    currentEditMemberAdmin: '',
    currentEditMemberPhone: {
      phone: '',
      countryCode: '',
      countryName: '',
    },
    currentEditMemberVerified: null,
    currentEditMemberInvitee: null,
  };

  const [state, dispatch] = useReducer(memberReducer, initialState);
  return (
    <>
      <EditMemberContext.Provider value={[state, dispatch]}>
        <TopBar></TopBar>
        <TeamsComponent />
      </EditMemberContext.Provider>
    </>
  );
}

export default Teams;
