import React from 'react';
import MobileBlockComponent from '../../containers/MobileBlock';

function MobileBlock() {
  return (
    <>
      <MobileBlockComponent />
    </>
  );
}

export default MobileBlock;
