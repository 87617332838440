import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import Search from '../../components/Search';
import PropCard from '../../components/PropCard';
import NavBar from '../../components/NavBar';
import { isEmpty, getSessionStorage } from '../../utils';
import { getReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import ReactPaginate from 'react-paginate';
import './index.scss';
import PopUpMagicPairing from '../../components/PopUpMagicPairing';
import PopUpAddNewItem from '../../components/PopUpAddNewItem';
import PopUpCTA from '../../components/PopUpCTA';
import PopUpRemoveProduct from '../../components/PopUpRemoveProduct';
import { currentProductContext } from '../../context';
import PopUpEmptySkill from '../../components/PopUpEmptySkill';
import ProductsCard from '../../components/ProductsCard';

// Assets
// import RemoveTextImg from '../../assets/ClearText.png';
import RemoveTextImg from '../../assets/removeText.svg';
import AddIcon from '../../assets/addIconBlack.png';
import PopUpEditProduct from '../../components/PopUpEditProduct';
// import EmptyBanner from '../../assets/EmptyStateBanner.png';
import PopUpEditVideos from '../../components/PopUpEditVideos';
import SearchImg from '../../assets/search.svg';
import EditIcon from '../../assets/editnew.svg';
import DeleteIcon from '../../assets/delete-btn.svg';
import UnpublishIcon from '../../assets/eye-slash.svg';
import CheckIcon from '../../assets/check-btn.svg';

function MyProducts() {
  // Context
  const [currentProduct, dispatch] = useContext(currentProductContext);

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isActiveProductsLoading, setActiveProductsLoading] = useState(true);
  const [toggleActiveIndex, setToggleActiveIndex] = useState('');

  const [noData, setNoData] = useState(false);
  const [changeState, setChangeState] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  // const [skills, setSkills] = useState([]);
  const [selectedCardsProps, setSelectedCardsProps] = useState({});

  // Pop Up States
  const [openMagicLinkPopUp, setMagicLinkPopUp] = useState(false);
  // const [openHashtagPopUp, setHashtagPopUp] = useState(false);
  const [openNewItemPopUp, setNewItemPopUp] = useState(false);
  const [openCTAPopUp, setOpenCTAPopUp] = useState(false);
  const [openRemoveProductPopUp, setOpenRemoveProductPopUp] = useState(false);
  const [openEditProductPopUp, setOpenEditProductPopUp] = useState(false);
  const [openEditVideosPopUp, setOpenEditVideosPopUp] = useState(false);
  const [openEmptySkillPopUp, setOpenEmptySkillPopUp] = useState(false);

  // Pagination States
  const [activeProducts, setActiveProducts] = useState(null);
  const [serachParam, setSearchParam] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [pageMetadata, setPageMetadata] = useState({
    totalDocs: 0,
    totalPages: 0,
  });

  const [isSettingMenuOpen, setSettingMenuOpen] = useState(false);
  const [addSkill, setAddSkill] = useState(false);

  // Status Messages
  const [statusMsg, setStatusMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [statusType, setStatusMsgType] = useState('error');

  const isMountedRef = React.useRef(null);
  const perPage = 4;

  // Get User Current Tier Details
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};

  useEffect(() => {
    isMountedRef.current = true;
    if (isEmpty(userToken)) {
      setShouldRedirect(true);
      return;
    }
    fetchActiveProducts();
    return () => (isMountedRef.current = false);
  }, [changeState]);

  useEffect(() => {
    if (statusMsg) {
      setTimeout(() => {
        setStatusMsg(false);
      }, 1500);
    }
  }, [statusMsg]);

  useEffect(() => {
    if (openNewItemPopUp) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openNewItemPopUp]);

  useEffect(() => {
    if (openEditProductPopUp) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openEditProductPopUp]);

  if (shouldRedirect) {
    return <Redirect to="/signin" />;
  }

  async function fetchActiveProducts() {
    setActiveProductsLoading(true);
    const url = `${DOMAIN}/v1/dashboard/products/page/${pageNo}?searchStr=${serachParam}`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeProductsResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = activeProductsResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      if (data && data.docs[0] && data.docs[0].currency) {
        dispatch({
          type: 'SET_CURRENCY',
          payload: { currency: data.docs[0].currency },
        });
      }
      setPageMetadata({
        totalDocs: data.totalDocs,
        totalPages: data.totalPages,
      });
      if (isMountedRef.current) {
        setActiveProducts(data.docs);
        setActiveProductsLoading(false);
        if (data.docs.length === 0 && serachParam.length === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
      }
    }
  }

  // select cards method
  const selectCardMethod = (selectedId, isSelected) => {
    if (editMode) {
      let cards = [...selectedCards];
      let index = cards.indexOf(selectedId);
      if (isSelected) {
        cards.push(selectedId);
      } else if (!isSelected) {
        cards.splice(index, 1);
      }
      setSelectedCards(cards);
    }
    return;
  };

  const selectedProduct = (property) => {
    setSelectedCardsProps(property);
  };

  // Rendering Active Spaces
  const renderActiveProducts = () => {
    let propArr = [];
    propArr = activeProducts.map((prop, index) => (
      // <PropCard
      //   key={index}
      //   property={prop}
      //   index={index}
      //   setMenuState={false}
      //   toggleCopy={toggleActiveCopy}
      //   isTogggle={index === toggleActiveIndex}
      //   setMagicLinkPopUp={setMagicLinkPopUp}
      //   setOpenCTAPopUp={setOpenCTAPopUp}
      //   setOpenRemoveProductPopUp={setOpenRemoveProductPopUp}
      //   setOpenEditProductPopUp={setOpenEditProductPopUp}
      //   setSettingMenuOpen={setSettingMenuOpen}
      //   isSettingMenuOpen={isSettingMenuOpen}
      //   setOpenEditVideosPopUp={setOpenEditVideosPopUp}
      //   setOpenEmptySkillPopUp={setOpenEmptySkillPopUp}
      //   setAddSkill={setAddSkill}
      // />
      <ProductsCard
        key={index}
        property={prop}
        editMode={editMode}
        selectCardMethod={selectCardMethod}
        selectedCards={selectedCards}
        setOpenEditProductPopUp={setOpenEditProductPopUp}
        selectedProduct={selectedProduct}
      />
    ));
    return propArr;
  };

  const toggleActiveCopy = (index) => {
    if (index === toggleActiveIndex) {
      setToggleActiveIndex('');
      return;
    }
    setToggleActiveIndex(index);
  };

  let timer;
  const searchActiveProducts = (value) => {
    if (!isActiveProductsLoading) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setPageNo(1);
        setSearchParam(value);
        setChangeState(!changeState);
      }, 1000);
    }
  };

  // Pagination Handler
  const handlePageClick = (e) => {
    let selectedPage = e.selected;
    setPageNo(selectedPage + 1);
    setChangeState(!changeState);
  };

  //toggle edit mode on and off
  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  // clear selected cards while clicking done
  const clearCards = () => {
    setSelectedCards([]);
    toggleEdit();
  };

  const toggleRemoveProduct = () => {
    setOpenRemoveProductPopUp(!openRemoveProductPopUp);
  };

  return (
    <>
      <div className="d-flex main-dash-container">
        <NavBar selected="mySpaces" />

        {activeProducts &&
          (noData ? (
            <div
              className="w-100p mt-20 pl-20 pr-20"
              style={{ height: 'calc(100vh - 68px)' }}
            >
              <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 mt-9">
                <h1>Your Products</h1>
              </div>
              <div className="first-product-container center-fix">
                <div
                  className="first-product-container-item cur-pointer center-align d-flex"
                  onClick={() => setNewItemPopUp(true)}
                >
                  <div className="first-product-container-item-plus" />
                </div>
                <h4 className="text-center">Add your first product</h4>
                <span className="text-center">
                  Create your first collection of videos and pair them <br /> to
                  your website
                </span>
              </div>
              {/* <div className="pos-rel w-100p bg-white br-20 empty-state-height">
                <h2 className="text-center fw-700 fs-32 mb-0">
                  Create your first products
                </h2>
                <h4 className="text-center fw-600 fs-12 c-sub lh-16 mt-6 fw-600">
                  Upload video collections, attach custom CTA’s and pair
                  <br /> them to your website.
                </h4>
                <img
                  className="mxw-100p center-fix top-58p w-65p"
                  src={EmptyBanner}
                />
                <div className="w-100p center-align d-flex pos-abs custom-bottom">
                  <button
                    className="empty-state-add-new"
                    onClick={() => setNewItemPopUp(true)}
                  >
                    {' '}
                    Add New Product
                  </button>
                </div>
              </div> */}
            </div>
          ) : (
            <div
              className="w-100p mt-20 ml-20 mr-20 content-width"
              style={{ height: 'calc(100vh - 68px)' }}
            >
              <div className="d-flex f-col space-between mb-20">
                <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 h-60">
                  <h1>Your Products</h1>
                  <div className="d-flex">
                    <div className="d-flex mt-5">
                      <Search
                        type="text"
                        labelStyle="mb-10"
                        placeholder="Search products"
                        onChange={searchActiveProducts}
                        prependImg={SearchImg}
                        appendImg={RemoveTextImg}
                        smallSearchBar={true}
                        setCurrentPage={() => {}}
                        setCurrentSearchTerm={() => {}}
                      />
                    </div>
                    <button
                      className="button-link ml-15 mt-5"
                      onClick={() => setNewItemPopUp(true)}
                    >
                      {' '}
                      <img src={AddIcon}></img> Add New Item
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-end mt-20">
                  {editMode ? (
                    <>
                      <button
                        className="button-delete-link"
                        onClick={toggleRemoveProduct}
                        disabled={selectedCards.length === 0}
                      >
                        {' '}
                        <img src={DeleteIcon}></img> DELETE
                      </button>
                      {/* <button
                        className="button-edit-link ml-15"
                        onClick={toggleEdit}
                      >
                        {' '}
                        <img src={UnpublishIcon}></img> UNPUBLISH
                      </button> */}
                      <button
                        className="button-done-link ml-15"
                        onClick={clearCards}
                      >
                        {' '}
                        <img src={CheckIcon}></img> DONE
                      </button>
                    </>
                  ) : (
                    <button className="button-edit-link" onClick={toggleEdit}>
                      {' '}
                      <img src={EditIcon}></img> EDIT
                    </button>
                  )}
                </div>
                <hr />
                {/* <div className="products-search-wrapper mt-20">
                  <button>
                    <span className="center-align fs-10 c-black space-count mb-10 mt-8">
                      {`${
                        isActiveProductsLoading
                          ? 'loading'
                          : pageMetadata.totalDocs
                      } Items`}
                    </span>
                  </button>
                  <Search
                    type="text"
                    labelStyle="mb-10"
                    placeholder="Search"
                    onChange={searchActiveProducts}
                    appendImg={RemoveTextImg}
                    setCurrentPage={() => {}}
                  />
                  <div className="search-hr" />
                </div> */}
              </div>
              <div className="product-section">
                <div className="active-products-wrapper overflow-scroll">
                  <div className="active-list">
                    <div className="products-wrapper mt-40 mr-20">
                      {isActiveProductsLoading ? (
                        <div className="loading-space">
                          Getting your Products. Please wait...
                        </div>
                      ) : activeProducts.length > 0 ? (
                        renderActiveProducts()
                      ) : (
                        <div className="loading-space">
                          No data available for the searched item...
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {pageMetadata.totalPages > 1 && (
                  <div className="pagination-wrapper">
                    <ReactPaginate
                      currentPage={pageNo - 1}
                      pageCount={pageMetadata.totalPages}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={2}
                      previousLabel={'❮'}
                      nextLabel={'❯'}
                      breakLabel={'---'}
                      onPageChange={handlePageClick}
                      initialPage={0}
                      currentPage={0}
                      containerClassName={'paginate-container'}
                      pageClassName={'page-item'}
                      activeClassName={'page-item-active'}
                      pageLinkClassName={'page-item-link'}
                      activeLinkClassName={'active-link'}
                      nextClassName={'next-previous-class'}
                      previousClassName={'next-previous-class'}
                      nextLinkClassName={'next-previous-link-class'}
                      previousLinkClassName={'next-previous-link-class'}
                      disabledClassName={'disabled-class'}
                      forcePage={pageNo - 1}
                    ></ReactPaginate>
                  </div>
                )}
              </div>
            </div>
          ))}
        {openMagicLinkPopUp ? (
          <PopUpMagicPairing
            setMagicLinkPopUp={setMagicLinkPopUp}
            fetchActiveSpaces={fetchActiveProducts}
          ></PopUpMagicPairing>
        ) : (
          ''
        )}
        {/* {openHashtagPopUp ? (
          <PopUpHashtag
            setHashtagPopUp={setHashtagPopUp}
            currentHashtagValues={currentHashtagValues}
            setCurrentHashtagValues={setCurrentHashtagValues}
            currentProductId={currentProductId}
            productName={currentProductName}
          ></PopUpHashtag>
        ) : (
          ''
        )} */}
        {openNewItemPopUp ? (
          <PopUpAddNewItem
            showPopupItem={setNewItemPopUp}
            fetchActiveProducts={fetchActiveProducts}
          ></PopUpAddNewItem>
        ) : (
          ''
        )}
        {openCTAPopUp ? (
          <PopUpCTA
            setOpenCTAPopUp={setOpenCTAPopUp}
            fetchActiveSpaces={fetchActiveProducts}
          ></PopUpCTA>
        ) : (
          ''
        )}
        {openRemoveProductPopUp ? (
          <PopUpRemoveProduct
            setOpenRemoveProductPopUp={setOpenRemoveProductPopUp}
            fetchActiveSpaces={fetchActiveProducts}
            selectedCards={selectedCards}
            setSelectedCards={setSelectedCards}
          ></PopUpRemoveProduct>
        ) : (
          ''
        )}
        {openEditProductPopUp ? (
          <PopUpEditProduct
            setOpenEditProductPopUp={setOpenEditProductPopUp}
            fetchActiveProducts={fetchActiveProducts}
            activeProducts={activeProducts}
            selectedCardsProps={selectedCardsProps}
          ></PopUpEditProduct>
        ) : (
          ''
        )}
        {openEditVideosPopUp && (
          <PopUpEditVideos
            fetchActiveProducts={fetchActiveProducts}
            setOpenEditVideosPopUp={setOpenEditVideosPopUp}
            fetchActiveSpaces={fetchActiveProducts}
            addSkill={addSkill}
          ></PopUpEditVideos>
        )}
        {openEmptySkillPopUp && (
          <PopUpEmptySkill
            fetchActiveProducts={fetchActiveProducts}
            setOpenEmptySkillPopUp={setOpenEmptySkillPopUp}
            fetchActiveSpaces={fetchActiveProducts}
          ></PopUpEmptySkill>
        )}
        {/* Showing blocker screen */}
      </div>
    </>
  );
}

export default MyProducts;
