import React, { useContext } from 'react';
import './popupremoveproduct.scss';
import { DOMAIN } from '../../constants/base';
import { getSessionStorage } from '../../utils';
import Pulse from 'react-reveal/Pulse';
import { currentProductContext } from '../../context';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';

const PopUpRemoveProduct = ({
  setOpenRemoveProductPopUp,
  fetchActiveSpaces,
  selectedCards,
  setSelectedCards
}) => {
   const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
   

  //remove multiple cards
  const deleteProducts = () => {
    const url = `${DOMAIN}/v1/dashboard/product/multiple`;
    const payload = {
      productIds: selectedCards 
    }
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: userToken.accessToken,
      },
      body: JSON.stringify(payload),
    }).then((resp) => {
      setOpenRemoveProductPopUp(false);
      setSelectedCards([])
      fetchActiveSpaces();
    });
  };

  return (
    <div className="popups-wrapper">
      <ClickAwayContentWrapper onClose={() => setOpenRemoveProductPopUp(false)}>
        <Pulse duration="150">
          <div className="remove-card-wrapper">
            <div className="form-wrapper">
              <h1 className="delete-products-heading">
                Are you sure you want to delete <span>{selectedCards.length} {selectedCards.length === 1 ? 'product' : 'products'}</span>?
              </h1>
              <p>
              All associated Videos, Stats and CTA Information will be permanently deleted and it will no longer be visible on your website.
              </p>
              <div className="remove-btn-wrapper mt-30 w-100p">
                <button
                  onClick={() => setOpenRemoveProductPopUp(false)}
                  className="white"
                >
                  GO BACK
                </button>
                <button className="transparent" onClick={deleteProducts}>REMOVE</button>
              </div>
            </div>
          </div>
        </Pulse>
      </ClickAwayContentWrapper>
    </div>
  );
};

export default PopUpRemoveProduct;
