import { data } from 'currency-codes';

export const isEmpty = (obj) => {
  if (obj instanceof Date) {
    return false;
  }
  if (obj == null || obj === 'undefined') {
    return true;
  }

  const isNumber = (value) =>
    Object.prototype.toString.call(value) === '[object Number]';
  const isNaN = (value) => isNumber(value) && value.toString() === 'NaN';

  if (isNumber(obj)) {
    return isNaN(obj);
  }

  if (obj.length > 0) {
    return false;
  }
  if (obj.length === 0) {
    return true;
  }

  if (typeof obj !== 'object') {
    return true;
  }

  const keys = Object.keys(obj);
  for (let i = 0, key = keys[i]; i < keys.length; i += 1) {
    if (hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
};

export const validateEmail = (email) => {
  const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/g;
  if (!reg.test(email)) {
    return false;
  }
  return true;
};

export const validateCompany = (website) => {
  const reg =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  if (!reg.test(website)) {
    return false;
  }
  return true;
};

export const apiPostFormDataHelper = async ({
  url,
  payload = {},
  requestHeaders,
}) => {
  try {
    let headers = {};
    if (!isEmpty(requestHeaders)) {
      headers = {
        ...requestHeaders,
      };
    }

    const responseData = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers,
      referrerPolicy: 'no-referrer',
      body: payload,
      redirect: 'follow',
    });
    return responseData.json();
  } catch (e) {
    return e || {};
  }
};
export const apiPutFormDataHelper = async ({
  url,
  payload = {},
  requestHeaders,
}) => {
  try {
    let headers = {};
    if (!isEmpty(requestHeaders)) {
      headers = {
        ...requestHeaders,
      };
    }

    const responseData = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers,
      referrerPolicy: 'no-referrer',
      body: payload,
      redirect: 'follow',
    });
    return responseData.json();
  } catch (e) {
    return e || {};
  }
};
export const apiPostHelper = async ({
  url,
  payload = {},
  contentType,
  requestHeaders,
}) => {
  try {
    let contentTypeText = 'application/json';
    if (contentType) {
      contentTypeText = contentType;
    }
    let headers = {
      Accept: 'application/json',
      'Content-Type': contentTypeText,
    };
    if (!isEmpty(requestHeaders)) {
      headers = {
        ...headers,
        ...requestHeaders,
      };
    }
    // const responseData = await axios.post(URL, PAYLOAD, {
    //   headers
    // });
    const responseData = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers,
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(payload),
    });
    return responseData.json();
  } catch (e) {
    return e || {};
  }
};
export const apiPutHelper = async ({
  url,
  payload = {},
  contentType,
  requestHeaders,
}) => {
  try {
    let contentTypeText = 'application/json';
    if (contentType) {
      contentTypeText = contentType;
    }
    let headers = {
      Accept: 'application/json',
      'Content-Type': contentTypeText,
    };
    if (!isEmpty(requestHeaders)) {
      headers = {
        ...headers,
        ...requestHeaders,
      };
    }
    // const responseData = await axios.post(URL, PAYLOAD, {
    //   headers
    // });
    const responseData = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers,
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(payload),
    });
    return responseData.json();
  } catch (e) {
    return e || {};
  }
};
export const apiGetHelper = async ({
  url,
  payload = {},
  contentType,
  requestHeaders,
}) => {
  try {
    let contentTypeText = 'application/json';
    if (contentType) {
      contentTypeText = contentType;
    }
    let headers = {
      Accept: 'application/json',
      'Content-Type': contentTypeText,
    };
    if (!isEmpty(requestHeaders)) {
      headers = {
        ...headers,
        ...requestHeaders,
      };
    }
    // const responseData = await axios.post(URL, PAYLOAD, {
    //   headers
    // });
    const responseData = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers,
      referrerPolicy: 'no-referrer',
    });
    return responseData.json();
  } catch (e) {
    return e.response || {};
  }
};

export const getParameterByName = (name) => {
  const url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const setSessionStorage = (data) => {
  if (typeof Storage !== 'undefined') {
    let { key, value, expiry } = data;
    const now = new Date().getTime();
    const item = {
      value,
      expiry: expiry ? now + expiry * 60 * 1000 : undefined,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  } else {
    console.log('No Web Storage support...');
  }
};
export const getSessionStorage = (key) => {
  if (typeof Storage !== 'undefined') {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) {
      return;
    }
    const item = JSON.parse(itemStr);
    const { value, expiry } = item;
    const now = new Date();
    if (expiry && now.getTime() > expiry) {
      sessionStorage.removeItem(key);
      return;
    }
    return value;
  } else {
    console.log('No Web Storage support...');
  }
};
export const removeSessionStorage = (key) => {
  if (typeof Storage !== 'undefined') {
    return sessionStorage.removeItem(key);
  } else {
    console.log('No Web Storage support...');
  }
};

let timer;
export const debounce = (fn, delay) => {
  return (...args) => {
    let context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.call(context, ...args);
    }, delay);
  };
};

export const getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.set(key, object[key]));
  return formData;
};

export const setCookie = (name, value, daysToLive) => {
  var cookie = name + '=' + encodeURIComponent(value);
  if (typeof daysToLive === 'number') {
    cookie += '; max-age=' + daysToLive * 24 * 60 * 60;
    document.cookie = cookie;
  }
};

export const getCookie = (name) => {
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

export const getUniqueListByObjectKey = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const toolTipDateFormat = (date) => {
  // 2021-11-11 / YYYY-MM-DD
  let month = date.substring(5, 7);
  let dateNumber = date.substring(8);
  let j = parseInt(dateNumber) % 10;
  let k = parseInt(dateNumber) % 100;
  if (j == 1 && k != 11) {
    dateNumber += 'ST';
  } else if (j == 2 && k != 12) {
    dateNumber += 'ND';
  } else if (j == 3 && k != 13) {
    dateNumber += 'RD';
  } else {
    dateNumber += 'TH';
  }
  switch (month) {
    case '01':
      return 'JAN, ' + dateNumber;
    case '02':
      return 'FEB, ' + dateNumber;
    case '03':
      return 'MAR, ' + dateNumber;
    case '04':
      return 'APR, ' + dateNumber;
    case '05':
      return 'MAY, ' + dateNumber;
    case '06':
      return 'JUN, ' + dateNumber;
    case '07':
      return 'JUL, ' + dateNumber;
    case '08':
      return 'AUG, ' + dateNumber;
    case '09':
      return 'SEP, ' + dateNumber;
    case '10':
      return 'OCT, ' + dateNumber;
    case '11':
      return 'NOV, ' + dateNumber;
    case '12':
      return 'DEC, ' + dateNumber;
    default:
      return;
  }
};
