import React, { useState, useEffect, useContext, useRef } from 'react';
import './popupaddnewitem.scss';
import cc from 'currency-codes';
import Pulse from 'react-reveal/Pulse';
import Input from '../../components/Input';
import { useDropzone } from 'react-dropzone';
import { isEmpty, getSessionStorage, validateCompany } from '../../utils';
import { postReq, putReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import 'react-circular-progressbar/dist/styles.css';
import { currentProductContext } from '../../context';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import getSymbolFromCurrency from 'currency-symbol-map';
import { toast } from 'react-toastify';
// Assets
import BackImg from '../../assets/back.png';
import addIconBlack from '../../assets/addIconBlack.png';
import minusIcon from '../../assets/minus.png';
import CloseHashtagsBtn from '../../assets/close-hashtags.png';
import dragHandleBtn from '../../assets/drag-handle.png';
import UploadIcon from '../../assets/add.svg';
import BreadcrumbIcon from '../../assets/breadcrumb-arr.png';
import VideoUploader from '../VideoUploader';
import ClearButton from '../../assets/ClearText.png';
import AlertIcon from '../../assets/alert.png';
import BreadcrumbIconWhite from '../../assets/breadcrumb-white.png';

const PopUpAddNewItem = ({ showPopupItem, fetchActiveProducts }) => {
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
  const userDetails = getSessionStorage('ss-user') || {};

  const [currentProduct] = useContext(currentProductContext);
  const [videoFiles, setVideoFiles] = useState([]);
  const [currentScreen, setCurrentScreen] = useState('1');
  const [webPages, setWebPages] = useState(['']);

  // Product Details States
  const [productId, setProductId] = useState(null);
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState();
  const [currency, setCurrency] = useState(
    currentProduct.currentCurrency ? currentProduct.currentCurrency : 'AED'
  );
  const [hashtags, setHashtags] = useState(['']);
  const [scrollHashTag, setScrollHashTag] = useState(false);
  const [showVideosUpload, setShowVideosUpload] = useState(false);
  const [enablePrice, setEnablePrice] = useState(false);
  const [enableHashtags, setEnableHashtags] = useState(false);
  const [holofyRef, setHolofyRef] = useState('');

  // Uploading Stage
  const [videoUploaded, setVideoUploaded] = useState(0);
  const [enableVideoContinue, setEnableVideoContinue] = useState(false);

  // Errors
  const [productNameError, setProductNameError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [webPagesError, setWebPagesError] = useState(true);
  const [webpagesErrorMsg, setWebpagesErrorMsg] = useState('');
  const [widgetLiveWarning, setWidgetLiveWarning] = useState(true);

  const [currentErrorIndex, setCurrentErrorIndex] = useState();

  // Dargging Column
  const { DragColumn } = ReactDragListView;

  // Handling Back Btn
  const handleBackBtn = () => {
    if (currentScreen === '1') {
      setCurrentScreen('1');
      showPopupItem(false);
    } else if (currentScreen === '2' && videoFiles.length === videoUploaded) {
      setCurrentScreen('1');
    } else if (currentScreen === '3') {
      setCurrentScreen('2');
    }
  };

  // File Utilities
  const DeleteFile = (skillId, fileNumber) => {
    const url = `${DOMAIN}/v1/dashboard/skill/${skillId}`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: userToken.accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setVideoUploaded(videoUploaded - 1);
        const newData = videoFiles.filter((item) => item.id !== fileNumber);
        setVideoFiles(newData);
      });
  };

  // Hashtags Utility
  const addHashtag = () => {
    setHashtags([...hashtags, '']);
    setScrollHashTag(true);
  };

  const hashtagChangeHandler = (id, value) => {
    const newHashtagsValue = [...hashtags];
    newHashtagsValue[id] = value;
    setHashtags(newHashtagsValue);
  };

  const removeHashtag = (id) => {
    var filteredArr = hashtags.filter(function (value, index, arr) {
      return index !== id;
    });
    setHashtags(filteredArr);
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() =>
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    );
    return <div ref={elementRef} />;
  };

  // Creating Product - Product Details Screen
  const createProduct = async () => {
    if (isEmpty(productName)) {
      setCurrentScreen('1');
      setProductNameError(true);
    } else {
      if (productId) {
        setCurrentScreen('2');
      } else {
        const url = `${DOMAIN}/v1/dashboard/product/create-product`;

        let payload = {
          name: productName,
        };

        if (enablePrice && price) {
          payload = {
            ...payload,
            price: price ? parseFloat(price) : 0,
            currency: currency ? currency : 'AED',
          };
        }
        if (enableHashtags && hashtags.length > 0) {
          let hashtagObj = [];
          for (const [_index, key] of hashtags.entries()) {
            if (key.length) {
              hashtagObj = [...hashtagObj, key.trim()];
            }
          }
          payload = {
            ...payload,
            hashTags: hashtagObj,
          };
        }

        const requestHeaders = {
          authorization: userToken.accessToken,
        };
        const editResponse = await postReq({
          url,
          payload,
          requestHeaders,
        });
        const { error = '', successCode = '', data = {} } = editResponse;

        if (error === 'ERR403') {
          console.log('Something Happened Bad', error);
          return;
        }
        if (error === 'ERR404') {
          console.log('User Not Found');
          return;
        }
        if (successCode === 'SUCC201') {
          if (data) {
            setProductId(data.id);
            setHolofyRef(data.holofyRef);
          }
          setCurrentScreen('2');
        }
      }
    }
  };

  // Handling Drag & Drop
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error('File Limit Size Exceeded (500 MB)', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      return;
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      acceptedFiles[i].id = videoFiles.length + i + 1;
    }
    setVideoFiles(videoFiles.concat(acceptedFiles));
    setShowVideosUpload(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.mp4,.mov',
    maxSize: 524288000,
  });

  function ButtonDropZone(props) {
    const { getRootProps, getInputProps } = useDropzone({
      noDragEventsBubbling: true,
      onDrop,
      accept: '.mp4,.mov',
      maxSize: 524288000,
    });

    return (
      <div className="add-more-btn-wrapper" {...getRootProps()}>
        <input {...getInputProps()} />
        <button className="add-more-btn">
          <img src={UploadIcon}></img> ADD MORE
        </button>
      </div>
    );
  }

  // Drag Props Hashtags
  const dragPropsHashtags = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...hashtags];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setHashtags(data);
    },
    nodeSelector: 'li',
    handleSelector: 'button',
    lineClassName: 'sort-line',
  };
  //
  const dragPropsVideoUploader = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...videoFiles];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setVideoFiles(data);
    },
    nodeSelector: 'li',
    handleSelector: 'li',
    lineClassName: 'sort-line-video-upload',
  };

  // Handling Name And Sorting

  const nameAndSortReq = async () => {
    const url = `${DOMAIN}/v1/dashboard/product/skill/${productId}/position`;
    let payload = {
      skillIdPosition: videoFiles.map((videoFile, index) => {
        if (videoFile.rename) {
          return {
            skillId: videoFile.skillId,
            position: index + 1,
            name: videoFile.rename,
          };
        } else {
          return {
            skillId: videoFile.skillId,
            position: index + 1,
          };
        }
      }),
    };

    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const statusResponse = await putReq({
      url,
      payload,
      requestHeaders,
    });
    const { successCode } = statusResponse;
    if (successCode === 'SUCC200') {
      setCurrentScreen('3');
    }
  };

  // Render Video Cards
  const renderVideoCards = () => {
    if (videoFiles.length === 1) {
      const url = URL.createObjectURL(videoFiles[0]);
      return (
        <div className="videos-container">
          <div
            className="videos-wrapper"
            style={{
              left: '50%',
            }}
          >
            <video className="video-single">
              <source src={url}></source>
            </video>
          </div>
        </div>
      );
    } else if (videoFiles.length === 2) {
      const url = URL.createObjectURL(videoFiles[0]);
      const urlTwo = URL.createObjectURL(videoFiles[1]);
      return (
        <div className="videos-container">
          <div className="videos-wrapper">
            <video className="video-rot-double-1">
              <source src={url}></source>
            </video>
          </div>
          <div className="videos-wrapper">
            <video className="video-rot-double-2">
              <source src={urlTwo}></source>
            </video>
          </div>
        </div>
      );
    } else if (videoFiles.length === 3) {
      const url = URL.createObjectURL(videoFiles[0]);
      const urlTwo = URL.createObjectURL(videoFiles[1]);
      const urlThree = URL.createObjectURL(videoFiles[2]);
      return (
        <div className="videos-container">
          <div className="videos-wrapper">
            <video className="video-rot-third-1">
              <source src={url}></source>
            </video>
          </div>
          <div className="videos-wrapper">
            <video className="video-rot-third-2">
              <source src={urlTwo}></source>
            </video>
          </div>
          <div className="videos-wrapper">
            <video className="video-rot-third-3">
              <source src={urlThree}></source>
            </video>
          </div>
        </div>
      );
    } else {
      const url = URL.createObjectURL(videoFiles[0]);
      const urlTwo = URL.createObjectURL(videoFiles[1]);
      const urlThree = URL.createObjectURL(videoFiles[2]);
      const urlFour = URL.createObjectURL(videoFiles[3]);
      return (
        <div className="videos-container">
          <div className="videos-wrapper">
            <video className="video-rot-four-1">
              <source src={url}></source>
            </video>
          </div>
          <div className="videos-wrapper">
            <video className="video-rot-four-2">
              <source src={urlTwo}></source>
            </video>
          </div>
          <div className="videos-wrapper">
            <video className="video-rot-four-3">
              <source src={urlThree}></source>
            </video>
          </div>
          <div className="videos-wrapper">
            <video className="video-rot-four-4">
              <source src={urlFour}></source>
            </video>
          </div>
        </div>
      );
    }
  };

  // Web Page Handler
  const webPageChangeHandler = (value, index) => {
    const newArr = [...webPages];
    newArr[index] = value;
    setWebPages(newArr);
  };

  const addWebPages = () => {
    if (!webPagesError) {
      const newArr = [...webPages];
      newArr.push('');
      setWebPages(newArr);
    }
  };

  const removeWebPages = (number) => {
    if (webPages.length > 1) {
      const newArr = webPages.filter((page, index) => index !== number);
      setWebPages(newArr);
      for (let i = 0; i < newArr.length; i++) {
        setWebPagesError(!validateCompany(newArr[i]));
      }
    }
  };

  const handleWebpagesError = async (value, index) => {
    setWebPagesError(!validateCompany(value));
    if (validateCompany(value)) {
      const url = `${DOMAIN}/v1/dashboard/product/${productId}/magiclink/search`;
      let payload = { productURL: value };
      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      const statusResponse = await postReq({
        url,
        payload,
        requestHeaders,
      });
      const { error, data } = statusResponse;
      if (error === 'ERR409') {
        setWebpagesErrorMsg(
          'You have already paired a product to this URL. Please try another.'
        );
        setCurrentErrorIndex(index);
        setWebPagesError(true);
      } else {
        setCurrentErrorIndex(null);
        setWebPagesError(false);
        setWidgetLiveWarning(data.isWidgetInstalled);
      }
    } else {
      setCurrentErrorIndex(index);
      setWebPagesError(true);
      setWebpagesErrorMsg('Magiclink is Invalid');
    }
  };

  // temporary solution for edit product details when screens are changed in middle
  const finishCreateProduct = async () => {
    if (productId) {
      const url = `${DOMAIN}/v1/dashboard/products/${productId}`;
      let payload = {
        name: productName,
      };

      if (enablePrice && price) {
        payload = {
          ...payload,
          price: price ? parseFloat(price) : 0,
          currency: currency ? currency : 'AED',
        };
      }
      if (enableHashtags && hashtags.length > 0) {
        let hashtagObj = [];
        for (const [_index, key] of hashtags.entries()) {
          if (key.length) {
            hashtagObj = [...hashtagObj, key.trim()];
          }
        }
        payload = {
          ...payload,
          hashTags: hashtagObj,
        };
      }
      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      const editResponse = await putReq({
        url,
        payload,
        requestHeaders,
      });
      const { error = '', successCode = '', data = {} } = editResponse;

      if (error === 'ERR403') {
        console.log('Something Happened Bad', error);
        return;
      }
      if (error === 'ERR404') {
        console.log('User Not Found');
        return;
      }
      if (successCode === 'SUCC201') {
        // if (data) {
        //   setProductId(data.id);
        //   setHolofyRef(data.holofyRef);
        // }
      }
    } else {
      return;
    }
  };
  const webpageReq = async () => {
    const url = `${DOMAIN}/v1/dashboard/product/${productId}/magiclink`;
    let payload = {
      productURL: webPages.map((webpage) => {
        return {
          status: true,
          url: webpage,
          holofyRef: parseInt(holofyRef.match(/#([^']+)#/)[1]),
        };
      }),
    };
    const requestHeaders = {
      authorization: userToken.accessToken,
    };

    const statusResponse = await putReq({
      url,
      payload,
      requestHeaders,
    });
    const { successCode, data } = statusResponse;
    if (successCode === 'SUCC200') {
      showPopupItem(false);
      fetchActiveProducts();
    }
  };

  // Handling Session And Local Storage
  const handlePersistenStorage = () => {
    localStorage.setItem('ss-auth', JSON.stringify(userDetails));
    window.open('mySettings', '_blank');
  };

  return (
    <div className="popups-wrapper">
      <Pulse duration={150}>
        <div
          className={
            isDragActive
              ? 'product-card-wrapper product-card-dragactive'
              : 'product-card-wrapper'
          }
        >
          <div className="top-wrapper">
            <button className="back-button" onClick={handleBackBtn}>
              <img src={BackImg}></img>
            </button>
            <div className="current-step-wrapper">
              <div
                className={currentScreen === '1' ? 'circle active' : 'circle'}
              ></div>
              <div
                className={currentScreen === '2' ? 'circle active' : 'circle'}
              ></div>
              <div
                className={currentScreen === '3' ? 'circle active' : 'circle'}
              ></div>
            </div>
            {currentScreen === '2' && <ButtonDropZone />}
          </div>
          <div className="current-screen-title">
            {currentScreen === '2' && (
              <div className="total-videos-title">
                {videoFiles.length} VIDEOS
              </div>
            )}

            {currentScreen === '1' && 'Product Details'}
            {currentScreen === '2' && 'ADD VIDEOS'}
            {currentScreen === '3' && 'Pair to a page on your website'}
          </div>
          {currentScreen === '1' ? (
            <div className="product-actions-container">
              <div className="product-actions-input">
                <Input
                  type="text"
                  label="PRODUCT NAME"
                  labelStyle="uc mb-5"
                  placeholder="iPhone 12 pro"
                  info={productNameError ? 'PLEASE NAME YOUR Product' : ''}
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>
              <div className="optional-wrapper">
                {enablePrice ? (
                  <>
                    <div className="price-container">
                      <div className="price-inpt-wrapper">
                        <Input
                          customWidth={'90%'}
                          type="text"
                          label="Price"
                          labelStyle="uc mb-5"
                          placeholder="1000"
                          value={price}
                          onChange={(e) => {
                            !isNaN(e.currentTarget.value) &&
                              setPrice(e.target.value);
                          }}
                          inputStyle="mt-2"
                          info={priceError ? "CAN'T BE BLANK" : ''}
                        />
                        <div className="select-input">
                          <label className="d-ib fs-8 fw-900 uc">
                            CURRENCY
                          </label>
                          <select
                            name="currency"
                            id="currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                          >
                            {cc.codes().map((code) => (
                              <option key={code} value={code}>
                                {code}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <button
                        className="optional-btn"
                        onClick={() => {
                          setPrice();
                          setEnablePrice(false);
                        }}
                      >
                        {' '}
                        <img src={minusIcon}></img>
                      </button>
                    </div>
                  </>
                ) : (
                  <div
                    className="optional-field"
                    onClick={() => setEnablePrice(true)}
                  >
                    <button className="optional-btn">
                      <img src={addIconBlack}></img>
                    </button>
                    <div className="title">ADD PRICE</div>
                  </div>
                )}
              </div>
              <div className="optional-wrapper">
                {enableHashtags ? (
                  <div className="hashtags-container">
                    <div className="hashtags-title">
                      <div className="title">#Hashtags</div>
                      <button
                        className="optional-btn"
                        onClick={() => {
                          setHashtags([]);
                          setEnableHashtags(false);
                        }}
                      >
                        <img src={minusIcon}></img>
                      </button>
                    </div>
                    <p>
                      Help your customers find and filter your content through
                      hashtags. You can add and edit this later.
                    </p>
                    <div className="hashtags-wrapper">
                      <div className="hashtags-title">ADD HASHTAGS</div>
                      <ReactDragListView {...dragPropsHashtags}>
                        <div
                          className={
                            hashtags.length > 2
                              ? enablePrice
                                ? 'hashtags-list add-v-scroll minimize'
                                : 'hashtags-list add-v-scroll'
                              : 'hashtags-list'
                          }
                        >
                          {hashtags.map((hashtag, index) => (
                            <li key={index}>
                              <div className="hashtag-title-wrapper">
                                <button className="drag-handle-btn">
                                  <img src={dragHandleBtn}></img>
                                </button>
                                <div className="hash-text">#</div>
                                <input
                                  type="text"
                                  className="title-input"
                                  value={hashtag}
                                  onChange={(e) =>
                                    hashtagChangeHandler(index, e.target.value)
                                  }
                                ></input>
                              </div>
                              <button
                                className="close-btn"
                                onClick={() => removeHashtag(index)}
                              >
                                <img src={CloseHashtagsBtn}></img>
                              </button>
                            </li>
                          ))}
                          {scrollHashTag && <AlwaysScrollToBottom />}
                        </div>
                      </ReactDragListView>

                      <div className="add-hashtag-wrapper" onClick={addHashtag}>
                        <button className="optional-btn">
                          <img src={addIconBlack}></img>
                        </button>
                        <div className="title">ADD HASHTAGS</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="optional-field"
                    onClick={() => setEnableHashtags(true)}
                  >
                    <button className="optional-btn">
                      <img src={addIconBlack}></img>
                    </button>
                    <div className="title">ADD HASHTAGS</div>
                  </div>
                )}
              </div>

              <div className="bottom-wrapper">
                <button
                  onClick={createProduct}
                  disabled={productName.length < 2 ? true : false}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          ) : (
            ''
          )}

          {currentScreen === '2' ? (
            <div className="upload-video-container">
              {showVideosUpload ? (
                <>
                  <DragColumn {...dragPropsVideoUploader}>
                    <ol className="videos-wrapper">
                      {videoFiles.length > 0 &&
                        videoFiles.map((file) => (
                          <VideoUploader
                            file={file}
                            key={file.id}
                            fileNumber={file.id}
                            id={productId}
                            DeleteFile={DeleteFile}
                            videoUploaded={videoUploaded}
                            setVideoUploaded={setVideoUploaded}
                          ></VideoUploader>
                        ))}
                    </ol>
                  </DragColumn>
                  <div className="add-more-card" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src={UploadIcon}></img>
                  </div>
                </>
              ) : (
                <div className="upload-video-icon-wrapper">
                  <div className="upload-video-icon" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src={UploadIcon}></img>
                    <div className="title">
                      Drop the product videos here or click the “plus” button
                    </div>
                  </div>
                </div>
              )}

              <div className="bottom-wrapper">
                <div className="upload-info">
                  {price && (
                    <h1>{`${getSymbolFromCurrency(currency)} ${price}`}</h1>
                  )}

                  <p>{productName}</p>
                  <div className="hashtags-info-wrapper">
                    {hashtags.map((item, index) => (
                      <div className="hashtag">
                        <div key={index} className="hashtag-info">
                          #{item}
                        </div>
                        {index < hashtags.length - 1 ? (
                          <img
                            src={BreadcrumbIcon}
                            style={{ marginRight: '6px' }}
                          ></img>
                        ) : (
                          ''
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  disabled={
                    videoFiles.length === videoUploaded &&
                    videoFiles.length >= 1
                      ? false
                      : true
                  }
                  onClick={() => nameAndSortReq()}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
          {currentScreen === '3' ? (
            <div className="final-step-container">
              <p>
                Make this video collection instantly available on your website
                on one or multiple pages.
              </p>

              <div className="product-card-dummy">
                <div className="product-card-dummy-vid">{`${
                  videoFiles.length
                } ${videoFiles.length === 1 ? 'Video' : 'Videos'}`}</div>
                {renderVideoCards()}
                <div className="product-card-dummy-content">
                  {price && (
                    <h1>{`${getSymbolFromCurrency(currency)} ${price}`}</h1>
                  )}
                  <h2>
                    {productName.length > 30
                      ? productName.substring(0, 30) + '...'
                      : productName}
                  </h2>
                </div>
              </div>

              <div className="webpage-input-wrapper">
                {webPages.map((value, index) => (
                  <div className="webpage-input">
                    <label>YOUR WEB PAGES</label>
                    <div className="webpage-input-container">
                      <div
                        className={
                          currentErrorIndex === index
                            ? 'input-wrapper input-wrapper-border-error'
                            : 'input-wrapper input-wrapper-border-active'
                        }
                      >
                        <input
                          type="text"
                          label="YOUR WEB PAGE"
                          placeholder="www.yourwebsite.com/detailpage"
                          value={value}
                          onChange={(e) => {
                            handleWebpagesError(e.target.value, index);
                            webPageChangeHandler(e.target.value, index);
                          }}
                        />

                        <button
                          onClick={(e) => {
                            removeWebPages(index);
                          }}
                          className="webpage-input-clear"
                        >
                          <img src={ClearButton}></img>
                        </button>
                      </div>
                      {webPagesError && currentErrorIndex === index && (
                        <span className="webpage-input-container-error">
                          {webpagesErrorMsg}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                <div className="optional-field">
                  <button className="optional-btn" onClick={addWebPages}>
                    <img src={addIconBlack}></img>
                  </button>
                  <div className="title">ADD MORE PAGES</div>
                </div>
              </div>

              <div className="bottom-wrapper">
                {!widgetLiveWarning && (
                  <div className="warning-wrapper">
                    <img src={AlertIcon}></img>
                    <div className="warning-text">
                      Your website does not seem to have the Holofy widget
                      installed. Videos will not appear on your website until
                      complete the one time installation process.
                    </div>
                    <div
                      className="warning-btn"
                      onClick={handlePersistenStorage}
                    >
                      HOW TO INSTALL <img src={BreadcrumbIconWhite}></img>
                    </div>
                  </div>
                )}
                <button
                  disabled={webPagesError}
                  onClick={() => {
                    finishCreateProduct();
                    webpageReq();
                  }}
                >
                  FINISH
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </Pulse>
    </div>
  );
};

export default PopUpAddNewItem;
