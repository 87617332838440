import React, { useState, useEffect } from 'react';
import './popupeditproductskill.scss';
// Assets
import PlayBtn from '../../assets/playbtn.svg';
import equalDrag from '../../assets/drag-equal.svg';
import VideoPlaceholder from '../../assets/video-placeholder.png';
import Checked from '../../assets/Vector 316checked.svg';
import Remove from '../../assets/close-pair.svg';

const PopUpEditProductsSkill = (props) => {
  const {
    key,
    item,
    editProducts,
    widgetLink,
    selectSkillMethod,
    toggleSkillFalse,
    setToggleSkillFalse,
  } = props;
  const [toggleSkill, setToggleSkill] = useState(false);
  const [name, setName] = useState(item.name ? item.name : item.rename);
  const toggleSkillCheck = (id) => {
    setToggleSkillFalse(false);
    setToggleSkill(!toggleSkill);
    if (toggleSkill) {
      selectSkillMethod(id, false);
    } else if (!toggleSkill) selectSkillMethod(id, true);
  };

  useEffect(() => {
    if (toggleSkillFalse) {
      setToggleSkill(false);
    }
  }, [toggleSkillFalse]);

  useEffect(() => {
    item.rename = name;
  }, [name]);

  const handleImageError = (e) => {
    e.target.src = VideoPlaceholder;
  };

  // temp showing video image while uploading
  const renderImage = () => {
    if (item?.content?.video?.length) {
      return (
        <video
          src={item.content.video}
          style={{
            position: 'absolute',
            objectFit: 'cover',
            width: '180px',
            height: '315px',
            borderRadius: '10px',
            zIndex: 1,
          }}
        />
      );
    }
    return '';
  };

  return (
    <div key={key} className="video-container-item">
      {renderImage()}
      <img
        className="bg-image"
        src={item.poster || VideoPlaceholder}
        onError={handleImageError}
      />
      {editProducts ? (
        <div
          className="products-custom-checkbox m-8"
          onClick={() => toggleSkillCheck(item.id)}
        >
          {toggleSkill && (
            <div>
              <img src={Checked} alt="Checked" />
            </div>
          )}
        </div>
      ) : (
        <a
          className="video-container-item-btn"
          href={widgetLink ? widgetLink : ''}
          target="_blank"
        >
          <img className="mr-10" src={PlayBtn}></img>PLAY VIDEO
        </a>
      )}
      {!editProducts && (
        <span className="video-container-item-dur">
          {item.videoDuration &&
            new Date(item.videoDuration * 1000).toISOString().substr(14, 5)}
        </span>
      )}
      <div className="video-container-item-desc">
        <img src={equalDrag} alt="Drag" />
        <input
          type="text"
          placeholder="VIDEO TITLE"
          disabled={editProducts ? false: true}
          value={name}
          onChange={(e) => editProducts && setName(e.target.value)}
        />
      </div>
    </div>
  );
};

export default PopUpEditProductsSkill;
