import React, { Component } from 'react'
import Slider from "react-slick";
import FeatureArrow from '../../assets/feature-arrow.svg';
import FeatureArrowBlack from '../../assets/next-arrow-black.svg';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Features } from './Feature';
import FeatureBackground from '../../assets/feature-background.svg';
export default class SimpleSlider extends Component {

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
  }
  next = () => {
    this.slider.slickNext();
  };
 

  render() {
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider ref={c => (this.slider = c)} {...settings}>
      {
        Features.map((feature, idx) => {
          const {id, heading, title, subTitle, img, className} = feature;
          let position = className;
          return <div className={`feature ${position}`} key={id}>
            {
              id % 2 === 0 ? <div className="feature-background">
                <img src={FeatureBackground} />
              </div>:''
            }
            <div className="left-line">

            </div>
            <div className="right-line">
              
            </div>
            <div className="img-wrap">
              <img src={img} />
            </div>
            <div className="feature__details">
              <h1>{heading} <span className="hr-line"></span> </h1>
              <div className="feature__title">
                {title}
              </div>
              <div className="feature__subtitle">
                {subTitle}
              </div>
            </div>
            <div className="feature__no left">
              0{id}
            </div>
            <div className="feature__no">
              0{id}
            </div>
            <div className="next-arrow" onClick={this.next}>
              {id % 2 != 0 ? <img src={FeatureArrow}/> : <img src={FeatureArrowBlack}/>}
            </div>
          </div>
        })
      }
    </Slider>
  )
}
}
