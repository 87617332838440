import { EDIT_GLOBAL_PROFILE,
         SET_NEW_USER,
         SET_USER_CURRENT_USAGE,
         SET_FREE_SUBSCRIPTION,
} from './types.js';

const editGlobalProfile = (
  { id, avatar, name, email, admin, phone, owner },
  state
) => {
  const newProfile = {
    ...state,
    globalProfileId: id,
    globalProfileAvatar: avatar,
    globalProfileName: name,
    globalProfileEmail: email,
    globalProfileAdmin: admin,
    globalProfilePhone: phone,
    globalProfileOwner: owner,
  };
  return {
    ...newProfile,
  };
};

const setNewUserType = ({newUser}, state) => {
  const newUserType = {
    ...state,
    isNewUser: newUser,
  };
  return newUserType;
}

const setUserUsageDetails = ({
  currentTier, daysLeft, 
  engagedVisitors, nextTier,
  planEnds, priceToPay, 
  range, paymentInfo, trialUp}, state) => {
  const newState = {
    ...state,
    usageDetails: {
      currentTier,
      daysLeft,
      engagedVisitors,
      nextTier,
      planEnds,
      priceToPay,
      range,
      paymentInfo,
      trialUp,
    }
  };
  return newState;
}

const setFreeSubscription = ({subscribe}, state) => {
  const newState = {
    ...state,
    subscribe
  }
  return newState;
}
export default (state, action) => {
  switch (action.type) {
    case EDIT_GLOBAL_PROFILE:
      return editGlobalProfile(action.payload, state);
    case SET_NEW_USER:
      return setNewUserType(action.payload, state);
    case SET_USER_CURRENT_USAGE:
      return setUserUsageDetails(action.payload, state);
    case SET_FREE_SUBSCRIPTION:
      return setFreeSubscription(action.payload, state);
    default:
      return state;
  }
};
