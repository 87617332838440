import React, { useState, useReducer } from 'react';
import SkillsRouter from './router';
// import awsconfig from './aws-exports';
import Amplify, { Auth } from 'aws-amplify';
import 'normalize.css';
import './styles/main.scss';

import {
  SignupContext,
  ResetContext,
  EditMemberContext,
  GlobalProfile,
} from './context';
import { getSessionStorage } from './utils';
import globalProfileReducer from './context/globalProfileReducer';
import { ToastContainer } from 'react-toastify';
import { S3_VIDEO_BUCKET } from './constants/base';

// Amplify.configure(awsconfig);

Amplify.configure({
  Auth: {
    identityPoolId: 'eu-west-2:cffd2ff3-6365-44a1-99f7-7e79fd07de44', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'eu-west-2', // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: S3_VIDEO_BUCKET, //REQUIRED -  Amazon S3 bucket name
      region: 'eu-west-2', //OPTIONAL -  Amazon service region
    },
  },
});

function App() {
  const signupContextHook = useState({});
  const resetContextHook = useState({});
  const editMemberContextHook = useState({});

  // Getting Sessions Values
  const userDetails = getSessionStorage('ss-user') || {};

  const {
    name = '',
    avatar,
    id,
    email,
    admin,
    phone,
    countryCode,
    countryName,
  } = userDetails;

  const intialGlobalProfile = {
    globalProfileId: id,
    globalProfileAvatar: avatar,
    globalProfileName: name,
    globalProfileEmail: email,
    globalProfileAdmin: admin,
    globalProfilePhone: {
      phone: phone,
      countryCode: countryCode,
      countryName: countryName,
    },
  };

  const [globalProfileState, globalProfileDispatch] = useReducer(
    globalProfileReducer,
    intialGlobalProfile
  );

  return (
    <div className="App">
      <GlobalProfile.Provider
        value={[globalProfileState, globalProfileDispatch]}
      >
        <SignupContext.Provider value={signupContextHook}>
          <ResetContext.Provider value={resetContextHook}>
            <EditMemberContext.Provider value={editMemberContextHook}>
              <SkillsRouter />
            </EditMemberContext.Provider>
          </ResetContext.Provider>
        </SignupContext.Provider>
      </GlobalProfile.Provider>
      <ToastContainer />
    </div>
  );
}

export default App;
