export const SET_CURRENT_MAGIC_LINK = 'SET_CURRENT_MAGIC_LINK';
export const SET_CURRENT_CTA = 'SET_CURRENT_CTA';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_VIDEOS = 'EDIT_VIDEOS';
export const SET_CURRENCY = 'SET_CURRENCY';
export const EDIT_EMPTY_SKILL = 'EDIT_EMPTY_SKILL';

export const EDIT_MEMBER = 'EDIT_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';

export const EDIT_GLOBAL_PROFILE = 'EDIT_GLOBAL_PROFILE';

// Stats Actions
export const SET_OVERALL_IMPRESSION = 'SET_OVERALL_IMPRESSION';
export const SET_OVERALL_RETENTION = 'SET_OVERALL_RETENTION';
export const SET_OVERALL_CTAACTIONRATE = 'SET_OVERALL_CTAACTIONRATE';
export const SET_MOST_VIEWED_TIMES = 'SET_MOST_VIEWED_TIMES';
export const SET_TOP_TRAFFIC_SOURCES = 'SET_TOP_TRAFFIC_SOURCES';
export const SET_GRAPH_DATE = 'SET_DATE';
export const SET_SHOW_POPUP = 'SET_SHOW_POPUP';
export const SET_OVERALL_POPUP = 'SET_OVERALL_POPUP';
export const SET_LAST_UPDATED = 'SET_LAST_UPDATED';

// Product Detail Stats Action
export const SET_PRODUCT_SKILLS = 'SET_PRODUCT_SKILLS';
export const SET_PRODUCT_IMPRESSION_CTA = 'SET_PRODUCT_IMPRESSION_CTA';
export const SET_PRODUCT_VIDEO_PERFORMANCE = 'SET_PRODUCT_VIDEO_PERFORMANCE';
export const SET_SKILL_STATS_POPUP = 'SET_SKILL_STATS_POPUP';
export const SET_TRAFFIC_SOURCES_BY_PRODUCT = 'SET_TRAFFIC_SOURCES_BY_PRODUCT';
export const SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT';
export const SET_RETENTION_RATE_BY_PRODUCT = 'SET_RETENTION_RATE_BY_PRODUCT';
// Upload
export const SET_UPLOAD = 'SET_UPLOAD';

export const SET_NEW_USER = 'SET_NEW_USER';

// Tier Plan Actions
export const SET_USER_CURRENT_USAGE = 'SET_USER_CURRENT_USAGE';
export const SET_FREE_SUBSCRIPTION = 'SET_FREE_SUBSCRIPTION';