import React from 'react';
import classnames from 'classnames';
import { isEmpty } from '../../utils';
import { Fade } from 'react-reveal';

const InputField = (props) => {
  const {
    type,
    inputStyle,
    label,
    labelStyle,
    placeholder,
    value,
    onChange,
    onBlur,
    info,
    infoStyle,
    customWidth,
    customHeight,
    disabled,
    isValid = true,
    handleEnter = () => {},
    readonly,
  } = props;

  const labelClass = classnames('d-ib fs-8 fw-900 ', labelStyle);
  const inputClass = classnames(
    'd-ib',
    {
      'mb-20': isEmpty(info),
      'mb-15': !isEmpty(info),
      'b-error': !isValid,
    },
    inputStyle
  );
  const infoClass = classnames('d-ib fs-8 fw-900 c-error', infoStyle);

  const onEnterKey = (e) => {
    if (e.keyCode === 13) {
      handleEnter(e);
    }
  };

  return (
    <div className="input-main">
      <div
        className="input-val-error d-flex space-between"
        style={{ width: customWidth ? customWidth : '100%' }}
      >
        {label && (
          <div>
            <label stye={{ color: '#3A3A3A' }} className={labelClass}>
              {label}
            </label>
          </div>
        )}
        {info && (
          <div className="mb-5">
            <Fade bottom duration={150}>
              <label className={infoClass}>{info}</label>
            </Fade>
          </div>
        )}
      </div>
      <div>
        <input
          style={{
            width: customWidth ? customWidth : '100%',
            height: customHeight ? customHeight : '40px',
            marginBottom: '20px',
          }}
          type={type}
          className={inputClass}
          placeholder={placeholder}
          onChange={(e) => onChange && onChange(e)}
          value={value}
          onBlur={onBlur}
          onKeyDown={onEnterKey}
          disabled={disabled}
          readonly={readonly}
        ></input>
      </div>
    </div>
  );
};

export default InputField;
