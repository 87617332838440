import { SET_UPLOAD } from './types.js';

const updateUpload = (
  { fileNumber, productName, loadingPercentage },
  state
) => {
  const newProfile = {
    ...state,
    [fileNumber]: {
      productName: productName,
      loadingPercentage: loadingPercentage,
    },
  };
  return {
    ...newProfile,
  };
};

export default (state, action) => {
  switch (action.type) {
    case SET_UPLOAD:
      return updateUpload(action.payload, state);
    default:
      return state;
  }
};
