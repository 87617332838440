import React, { useState, useEffect, useReducer } from 'react';
import './navBar.scss';
import { Redirect, Link } from 'react-router-dom';
import { isEmpty } from '../../utils';
import { DOMAIN } from '../../constants/base';
import Logo from '../../components/Logo';
import {
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage,
} from '../../utils';
import { getReq } from '../../utils/api';
import { InfoBox } from '../../components/StatusMsg';

// Assets
import LogoutDeselected from '../../assets/SignOutPassive.png';
import LogoutSelected from '../../assets/SignOutActive.png';
import PortfolioDeselected from '../../assets/ListingsPassive.png';
import PortfolioSelected from '../../assets/ListingsActive.png';
import TeamDeselected from '../../assets/TeamPassive.png';
import TeamSelected from '../../assets/TeamActive.png';
import settingsDeselected from '../../assets/SettingsPassive.png';
import settingsSelected from '../../assets/SettingsActive.png';
import statsSelected from '../../assets/StatsActive.png';
import statsDeselected from '../../assets/StatsPassive.png';



function NavBar(props) {
  const { selected } = props;
  const [isSignOut, setSignOut] = useState(false);
  const [shouldRedirect, setRedirect] = useState(false);
  const [widgetStatus, setWidgetStatus] = useState([]);
  const userDetails = getSessionStorage('ss-user') || {};
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};

  const { name = '', email, currenPlan } = userDetails;
  const [statusMsg, setStatusMsg] = useState({ msg: null, type: null });


  const signoutHandler = () => {
    document.body.classList.add('ovf-hidden');
    setSignOut(true);
  };
  const noHandler = () => {
    document.body.classList.remove('ovf-hidden');
    setSignOut(false);
  };
  const proceedHandler = () => {
    document.body.classList.remove('ovf-hidden');
    localStorage.removeItem('ss-auth');
    removeSessionStorage('ss-user');
    setRedirect(true);
    try {
      window.Intercom('shutdown');
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    //Api to get user details with access token
    // if (isEmpty(userDetails)) {
    //   fetchUserDetails(); 
    // }
    if (isEmpty(userToken)) {
      setRedirect(true);
    }
    // removing the API call 
    // fetchWidgetStatus();
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_KEY,
      name: name, // Full name
      email: email, // Email address
      created_at: new Date(), // Signup date as a Unix timestamp
      Entity: 'Product',
      plan: currenPlan,
    });
  }, []);

  useEffect(() => {
    if (statusMsg) {
      setTimeout(() => {
        setStatusMsg(null);
      }, 5000);
    }
  }, [statusMsg]);

  if (shouldRedirect) {
    return <Redirect to="/signin" />;
  }
  //Fetching widget status
  async function fetchWidgetStatus() {
    const url = `${DOMAIN}/v1/dashboard/isLive`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const statusResponse = await getReq({
      url,
      requestHeaders,
    });
    const { successCode, data } = statusResponse;
    if (successCode === 'SUCC200') {
      setWidgetStatus(data);
    }
  }

  
  return (
    <>
      {statusMsg && <InfoBox type={statusMsg.type} message={statusMsg.msg} />}
      <div className="navbar-container d-flex h-center f-col">
        <div className="navbar-new d-flex center-align f-col bg-white b-shadow">
          <Link
            className={
              selected === 'mySpaces'
                ? 'nav-item cur-pointer selected'
                : 'nav-item cur-pointer'
            }
            to="/myProducts"
          >
            <Logo
              logo={
                selected === 'mySpaces'
                  ? PortfolioSelected
                  : PortfolioDeselected
              }
              width={24}
              height={24}
            />
            <div className="nav-text mt-5">PRODUCTS</div>
          </Link>
          <Link
            className={
              selected === 'myTeam'
                ? 'nav-item cur-pointer selected'
                : 'nav-item cur-pointer'
            }
            to="/myTeam"
          >
            <Logo
              logo={selected === 'myTeam' ? TeamSelected : TeamDeselected}
              width={24}
              height={24}
            />
            <div className="nav-text mt-5">TEAM</div>
          </Link>

          <Link
            className={
              selected === 'mySettings'
                ? 'nav-item cur-pointer selected'
                : 'nav-item cur-pointer'
            }
            to="/mySettings"
          >
            <Logo
              logo={
                selected === 'mySettings'
                  ? settingsSelected
                  : settingsDeselected
              }
              width={24}
              height={24}
            />
            <div className="nav-text mt-5">SETTINGS</div>
          </Link>

          {/* Stats navigation start */}
          <Link
            className={
              selected === 'myStats'
                ? 'nav-item cur-pointer selected'
                : 'nav-item cur-pointer'
            }
            to="/myStats"
          >
            <Logo
              logo={selected === 'myStats' ? statsSelected : statsDeselected}
              width={24}
              height={24}
            />
            <div className="nav-text mt-5">STATS</div>
          </Link>
          {/* Stats navigation end */}

          <div className="nav-item cur-pointer" onClick={signoutHandler}>
            <Logo
              logo={isSignOut ? LogoutSelected : LogoutDeselected}
              width={24}
              height={24}
            />
            <div className="nav-text mt-5">SIGN OUT</div>
          </div>
        </div>
      </div>
      {isSignOut && (
        <div className="d-flex center-align pos-fix w-100p h-100p bg-black-50 top-0 left-0 zi-100">
          <div className="bg-white p-20 br-10 mxw-300">
            <div className="fs-18 fw-b mb-40">
              Are you sure you want to Sign Out of your account?
            </div>
            <div className="d-flex space-between fs-12">
              <div
                className="fs-12 fw-b uc c-blue p-10 cur-pointer"
                onClick={noHandler}
              >
                Nope, take me back
              </div>
              <div
                className="fs-12 fw-b uc bg-black c-white br-20 p-10-20 cur-pointer"
                onClick={proceedHandler}
              >
                Sign Out
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NavBar;
