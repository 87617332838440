import {
  apiPostHelper,
  apiGetHelper,
  apiPostFormDataHelper,
  apiPutFormDataHelper,
  apiPutHelper,
  removeSessionStorage,
} from '../utils';
import { toast } from 'react-toastify';

export const postReq = (req) => {
  const response = apiPostHelper(req);
  Promise.resolve(response).then((data) => {
    if (data.error === 'ERR401') {
      removeSessionStorage('ss-user-spaces');
    }
    if (data.error && data.error.message) {
      toast.error(data.error.message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  });
  return response;
};
export const getReq = (req) => {
  const response = apiGetHelper(req);
  Promise.resolve(response).then((data) => {
    if (data.error === 'ERR401') {
      removeSessionStorage('ss-user-spaces');
      localStorage.removeItem('ss-auth-spaces');
    }
    if (data.error && data.error.message) {
      toast.error(data.error.message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  });
  return response;
};
export const postFormReq = (req) => {
  const response = apiPostFormDataHelper(req);
  Promise.resolve(response).then((data) => {
    if (data.error === 'ERR401') {
      removeSessionStorage('ss-user-spaces');
    }
    if (data.error && data.error.message) {
      toast.error(data.error.message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  });
  return response;
};

export const putReq = (req) => {
  const response = apiPutHelper(req);
  Promise.resolve(response).then((data) => {
    if (data.error === 'ERR401') {
      removeSessionStorage('ss-user-spaces');
    }
    if (data.error && data.error.message) {
      toast.error(data.error.message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  });
  return response;
};

export const putFormReq = (req) => {
  const response = apiPutFormDataHelper(req);
  Promise.resolve(response).then((data) => {
    if (data.error === 'ERR401') {
      removeSessionStorage('ss-user-spaces');
    }
    if (data.error && data.error.message) {
      toast(data.error.message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  });
  return response;
};
