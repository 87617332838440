import React, { useState, useEffect, useContext } from 'react';
import './teams.scss';
import { Redirect } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import { Fade } from 'react-reveal';
import { postReq, postFormReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import { getSessionStorage, isEmpty, getFormData } from '../../utils';
import ReactPaginate from 'react-paginate';
import PopUpInviteNewMember from '../../components/PopUpInviteMember';
import MemberItem from '../../components/MemberItem';
import PopUpEditMember from '../../components/PopUpEditMember';
import PopUpRemoveMember from '../../components/PopUpRemoveMember';
import Search from '../../components/Search';
import StatusMsg from '../../components/StatusMsg';
import { EditMemberContext } from '../../context';

// Assets
import AddIcon from '../../assets/addIconBlack.png';
import SearchImg from '../../assets/search.svg';
import RemoveTextImg from '../../assets/removeText.svg';
import EmptyTeamBanner from '../../assets/EmptyTeamBanner.png';

const Teams = () => {
  const [currentMember, dispatch] = useContext(EditMemberContext);
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
  const userDetails = getSessionStorage('ss-user') || {};

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [activeMemberList, setActiveMemberList] = useState(null);
  const [inviteMemberPopup, setInviteMemberPopup] = useState(false);
  const [editMemberPopUp, setEditMemberPopUp] = useState(false);
  const [removeMemberPopUp, setRemoveMemberPopUp] = useState(false);
  const [isTeamsLoading, setIsTeamsLoading] = useState(false);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const [noData, setNoData] = useState(false);
  const [changeState, setChangeState] = useState(true);

  // Pagination States
  const [pageNo, setPageNo] = useState(1);
  const [pageMetadata, setPageMetadata] = useState({
    totalDocs: 0,
    totalPages: 0,
  });
  const [searchParam, setSearchParam] = useState('');

  // Status Messages
  const [statusMsg, setStatusMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const isMountedRef = React.useRef(null);

  useEffect(() => {
    if (isEmpty(userToken)) {
      setShouldRedirect(true);
    }
  }, []);

  async function fetchActiveMembers() {
    setIsTeamsLoading(true);
    const url = `${DOMAIN}/v1/dashboard/product/user/team-members/page/${pageNo}?searchStr=${searchParam}`;
    if (!isEmpty(userToken)) {
      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      const teamMembersResponse = await postReq({
        url,
        requestHeaders,
      });
      const { error = '', successCode = '', data = {} } = teamMembersResponse;
      if (error === 'ERR500') {
        console.log('Something went wrong. Please try after sometime.');
        return;
      }
      if (successCode === 'SUCC200') {
        if (isMountedRef.current) {
          setIsTeamsLoading(false);
          setActiveMemberList(data.docs);
          setPageMetadata({
            totalPages: data.pageSize,
            totalDocs: data.pageSize * 16,
          });
          if (data.docs.length === 0 && searchParam.length === 0) {
            setNoData(true);
          } else {
            setNoData(false);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (statusMsg) {
      setTimeout(() => {
        setStatusMsg(false);
      }, 1500);
    }
  }, [statusMsg]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchActiveMembers();
    return () => (isMountedRef.current = false);
  }, [changeState]);

  // Pagination Handler
  const handlePageClick = (e) => {
    let selectedPage = e.selected;
    setPageNo(selectedPage + 1);
    setChangeState(!changeState);
  };

  // Handle Edit Member
  const handleEditMember = (index) => {
    const currentMember = activeMemberList[index];
    const { id, avatar, name, email, admin, verified, invitee, owner } =
      currentMember;
    const phone = {
      phone: currentMember.phone,
      countryCode: currentMember.countryCode,
      countryName: currentMember.countryName,
    };
    dispatch({
      type: 'EDIT_MEMBER',
      payload: {
        id,
        avatar,
        name,
        email,
        admin,
        phone,
        verified,
        invitee,
        owner,
      },
    });

    if (userDetails.admin) {
      setEditMemberPopUp(true);
    } else {
      setEditMemberPopUp(false);
    }
  };
  // Handle Changind Role
  const handleRole = async (index) => {
    const currentMember = activeMemberList[index];
    const { id, admin } = currentMember;
    console.log(id, admin);
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    let payload = {
      id: id,
      makeAdmin: admin ? false : true,
    };
    const url = `${DOMAIN}/v1/dashboard/product/user/edit-member`;
    const formData = getFormData(payload);
    const editResponse = await postFormReq({
      url,
      payload: formData,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = editResponse;
    if (error === 'ERR403') {
      console.log('Error Editing The Member');
      return;
    }
    if (error === 'ERR404') {
      console.log('User Not Found');
      return;
    }
    if (successCode === 'SUCC200') {
      fetchActiveMembers();
    }
  };

  const handleRemoveMember = (index) => {
    const currentMember = activeMemberList[index];
    const { id, avatar, name, invitee } = currentMember;
    dispatch({ type: 'REMOVE_MEMBER', payload: { id, avatar, name, invitee } });
    setRemoveMemberPopUp(true);
  };

  let timer;
  const searchActiveMembers = (value) => {
    if (!isTeamsLoading && !firstTimeLoad) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setPageNo(1);
        setSearchParam(value);
        setChangeState(!changeState);
      }, 1000);
    }
    setFirstTimeLoad(false);
  };

  const renderTeamsList = () => {
    const memberList = activeMemberList.map((props, index) => (
      <Fade duration={200} key={index}>
        <MemberItem
          name={props.name}
          isMemberAdmin={props.admin}
          avatar={props.avatar}
          verified={props.verified}
          totalSpaces={props.totalSpaces}
          owner={props.owner}
          onClick={() => handleEditMember(index)}
          handleEditMember={() => handleEditMember(index)}
          handleRemoveMember={() => handleRemoveMember(index)}
          handleRole={() => handleRole(index)}
        ></MemberItem>
      </Fade>
    ));
    return memberList;
  };

  if (shouldRedirect) {
    return <Redirect to="/signin" />;
  }

  return (
    <div className="d-flex main-dash-container">
      <NavBar selected="myTeam" />
      {statusMsg && <StatusMsg type="error" message={errorMsg}></StatusMsg>}
      {activeMemberList && (
        <>
          {!noData ? (
            <div
              className="w-100p mt-20 content-width"
              style={{ height: 'calc(100vh - 68px)' }}
            >
              <div className="d-flex f-col space-between mr-25 ml-25">
                <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 h-60">
                  <h1>Team</h1>
                  <div className="d-flex">
                    <div className="d-flex mt-5">
                      <Search
                        type="text"
                        labelStyle="mb-10"
                        placeholder="Search"
                        onChange={searchActiveMembers}
                        prependImg={SearchImg}
                        appendImg={RemoveTextImg}
                        smallSearchBar={true}
                        setCurrentPage={() => {}}
                        setCurrentSearchTerm={() => {}}
                      />
                    </div>
                    <button
                      className="button-link ml-15 mt-5"
                      onClick={() => setInviteMemberPopup(true)}
                    >
                      {' '}
                      <img src={AddIcon} alt="ADD"></img> Invite New
                    </button>
                  </div>
                </div>
                <div className="active-teams">
                  <div className="team-wrapper">
                    {isTeamsLoading ? (
                      <div className="loading-teams">
                        Getting your teams. Please wait...
                      </div>
                    ) : activeMemberList.length > 0 ? (
                      renderTeamsList()
                    ) : (
                      <div className="loading-teams">
                        No data available for the searched item...
                      </div>
                    )}
                  </div>
                </div>
                <div className="pagination-wrapper">
                  {pageMetadata.totalPages > 1 && (
                    <ReactPaginate
                      currentPage={pageNo - 1}
                      forcePage={pageNo - 1}
                      pageCount={pageMetadata.totalPages}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={2}
                      previousLabel={'❮'}
                      nextLabel={'❯'}
                      breakLabel={'---'}
                      onPageChange={handlePageClick}
                      initialPage={0}
                      currentPage={0}
                      containerClassName={'paginate-container'}
                      pageClassName={'page-item'}
                      activeClassName={'page-item-active'}
                      pageLinkClassName={'page-item-link'}
                      activeLinkClassName={'active-link'}
                      nextClassName={'next-previous-class'}
                      previousClassName={'next-previous-class'}
                      nextLinkClassName={'next-previous-link-class'}
                      previousLinkClassName={'next-previous-link-class'}
                      disabledClassName={'disabled-class'}
                    ></ReactPaginate>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="w-100p mt-20 pl-20 pr-20"
              style={{ height: 'calc(100vh - 68px)' }}
            >
              <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 mt-9">
                <h1>Team</h1>
              </div>
              <div className="pos-rel w-100p bg-white br-20 empty-state-height">
                <h2 className="text-center fw-700 fs-32 mb-0">
                  Invite your team
                </h2>
                <h4 className="text-center fw-600 fs-12 c-sub lh-16 mt-6 fw-600">
                  Bring all your team under one account so you can
                  <br /> manage, publish and create together.
                </h4>
                <img
                  className="mxw-100p center-fix top-58p w-65p"
                  src={EmptyTeamBanner}
                />
                <div className="w-100p center-align d-flex pos-abs custom-bottom">
                  <button
                    className="empty-state-add-new"
                    onClick={() => setInviteMemberPopup(true)}
                  >
                    {' '}
                    SEND INVITATIONS
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {inviteMemberPopup ? (
        <PopUpInviteNewMember
          setInviteMemberPopup={setInviteMemberPopup}
          fetchActiveMembers={fetchActiveMembers}
          setStatusMsg={setStatusMsg}
          setErrorMsg={setErrorMsg}
        ></PopUpInviteNewMember>
      ) : (
        ''
      )}
      {editMemberPopUp ? (
        <PopUpEditMember
          userDetails={userDetails}
          setEditMemberPopUp={setEditMemberPopUp}
          fetchActiveMembers={fetchActiveMembers}
        ></PopUpEditMember>
      ) : (
        ''
      )}
      {removeMemberPopUp ? (
        <PopUpRemoveMember
          setRemoveMemberPopUp={setRemoveMemberPopUp}
          fetchActiveMembers={fetchActiveMembers}
        ></PopUpRemoveMember>
      ) : (
        ''
      )}
    </div>
  );
};

export default Teams;
