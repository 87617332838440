import {
  SET_CURRENT_MAGIC_LINK,
  SET_CURRENT_CTA,
  REMOVE_PRODUCT,
  EDIT_PRODUCT,
  EDIT_VIDEOS,
  SET_CURRENCY,
  EDIT_EMPTY_SKILL
} from './types.js';

const setCurrentMagicLink = ({ magicLink, name, id }, state) => {
  const newCurrentProduct = {
    ...state,
    currentMagicLink: magicLink,
    currentProductName: name,
    currentProductId: id,
  };
  return {
    ...newCurrentProduct,
  };
};

const setCurrentCTA = ({ cta, name, id }, state) => {
  const newCurrentProduct = {
    ...state,
    currentProductName: name,
    currentProductId: id,
    currentCTAValues: cta,
  };
  return {
    ...newCurrentProduct,
  };
};

const editProduct = ({ name, id, skills, currency, brand, price }, state) => {
  const newCurrentProduct = {
    ...state,
    currentProductName: name,
    currentProductId: id,
    currentSkillValues: skills,
    currentBrandName: brand,
    currentCurrency: currency,
    currentPrice: price,
  };
  return {
    ...newCurrentProduct,
  };
};

const removeProduct = ({ name, id }, state) => {
  const newCurrentProduct = {
    ...state,
    currentProductName: name,
    currentProductId: id,
  };
  return {
    ...newCurrentProduct,
  };
};

const editVideos = ({ skills, id }, state) => {
  const newCurrentProduct = {
    ...state,
    currentSkillValues: skills,
    currentProductId: id,
  };
  return {
    ...newCurrentProduct,
  };
};

const setCurrencyValue = ({ currency }, state) => {
  const newCurrentProduct = {
    ...state,
    currentCurrency: currency,
  };
  return {
    ...newCurrentProduct,
  };
};

const emptyVideoSkill = ({ skillId, skillName }, state) => {
  const newCurrentProduct = {
    ...state,
    selectedSkillId: skillId,
    selectedSkillName: skillName
  };
  return {
    ...newCurrentProduct,
  };
};


export default (state, action) => {
  switch (action.type) {
    case SET_CURRENT_MAGIC_LINK:
      return setCurrentMagicLink(action.payload, state);
    case SET_CURRENT_CTA:
      return setCurrentCTA(action.payload, state);
    case REMOVE_PRODUCT:
      return removeProduct(action.payload, state);
    case EDIT_PRODUCT:
      return editProduct(action.payload, state);
    case EDIT_VIDEOS:
      return editVideos(action.payload, state);
    case SET_CURRENCY:
      return setCurrencyValue(action.payload, state);
    case EDIT_EMPTY_SKILL:
      return emptyVideoSkill(action.payload, state);
    default:
      return state;
  }
};
