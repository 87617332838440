import React, { useEffect, useState } from 'react';
import './videostatscard.scss';

// Assets

import EyeIcon from '../../assets/eye-icon.png';
import RightArrIcon from '../../assets/right-arr.png';
import SortIncreasingIcon from '../../assets/sort-increase.svg';
import SortDecreasingIcon from '../../assets/sort-decreasing.svg';
import skillPlaceholder from '../../assets/skillPlaceholder.png';

export default function VideoStatsCard({
  skills,
  setSkills,
  showCompleteStats,
  skillsLoading,
}) {
  const [sortIncrease, setSortIncrease] = useState(true);
  const [sortIcon, setSortIcon] = useState(SortIncreasingIcon);

  useEffect(() => {
    let sortedSkills;
    if (sortIncrease) {
      setSortIcon(SortIncreasingIcon);
      sortedSkills = skills.sort(
        (l1, l2) => l1.retentionRate - l2.retentionRate
      );
    } else {
      setSortIcon(SortDecreasingIcon);
      sortedSkills = skills.sort(
        (l1, l2) => l2.retentionRate - l1.retentionRate
      );
    }
    setSkills(sortedSkills);
  }, [sortIncrease]);

  return (
    <div className="video-per-list-wrapper">
      <div className="videos-sort__header">
        <span>Videos</span>
        <div
          className="sort-criteria"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSortIncrease(!sortIncrease);
          }}
        >
          Engagement Rate
          <img src={sortIcon} />
        </div>
      </div>
      <ul className="pr-video-list">
        {skillsLoading ? (
          <li className="pr-video">
            <div className="skills-loading">
              <div className="skills-header">
                <div className="shimmerBG skills-loading-circle"></div>
                <div className="skills-loading-rect-wrapper">
                  <div className="shimmerBG skills-loading-rect-one"></div>
                  <div className="shimmerBG skills-loading-rect-two"></div>
                </div>
              </div>
              <div className="shimmerBG skills-loading-rect-three"></div>
            </div>
          </li>
        ) : (
          skills.map((skill) => {
            return (
              <li
                className="pr-video"
                key={skill.skillId}
                onClick={() => {
                  showCompleteStats(skill.skillId, skill.poster);
                }}
              >
                <div className="pr-video__banner">
                  <img src={skill.poster} />
                </div>
                <div className="pr-video__details">
                  {skill.name ? (
                    <div className="pr-video__name">{skill.name}</div>
                  ) : (
                    ''
                  )}
                  <div className="pr-video__views">
                    <img src={EyeIcon} /> {skill.viewCount}
                  </div>
                </div>
                <div className="pr-video__sort-value">
                  {skill.retentionRate} <span className="perc">%</span>
                </div>
                <div className="pr-video__more">
                  <img src={RightArrIcon} />
                </div>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
}
