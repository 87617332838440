import React, { useState } from 'react';
import classnames from 'classnames';
import { isEmpty } from '../../utils';
import { Fade } from 'react-reveal';
import './pass-input.scss';

import EyeIcon from '../../assets/eyeicon.svg';
import EyeOpenIcon from '../../assets/eyeopen.svg';

function PassInput(props) {
  const {
    inputStyle,
    label,
    labelStyle,
    placeholder,
    value,
    onChange,
    onBlur,
    info,
    infoStyle,
    isValid = true,
    handleEnter = () => {},
    showMarginBottom,
    isInviteScreen,
  } = props;

  const labelClass = classnames('d-ib fs-8 fw-900 ', labelStyle);
  const inputClass = classnames(
    'd-ib',
    {
      'mb-20': isEmpty(info),
      'mb-15': !isEmpty(info),
      'b-error': !isValid,
    },
    inputStyle
  );
  const infoClass = classnames('d-ib fs-8 fw-900 c-error', infoStyle);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const onEnterKey = (e) => {
    if (e.keyCode === 13) {
      handleEnter();
    }
  };

  return (
    <div>
      <div className="input-info d-flex space-between">
        {label && (
          <div>
            <label stye={{ color: '#3A3A3A' }} className={labelClass}>
              {label}
            </label>
          </div>
        )}
        {info && (
          <div className="mb-5">
            <Fade bottom duration="150">
              <label className={infoClass}>{info}</label>
            </Fade>
          </div>
        )}
      </div>
      <div className="passinput">
        <input
          type={passwordShown ? 'text' : 'password'}
          className={inputClass}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onEnterKey}
          style={{ marginBottom: showMarginBottom ? showMarginBottom : '20px' }}
        ></input>
        <img
          style={{
            marginTop: showMarginBottom ? '20px' : '0px',
            transform: isInviteScreen
              ? 'translateX(14.5rem) translateY(-100%)'
              : 'translateX(13rem) translateY(-100%)',
          }}
          onClick={togglePasswordVisiblity}
          src={passwordShown ? EyeOpenIcon : EyeIcon}
        ></img>
      </div>
    </div>
  );
}

export default PassInput;
