export const DOMAIN =
  process.env.REACT_APP_ENV === 'development'
    ? 'https://7ab0-27-58-199-37.ngrok.io'
    : process.env.REACT_APP_ENV === 'staging'
    ? 'https://staging.theskill.store'
    : 'https://dashboard.api.theskill.store';
export const TIME_LIMIT = 60;
export const MOBILE_NUMBER_LENGTH = 13;
export const APP_STORE_LINK =
  'https://apps.apple.com/gb/app/holofy-products/id1532086171';
export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.holofy.store.products&gl=GB';
export const TERMS_LINK = 'https://www.holofy.io/termsandconditions';
export const TUTORIAL_LINK = 'https://vimeo.com/462632088/1a8e53b89c';
export const WIX_LINK = 'https://vimeo.com/461815361';
export const SQUARE_SPACE_LINK = 'https://vimeo.com/461805969';
export const SHOPIFY_LINK = 'https://vimeo.com/461784906';
export const WEBFLOW_LINK = 'https://vimeo.com/461821787';
export const TEAM_PERFORMANCE_MAP = {
  averageClose: 'Average Close',
  spacesLet: 'Spaces Let',
  spacesAdded: 'Spaces Added',
};
export const TEAM_SIZE_LIMIT = 10;

export const APPLE_CLIENT_ID =
  process.env.REACT_APP_ENV === 'staging'
    ? 'ios.holofy.productsstaging'
    : 'ios.holofy.products';

export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_SSO_CLIENT_PRODUCTION
    : process.env.REACT_APP_SSO_CLIENT_STAGING;

export const S3_VIDEO_BUCKET =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_S3_BUCKET_PRODUCTION
    : process.env.REACT_APP_S3_BUCKET_STAGING;

export const PLAN_DETAILS = [
  {"users": "800","price": 49.99},
  {"users": "2,000","price": 79.99},
  {"users": "10,000","price": 299},
  {"users": "15,000","price": 370},
  {"users": "20,000","price": 420},
  {"users": "25,000","price": 480},
  {"users": "30,000","price": 530},
  {"users": "40,000","price": 580},
  {"users": "50,000","price": 640},
  {"users": "75,000","price": 700},
  {"users": "100,000","price": 750},
  {"users": "130,000","price": 850},
  {"users": "150,000","price": 950},
  {"users": "200,000","price": "1,190"},
  {"users": ">200,000", "price": "Enterprise Plan"}
]