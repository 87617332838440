import React, {useState} from 'react';
import './planusageestimate.scss';
import PlanUsage from '../PlanUsage';

// Assets
import EyeIcon from '../../assets/impression-stat.svg';
import RetentionIcon from '../../assets/retention-stat.svg';
import ProductIcon from '../../assets/Products-phone.svg';
import DaysIcon from '../../assets/days.svg';
import HourGlassIcon from '../../assets/hour-glass.svg';

export default function PlanUsageEstimate(
{
  dataUsed,
  freeUser,
  handleSubscription,
  signoutHandler,
  domain,
  userToken,
  userDetails,
}
) {
  return (
    <div className="plan-usage-estimate-wrapper">
      <div className="sign-out-btn">
        <button onClick={signoutHandler}>sign out</button>
      </div>
      <div className="hour-glass__wrapper">
        <img src={HourGlassIcon}/>
      </div>
      <div className="tier-status">
        <div className="tier-status__title">
          {
            freeUser ? 'Your trial is up!' : 'Your subscription is on pause.'
          }
        </div>
        <div className={freeUser ? "tier-status__msg" : "tier-status__msg red-text"}>
          {
            freeUser ? ' So how was it? Are your sales up already? Here’s a quick overview of your trial period:' : 
            'We could not process your latest payment and your account is temporarily suspended.'
          }
       
        </div>
      </div>
      <div className="usage-container">
        <PlanUsage 
          icon={DaysIcon}
          value={dataUsed.daysUsed} 
          resourceType={'Days Used'}>
        </PlanUsage>
        <PlanUsage 
          icon={ProductIcon}
          value={dataUsed.productsAdded} 
          resourceType={'Products Added'}>
        </PlanUsage>
        <PlanUsage 
          icon={EyeIcon}
          value={dataUsed.engagedVisitors} 
          resourceType={'Engaged Visitors'}>
        </PlanUsage>
        <PlanUsage 
          icon={RetentionIcon}
          value={dataUsed.engagementRate} 
          resourceType={'Engagement Rate'}>
        </PlanUsage>
      </div>
      <div className="estimate-container">
        {
          freeUser ? 
          <div className="msg">
            Based on the number of engaged visitors so far you would pay:
          </div> :
          <div className="msg">
            To re-enable your subscription you would have to pay
          </div>
        }
        
        <div className="pricing-container">
          <div className="amount-container">
           <span span className="currency-icon">$</span> <span className="amount">{dataUsed.priceToPay.toLocaleString()}</span>
          </div>
          <div className="amount-period">per month</div>
        </div>
      </div>

      <div className="subscribe-wrapper">
        <div className="subscribe-msg">
              To continue using our services, please {freeUser? ' subscribe' : ' re-subscribe'} 👇
        </div>
        <div className="subscribe-btn-wrapper">
          <button className="btn" onClick={() => {
            handleSubscription(domain, userToken, userDetails)
          }}>
            {
            freeUser? 'SUBSCRIBE': 'RE-START SUBSCRIPTION'
            }
          </button>
        </div>
      </div>
    </div>
  )
}
