import { createContext } from 'react';

export const SignupContext = createContext([{}, () => {}]);
export const ResetContext = createContext([{}, () => {}]);

// Edit Member Context
export const EditMemberContext = createContext([{}, () => {}]);
// Current Product Selected Context
export const currentProductContext = createContext([{}, () => {}]);

// Global Profile
export const GlobalProfile = createContext([{}, () => {}]);
// Stats Context
export const StatsContext = createContext([{}, () => {}]);

// Upload Context
export const UploadContext = createContext([{}, () => {}]);
