import Signin from '../pages/signin';
import MyProducts from '../pages/myProducts';
import MySettings from '../pages/mySettings';
import Teams from '../pages/teams';
import MobileBlock from '../pages/mobileBlock';
import Stats from '../pages/myStats';
import MarketingPage from '../pages/marketingPage';
import Invite from '../pages/invite';
import InviteLogin from '../pages/inviteLogin';
import HomePage from '../pages/homePage';
import Signup from '../pages/signup';

export default [
  {
    path: '/',
    component: HomePage,
    exact: true,
  },
  {
    path: '/signup',
    component: Signup,
    exact: true,
  },
  {
    path: '/signin',
    component: Signin,
    exact: true,
  },
  {
    path: '/myTeam',
    component: Teams,
    exact: true,
  },
  {
    path: '/myProducts',
    component: MyProducts,
    exact: true,
  },
  {
    path: '/mySettings',
    component: MySettings,
    exact: true,
  },
  {
    path: '/mobileBlock',
    component: MobileBlock,
    exact: true,
  },
  {
    path: '/df4S1lgpw4/email',
    component: MarketingPage,
    exact: true,
  },
  {
    path: '/myStats',
    component: Stats,
    exact: true,
  },
  {
    path: '/invite',
    component: Invite,
    exact: true,
  },
  {
    path: '/invite-login',
    component: InviteLogin,
    exact: true,
  },
  {
    path: '/*',
    component: HomePage,
    exact: true,
  },
];
