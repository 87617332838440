import React, { useState, useEffect, useContext } from 'react';
import './topbar.scss';
import { DOMAIN } from '../../constants/base';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

// Modules
import {
  getSessionStorage,
  isEmpty,
  setSessionStorage,
  removeSessionStorage,
} from '../../utils';
import { getReq, postReq } from '../../utils/api';
import Avatar from 'react-avatar';
import ProgressBar from '../ProgressBar';
import PopUpEditMember from '../PopUpEditMember';
import { GlobalProfile } from '../../context';
import OneTimeNotification from '../../components/OneTimeNotification';

// Assets
import HolofyProductLogo from '../../assets/holofy-black.png';
import PopUpPlanType from '../PopUpPlanTypes';
import { handleSubscription } from '../../utils/stripe-handler';

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const TopBar = () => {
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
  const userDetails = getSessionStorage('ss-user') || {};

  const {
    name = '',
    avatar,
    id,
    email,
    admin,
    phone,
    countryCode,
    countryName,
    owner,
  } = userDetails;
  const [globalProfile, dispatch] = useContext(GlobalProfile);
  const [currentDataUsed, setCurrentDataUsed] = useState();
  const [currentPlan, setCurrentPlan] = useState('');
  const [currentOutstanding, setCurrentOutstanding] = useState('');

  // PopUps
  const [showPlansPopUp, setShowPlansPopUp] = useState(false);
  const [showEditPopUp, setShowEditPopUp] = useState(false);

  // states for new product usages
  const [engagedVisitors, setEngagedVisitors] = useState();
  const [rangeMax, setRangeMax] = useState();
  const [rangeMin, setRangeMin] = useState();
  const [planSubscribed, setPlanSubscribed] = useState(false);
  const [planTypeObj, setPlanTypeObj] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  // making re render when session storage is set
  const refreshPage = useForceUpdate();

  // payment handling status
  const paymentUrl = useLocation().search;
  const status = new URLSearchParams(paymentUrl).get('payment_status');
  const [showPaymentStatus, setShowPaymentStatus] = useState(
    status ? true : false
  );
  const [shouldRedirectMobileBlock, setShouldRedirectMobileBlock] =
    useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (window.screen.width < 800) {
      setShouldRedirectMobileBlock(true);
    }
    //Api to get user details with access token
    if (isEmpty(userDetails)) {
      fetchUserDetails();
    }
    getUserCurrentUsage();
    setTimeout(() => {
      setShowPaymentStatus(false);
    }, 5000);
  }, []);

  useEffect(() => {
    if (showPlansPopUp) {
      getUserCurrentUsage();
    }
  }, [showPlansPopUp]);

  useEffect(() => {
    if (typeof phone === 'string') {
      dispatch({
        type: 'EDIT_GLOBAL_PROFILE',
        payload: {
          id: id,
          avatar: avatar,
          name: name,
          email: email,
          admin: admin,
          owner: owner,
          phone: {
            phone: phone,
            countryCode,
            countryName,
          },
        },
      });
    } else {
      dispatch({
        type: 'EDIT_GLOBAL_PROFILE',
        payload: {
          id: id,
          avatar: avatar,
          name: name,
          email: email,
          admin: admin,
          owner: owner,
          phone: phone,
        },
      });
    }
  }, []);

  // Redirects
  if (shouldRedirectMobileBlock) {
    return <Redirect to="/mobileBlock" />;
  }

  async function fetchUserDetails() {
    const url = `${DOMAIN}/v1/dashboard/product/user/login/details`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const statusResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error, successCode, data } = statusResponse;
    if (successCode === 'SUCC200') {
      setSessionStorage({
        key: 'ss-user',
        value: data,
        expiry: 20160, // 2 Weeks
      });
      refreshPage();
    }
    if (error === 'ERR401') {
      proceedHandler();
    }
  }

  // get user details
  async function getUserCurrentUsage() {
    setShowLoader(true);
    const url = `${DOMAIN}/v1/dashboard/products/plans/usage`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const currentUsage = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = currentUsage;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      dispatch({
        type: 'SET_USER_CURRENT_USAGE',
        payload: {
          currentTier: data.currentTier,
          daysLeft: data.daysLeft,
          engagedVisitors: data.engagedVisitors,
          nextTier: data.nextTier,
          planEnds: data.planEnds,
          priceToPay: data.priceToPay,
          range: data.range,
          paymentInfo: data.paymentInfo ? data.paymentInfo : null,
          trialUp: data.daysLeft > 0 ? false : true,
        },
      });
      setEngagedVisitors(data.engagedVisitors);
      setRangeMin(data.range.starts);
      setRangeMax(data.range.ends);
      setPlanTypeObj(data);
      setShowLoader(false);
    }
  }

  const handleEditPopUp = () => {
    setShowEditPopUp(true);
  };

  const proceedHandler = () => {
    document.body.classList.remove('ovf-hidden');
    localStorage.removeItem('ss-auth');
    removeSessionStorage('ss-user');
    setShouldRedirect(true);
    try {
      window.Intercom('shutdown');
    } catch (e) {
      console.log(e);
    }
  };

  if (shouldRedirect) {
    return <Redirect to="/signin" />;
  }

  return (
    <>
      <div className="topbar d-flex space-between h-center pos-fix top-0">
        {/* payment status */}

        {status === 'success' && showPaymentStatus ? (
          <>
            <div className="payment-status success">
              Yay! Your subscription is activated! Let’s get selling!
            </div>
          </>
        ) : status === 'failed' && showPaymentStatus ? (
          <>
            <div className="payment-status failed">
              <div className="msg">
                <div className="title">Oops, payment failed</div>
                <div className="title-msg">
                  Payment to activate your membership could not be processed.
                  Please try again.
                </div>
              </div>
              <div
                className="try-again"
                onClick={() =>
                  handleSubscription(DOMAIN, userToken, userDetails)
                }
              >
                try again
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        <OneTimeNotification />
        <img
          className="product-logo-topbar ml-30"
          src={HolofyProductLogo}
        ></img>

        <div className="left-menu-wrap d-flex center-align">
          {currentPlan === 'Basic' || 'Business' || 'Business Plus' ? (
            <button
              disabled={showLoader}
              onClick={() => setShowPlansPopUp(true)}
              className="dataused-wrapper"
            >
              <h1>{'YOUR PLAN'}</h1>
              <ProgressBar
                percentage={engagedVisitors}
                min={rangeMin}
                max={rangeMax}
              ></ProgressBar>
            </button>
          ) : (
            <button
              onClick={() => setShowPlansPopUp(true)}
              className="dataused-wrapper-business"
            >
              <div className="col right-border">
                <h2>STREAMED</h2>
                <h1>{`${currentDataUsed ? currentDataUsed : 0}GB`}</h1>
              </div>
              <div className="col">
                <h2>TO PAY</h2>
                <h1>{`$${
                  currentOutstanding
                    ? Math.round((currentOutstanding + Number.EPSILON) * 100) /
                      100
                    : 0
                }`}</h1>
              </div>
            </button>
          )}

          {avatar ? (
            <div
              className="top-menu-item-user"
              onClick={handleEditPopUp}
              typeof="Button"
            >
              <img src={avatar}></img>
            </div>
          ) : (
            <Avatar
              name={name}
              value=""
              size="42"
              round={true}
              color="black"
              maxInitials={1}
              textSizeRatio={2}
              style={{ fontWeight: '700' }}
              onClick={handleEditPopUp}
            />
          )}
        </div>
      </div>
      {showPlansPopUp ? (
        <PopUpPlanType
          planTypeObj={planTypeObj}
          showLoader={showLoader}
          setShowPlansPopUp={setShowPlansPopUp}
        ></PopUpPlanType>
      ) : (
        ''
      )}
      {showEditPopUp ? (
        <PopUpEditMember
          setEditMemberPopUp={setShowEditPopUp}
          isUserProfileEdit={true}
          userDetails={userDetails}
        ></PopUpEditMember>
      ) : (
        ''
      )}
      {globalProfile?.usageDetails?.trialUp && (
        <PopUpPlanType planTypeObj={globalProfile.usageDetails}></PopUpPlanType>
      )}
    </>
  );
};

export default TopBar;
