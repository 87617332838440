import React, { useState, useContext } from 'react';
import './popupinvitenewmember.scss';
import Pulse from 'react-reveal/Pulse';
import TextAtom from '../TextAtom';
import ButtonAtom from '../ButtonAtom';
import { isEmpty, validateEmail, getSessionStorage } from '../../utils';
import Input from '../Input';
import { postReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';

// Assets
import BackImg from '../../assets/back.png';

const PopUpInviteNewMember = ({
  setInviteMemberPopup,
  fetchActiveMembers,
  setStatusMsg,
  setErrorMsg,
}) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  //Errors
  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  //Blur Handler

  const fullNameBlurHandler = () => {
    if (isEmpty(fullName)) {
      setFullNameError(true);
    }
  };

  const emailBlurHandler = () => {
    const isValid = !validateEmail(email);
    setEmailError(isValid);
  };

  //   Invite Member

  const handleInvite = async () => {
    setShowLoader(true);
    if (!isEmpty(fullName) && validateEmail(email)) {
      const url = `${DOMAIN}/v1/dashboard/product/user/invite-team`;
       const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
   
      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      const payload = {
        name: fullName,
        email,
      };
      const invitationResponse = await postReq({
        url,
        payload,
        requestHeaders,
      });
      const { error = '', successCode = '', data = {} } = invitationResponse;
      if (error === 'ERR404') {
        // setStatusMsg('Your session is expired. Try logging in again.');
        // setStatusMsgType('error');
        setShowLoader(false);
        return;
      }
      if (error === 'ERR409') {
        setStatusMsg(true);
        setErrorMsg('User Already Exist');
        setInviteMemberPopup(false);
        setShowLoader(false);
        return;
      }
      if (successCode === 'SUCC200') {
        // setStatusMsg('Your team member invitation has been sent.');
        // setStatusMsgType('info');
        setInviteMemberPopup(false);
        fetchActiveMembers();
        setShowLoader(false);
      }
    }
  };

  return (
    <div className="popups-wrapper">
      <ClickAwayContentWrapper onClose={() => setInviteMemberPopup(false)}>
        <Pulse duration="150">
          <div className="form-card-wrapper">
            <button
              className="back-button w-100p d-flex"
              onClick={() => setInviteMemberPopup(false)}
            >
              <img src={BackImg} alt="back"></img>
            </button>
            <div className="form-wrapper mt-20">
              <TextAtom
                textContent="Invite Team Member"
                fontFamily="Montserrat"
                fontWeight="700"
                fontSize="1rem"
                color="#3A3A3A"
              ></TextAtom>
              <div className="input-wrapper mt-20">
                <Input
                  type="text"
                  label="Full Name"
                  labelStyle="uc mb-5"
                  placeholder="John Doe"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  info={fullNameError ? "CAN'T BE BLANK" : ''}
                  onBlur={fullNameBlurHandler}
                />
                <Input
                  type="text"
                  label="Email"
                  labelStyle="uc mb-5"
                  placeholder="john@holofy.io"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  info={emailError ? 'ENTER VALID EMAIL' : ''}
                  onBlur={emailBlurHandler}
                />
                <ButtonAtom
                  label="CONTINUE"
                  onClick={handleInvite}
                  showLoader={showLoader}
                ></ButtonAtom>
              </div>
            </div>
          </div>
        </Pulse>
      </ClickAwayContentWrapper>
    </div>
  );
};

export default PopUpInviteNewMember;
