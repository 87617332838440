import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

function linkField(props) {
  const { href, linkText, label, labelStyle, linkStyle, contStyle } = props;
  const labelClass = classnames("d-ib c-gray fs-12 fw-900 mr-10", labelStyle);
  const linkClass = classnames("d-ib c-gray fs-12 c-blue", linkStyle);
  const contClass = classnames("mb-10", contStyle);
  return (
    <div className={contClass}>
      {label && <label className={labelClass}>{label}</label>}
      <Link to={href} className={linkClass}>
        {linkText}
      </Link>
    </div>
  );
}

export default linkField;
