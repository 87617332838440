import React from 'react';
import './statscard.scss';
import LoadingSpin from '../../components/LoadingSpin';
import { AreaChart, Area, linearGradient, ResponsiveContainer } from 'recharts';

// Assets
import RightArrIcon from '../../assets/right-arr.png';

const StatsCard = ({
  icon,
  title,
  totalValue,
  subtitle,
  graph,
  onClick,
  cardsSpinner,
  tempCondition,
}) => {
  return (
    <div
      className="stats-card"
      onClick={cardsSpinner ? '' : tempCondition ? '' : onClick}
      style={{
        cursor: cardsSpinner || tempCondition ? 'unset' : 'pointer',
        background: cardsSpinner
          ? 'rgba(255, 255, 255, 0.2)'
          : 'rgba(255, 255, 255, 0.72)',
      }}
    >
      {cardsSpinner && (
        <div className="pos-abs">
          <div className="loading-card-wrapper">
            <div className="loading-top-wrapper">
              <div className="shimmerBG loading-circle"></div>
              <div className="shimmerBG loading-top-rectangle"></div>
            </div>
            <div className="shimmerBG loading-bottom-rectangle"></div>
          </div>
        </div>
      )}

      {!cardsSpinner && (
        <>
          {' '}
          <div className="stats-content">
            <div className="stats-card-header">
              <div className="stats-card-title">
                <div className="icon-wrapper">
                  <img src={icon}></img>
                </div>
                {title}
              </div>
              <img src={RightArrIcon}></img>
            </div>
            <div className="stats-card-main">
              <div className="stats-card-graph-content">
                {title === 'Impressions' ? (
                  <h1>{Number(totalValue).toLocaleString()}</h1>
                ) : (
                  <h1>
                    {totalValue}
                    <span className="perc">%</span>
                  </h1>
                )}
                <h2>{subtitle}</h2>
              </div>
            </div>
          </div>
          <div className="stats-card-graph-wrapper">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart width={200} height={200} data={graph}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#000" stopOpacity={0.2} />
                    <stop offset="50%" stopColor="#000" stopOpacity={0.05} />
                    <stop offset="95%" stopColor="#000" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="count"
                  stroke="transparent"
                  fillOpacity={0.5}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default StatsCard;
