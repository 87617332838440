import React, { useState, useEffect } from 'react';
import './inviteLogin.scss';
import {
  isEmpty,
  getSessionStorage,
  getParameterByName,
  setSessionStorage,
} from '../../utils';
import VideoAtom from '../../components/VideoAtom';
import TextAtom from '../../components/TextAtom';
import ButtonAtom from '../../components/ButtonAtom';
import ProductAtom from '../../components/ProductAtom';
import Avatar from 'react-avatar';
import LoadingSpin from '../../components/LoadingSpin';
import { Bounce } from 'react-reveal';
import { Redirect } from 'react-router-dom';
import { DOMAIN } from '../../constants/base';
import { getReq } from '../../utils/api';

function InviteLoginContainer(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [shouldRedirect, setRedirect] = useState(false);
  const [shouldRedirectMobileBlock, setShouldRedirectMobileBlock] =
    useState(false);

  const mobileBlock = document.getElementById('mobileBlock');

  const sessionData = localStorage.getItem('ss-auth') || {};
  const userToken = getParameterByName('userToken');

  useEffect(() => {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_KEY,
    });
  }, []);

  useEffect(() => {
    (async () => {
      const url = `${DOMAIN}/v1/dashboard/product/user/invite-team/team-info`;
      const requestHeaders = {
        authorization: userToken,
      };
      const userInviteResponse = await getReq({ url, requestHeaders });
      const { successCode = '', error = '', data = {} } = userInviteResponse;
      if (successCode === 'SUCC200') {
        setData(data);
        setLoading(false);
      }
    })();
  }, []);

  const joinTeamHandler = async () => {
    const url = `${DOMAIN}/v1/dashboard/product/user/invite-team/join-team/${userToken}`;
    const requestHeaders = {
      authorization: userToken,
    };
    const userInviteResponse = await getReq({ url, requestHeaders });
    const { successCode = '', error = '', data = {} } = userInviteResponse;
    if (successCode === 'SUCC200' && window.innerWidth >= 800) {
      localStorage.setItem('ss-auth', JSON.stringify(data));
      // setSessionStorage({
      //   key: 'ss-auth',
      //   value: data,
      //   expiry: 20160, // 2 Weeks
      // });
      setRedirect(true);
    }
    if (successCode === 'SUCC200' && window.innerWidth < 800) {
      if (mobileBlock) {
        mobileBlock.style.display = 'flex';
      }
      localStorage.setItem('ss-auth', JSON.stringify(data));
      // setSessionStorage({
      //   key: 'ss-auth',
      //   value: data,
      //   expiry: 20160, // 2 Weeks
      // });
      setShouldRedirectMobileBlock(true);
    }
  };

  if (isEmpty(userToken)) {
    if (sessionData) {
      return <Redirect to="/myTeam" />;
    }
    return <Redirect to="/signin" />;
  }

  if (shouldRedirect) {
    return <Redirect to="/myTeam" />;
  }

  if (shouldRedirectMobileBlock) {
    return <Redirect to="/mobileBlock" />;
  }

  return (
    <>
      <div className="invite">
        <ProductAtom></ProductAtom>
        <VideoAtom></VideoAtom>
        <Bounce>
          <div className="form-card-wrapper-invite custom-blur">
            {loading ? (
              <LoadingSpin></LoadingSpin>
            ) : (
              <div>
                <TextAtom
                  textContent={`Hi ${data.name ? data.name : ''}`}
                  className="w-100p"
                  fontWeight="700"
                  fontSize="20px"
                  lineHeight="25px"
                ></TextAtom>
                <div className="d-flex center-align align-middle mt-10">
                  {data.avatars && data.avatars[0].length > 1 ? (
                    <div className="image-wrapper-main">
                      <img src={data.avatars && data.avatars[0]}></img>
                    </div>
                  ) : (
                    <Avatar
                      name={data.avatars && data.avatars[0]}
                      size="56"
                      round={true}
                      color="black"
                      maxInitials={1}
                      textSizeRatio={2}
                      textMarginRatio={0}
                      style={{ fontWeight: '700', marginLeft: '5px' }}
                    />
                  )}

                  <p className="ml-10">
                    {data.adminName ? data.adminName : ''}, your teammate has
                    invited you to join Holofy Products.
                  </p>
                </div>
                <div className="mt-10 w-100p">
                  <p>
                    {data.memberCount && data.memberCount}
                    {data.memberCount > 1 ? ' others are' : ' other is'} waiting
                    for you
                  </p>
                  <div className="d-flex vertical-center">
                    {data.avatars &&
                      data.avatars.map((avatar) => {
                        return avatar.length > 1 ? (
                          <div className="image-wrapper-invite ml-5">
                            <img src={avatar} alt={avatar}></img>
                          </div>
                        ) : (
                          <Avatar
                            name={avatar}
                            size="32"
                            round={true}
                            color="black"
                            maxInitials={1}
                            textSizeRatio={2}
                            textMarginRatio={0}
                            style={{ fontWeight: '700', marginLeft: '5px' }}
                          />
                        );
                      })}
                    {data.memberCount && data.memberCount > 5 ? (
                      <button className="more-invite-btn ml-5">
                        {`+${
                          data.memberCount &&
                          data.memberCount - data.avatars.length
                        }`}
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="line mt-20"></div>
                <div className="mt-20 w-100p">
                  <div className="email-inpt d-flex f-col space-between">
                    <label className="d-ib mb-5">Email</label>
                    <div className="input-wrap">
                      <Avatar
                        name={data.name && data.name}
                        size="24"
                        round={true}
                        color="black"
                        maxInitials={1}
                        textSizeRatio={2}
                        textMarginRatio={0}
                        style={{ fontWeight: '700', marginLeft: '10px' }}
                      />
                      <h2 className="email-custom">
                        {data.email ? data.email : 'email@email.com'}
                      </h2>
                    </div>
                  </div>
                  <div className="d-flex f-col vertical-center mt-20">
                    <ButtonAtom
                      label="JOIN TEAM"
                      onClick={joinTeamHandler}
                    ></ButtonAtom>
                  </div>
                  <div className="important-note-wrapper mt-20">
                    <h2>Important Note:</h2>
                    <p>
                      Any product created on your account will be included to
                      this team.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Bounce>
      </div>
    </>
  );
}

export default InviteLoginContainer;
