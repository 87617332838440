import React, { useReducer } from 'react';
import TopBar from '../../components/TopBar';
import StatsComponent from '../../containers/Stats';
// State Management
import { StatsContext } from '../../context/';
import statsReducer from '../../context/statsReducer';

function Stats() {
  const initialState = {
    lastUpdated: {},
    overallImpressions: {
      value: '',
      graphPlot: [],
    },
    overallRetention: {
      value: '',
      graphPlot: [],
    },
    overallCTA: {
      value: '',
      graphPlot: [],
    },
    mostViewedTimes: {
      graph: [],
      xAxis: [],
      yAxis: [],
    },
    startDate: '',
    endDate: '',
    showPopUp: false,
    currentGraphType: '',
  };
  const [state, dispatch] = useReducer(statsReducer, initialState);

  return (
    <>
      <StatsContext.Provider value={[state, dispatch]}>
        <TopBar />
        <StatsComponent />
      </StatsContext.Provider>
    </>
  );
}

export default Stats;
