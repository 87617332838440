import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from '../../utils';
import { Link, Redirect } from 'react-router-dom';
import './homepage.scss';
import {PLAN_DETAILS} from '../../constants/base';
import TierSlider from '../../components/TierSlider';
import SimpleSlider from '../../components/FeatureCarousel';
import VideoAtom from '../../components/VideoAtom';
import ProductAtom from '../../components/ProductAtom';

import TierSep from '../../assets/comp-separator.svg';


const HomePage = () => {

  const [tierPlans, setTierPlans] = useState({});
  const [tierPlansPrice, setTierPlansPrice] = useState([]);
  const [currentProgressedTier, setCurrentProgressTier] = useState();
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);
  const [redirectToSignUp, setRedirectToSignUp] = useState(false);
  const [redirectToMyProducts, setRedirectToMyProducts] = useState(false);

  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};

  useEffect(() => {
    if (!isEmpty(userToken)) {
      setRedirectToMyProducts(true)
    } else {
      // preventing to sign in page
      setRedirectToSignIn(false)
    }
    let objTiers = {};
    let objTierPrice = {}
    let idx = 0;
    for (let arr of PLAN_DETAILS) {
      objTiers[idx] = arr.users;
      objTierPrice[idx] = arr.price;
      idx += 1;
    }
    // objTierPrice[idx] = 'Enterprise Plan';
    // objTiers[idx] = '>200000';
    setTierPlans(objTiers);
    setTierPlansPrice(objTierPrice);
  }, []);

  if (redirectToSignIn) {
    return <Redirect to="/signin" />;
  }

  if (redirectToSignUp) {
    return <Redirect to="/signup" />;
  }

  if (redirectToMyProducts) {
    return <Redirect to="/myProducts" />;
  }

  return(
  <div className="homepage">
    <ProductAtom></ProductAtom>
    <VideoAtom></VideoAtom>
    <div className="sign-in">
      <div>Already an existing user?</div>
      <div className="btn-wrapper">
        <Link to="/signin">
          <button className="btn" onClick={() => setRedirectToSignIn(true)}>SIGN IN</button>
        </Link>
      </div>
    </div>
    <div className="landing-container">
      <div className="plan-details">
        <div className="current-plan">
          <h1>Welcome!</h1>
          <div className="tagline">Bring your brand video content into a new shopping experience on your website.</div>
          <div className="btn-wrapper">
            <Link to="/signup">
              <button className="btn">start 14 day free trial</button>
            </Link>
          </div>
          <div className="payment-info">
            No credit card required
          </div>
        </div>
        <div className="tier-sep">
          <img src={TierSep}/>
        </div>
        <div className="plan-comparator">
          <h3>After trial ends</h3>
          <h2>Pay as you go!</h2>
          <div className="slider-tier">
            <TierSlider
            tierPlans={tierPlans}
            setCurrentProgressTier={setCurrentProgressTier}></TierSlider>
          </div>
          <div className="current-tier">
            {
              currentProgressedTier === 14 ? 
              <>
                <div className="price">{tierPlansPrice[currentProgressedTier]}</div>
                <div className="visitors">For more than  <span>{tierPlans[currentProgressedTier]}</span> engaged visitors. <br/> Our sales team will be in touch!</div>
              </>: 
              <>
                <div className="price"><span>$ </span>{tierPlansPrice[currentProgressedTier]}<span>/mo</span></div>
                <div className="visitors">with up to <span>{tierPlans[currentProgressedTier]}</span> engaged visitors</div>
              </>
            }
            
          </div>
        </div>
      </div>
      <div className="feature-carousel">
        <SimpleSlider></SimpleSlider>
      </div>
    </div>
  </div>
)
}

export default HomePage;