import React, { useEffect, useContext, useState, useRef } from 'react';
import './stats.scss';
import { Redirect } from 'react-router-dom';
import { Fade } from 'react-reveal';
import NavBar from '../../components/NavBar';
import LoadingSpin from '../../components/LoadingSpin';
import { getReq } from '../../utils/api';
import { getSessionStorage, isEmpty } from '../../utils';
import StatsCard from '../../components/StatsCard';
import StatsCardHeatmap from '../../components/StataCardHeatMap';
import StatsCardGraph from '../../components/StatsCardGraph';
import StatsPopUpGraph from '../../components/StatsPopUpGraph';
import { StatsContext } from '../../context';
import { DOMAIN } from '../../constants/base';
import { InView } from 'react-intersection-observer';
import DetailStats from '../StatsDetail/index';

//Assets
import EyeIcon from '../../assets/impression-stat.svg';
import EyeIconViewed from '../../assets/most-viewed-times-stat.svg';
import RetentionIcon from '../../assets/retention-stat.svg';
import CtaActionIcon from '../../assets/cta-action-stat.svg';
import SearchIcon from '../../assets/search.svg';
import StatsUpDownIcon from '../../assets/stats-up-down.png';
import StatsUpIcon from '../../assets/stats-up.png';
import StatsDownIcon from '../../assets/stats-down.png';
import RightArrIcon from '../../assets/right-arr.png';
import { set } from 'date-fns';
import FallBackImg from '../../assets/skillPlaceholder.png';
import tr from 'date-fns/esm/locale/tr/index.js';

const Stats = () => {
  const [statsValue, dispatch] = useContext(StatsContext);
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
  const userDetails = getSessionStorage('ss-user') || {};

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [impressionIncreasing, setImpressionIncreasing] = useState(null);
  const [retentionIncreasing, setRetentionIncreasing] = useState(null);
  const [ctaActionRateIncreasing, setCtaActionRateIncreasing] = useState(null);
  const [cardsSpinner1, setCardsSpinner1] = useState(true);
  const [cardsSpinner2, setCardsSpinner2] = useState(true);
  const [cardsSpinner3, setCardsSpinner3] = useState(true);
  const [cardsSpinner4, setCardsSpinner4] = useState(true);
  const [cardsSpinner5, setCardsSpinner5] = useState(true);
  const lastProductElem = useRef(null);

  // States
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [topOverallTraffic, setTopOverallTraffic] = useState([]);

  // Details Stats toggle
  const [showDetailStats, setShowDetailStats] = useState(false);
  const [activeProductID, setActiveProductID] = useState(null);

  useEffect(() => {
    if (isEmpty(userToken)) {
      setShouldRedirect(true);
    }
  }, []);

  async function getOverallImpressions() {
    setCardsSpinner1(true);
    const url = `${DOMAIN}/v1/dashboard/stats/v3/product/overall-impressions`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }

    if (successCode === 'SUCC200') {
      setCardsSpinner1(false);
      dispatch({
        type: 'SET_OVERALL_IMPRESSION',
        payload: {
          value: data.totalViews,
          graphPlot: data.overview,
          startDate: data.startDate,
          endDate: data.endDate,
          accountCreated: data.accountCreated,
        },
      });
    }
  }
  async function getOverallRetention() {
    setCardsSpinner2(true);
    const url = `${DOMAIN}/v1/dashboard/stats/v3/product/overall-retention-rate`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setCardsSpinner2(false);
      dispatch({
        type: 'SET_OVERALL_RETENTION',
        payload: {
          value: data.retentionRate === null ? 0 : data.retentionRate,
          graphPlot: data.overview,
          startDate: data.startDate,
          endDate: data.endDate,
          accountCreated: data.accountCreated,
        },
      });
    }
  }
  async function getOverallCTAActionRate() {
    setCardsSpinner3(true);
    const url = `${DOMAIN}/v1/dashboard/stats/v3/product/cta-action-rates`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setCardsSpinner3(false);
      dispatch({
        type: 'SET_OVERALL_CTAACTIONRATE',
        payload: {
          value: data.avgConversion,
          graphPlot: data.overview,
          startDate: data.startDate,
          endDate: data.endDate,
          accountCreated: data.accountCreated,
        },
      });
    }
  }
  async function getMostViewedTimes() {
    setCardsSpinner4(true);
    const url = `${DOMAIN}/v1/dashboard/stats/v3/product/most-viewed-times`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setCardsSpinner4(false);
      dispatch({
        type: 'SET_MOST_VIEWED_TIMES',
        payload: {
          graph: data.data,
          xAxis: data.keys.xAxis,
          yAxis: data.keys.yAxis,
        },
      });
    }
  }
  async function getTopTrafficSources() {
    setCardsSpinner5(true);
    const url = `${DOMAIN}/v1/dashboard/stats/v3/product/top-traffic-sources`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setCardsSpinner5(false);
      let sortedData = data.trafficRes.sort((s1, s2) =>
        s1.value > s2.value ? -1 : 1
      );
      dispatch({
        type: 'SET_TOP_TRAFFIC_SOURCES',
        payload: {
          startDate: data.startDate,
          endDate: data.endDate,
          graph: sortedData,
          accountCreated: data.accountCreated,
        },
      });
      if (data.trafficRes.length >= 4) {
        setTopOverallTraffic(sortedData.slice(0, 3));
      } else {
        setTopOverallTraffic(sortedData);
      }
    }
  }

  // Products Get Req

  // Getting All Products
  async function getAllProductsStats() {
    setLoading(true);
    let url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?searchStr=${searchString}`;
    if (impressionIncreasing === true) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?impressionsInc=true&searchStr=${searchString}`;
    } else if (impressionIncreasing === false) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?impressionsDec=true&searchStr=${searchString}`;
    }

    if (retentionIncreasing === true) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?retentionRateInc=true&searchStr=${searchString}`;
    } else if (retentionIncreasing === false) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?retentionRateDec=true&searchStr=${searchString}`;
    }

    if (ctaActionRateIncreasing === true) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?ctaActionRateInc=true&searchStr=${searchString}`;
    } else if (ctaActionRateIncreasing === false) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?ctaActionRateInc=false&searchStr=${searchString}`;
    }

    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });

    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setPage(1);
      setHasNextPage(data.hasNextPage);
      setProductsData(data.docs);
      setLoading(false);
    }
  }

  // Getting All Products
  async function filterByImpressions() {
    setLoading(true);
    let url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?impressionsInc=true&searchStr=${searchString}`;
    if (impressionIncreasing === true) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?impressionsInc=true&searchStr=${searchString}`;
    } else if (impressionIncreasing === false) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?impressionsDec=true&searchStr=${searchString}`;
    }
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });

    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setHasNextPage(data.hasNextPage);
      setProductsData(data.docs);
      setLoading(false);
    }
  }

  // Getting All Products
  async function filterByRetention() {
    setLoading(true);
    let url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?retentionRateInc=true&searchStr=${searchString}`;
    if (retentionIncreasing === true) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?retentionRateInc=true&searchStr=${searchString}`;
    } else if (retentionIncreasing === false) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?retentionRateDec=true&searchStr=${searchString}`;
    }
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });

    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setHasNextPage(data.hasNextPage);
      setProductsData(data.docs);
      setLoading(false);
    }
  }

  // Getting All Products
  async function filterByCtaActionRate() {
    setLoading(true);
    let url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?ctaActionRateInc=true&searchStr=${searchString}`;
    if (ctaActionRateIncreasing === true) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?ctaActionRateInc=true&searchStr=${searchString}`;
    } else if (ctaActionRateIncreasing === false) {
      url = `${DOMAIN}/v1/dashboard/products/page/${page}/v3/stats?ctaActionRateDec=true&searchStr=${searchString}`;
    }
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });

    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setHasNextPage(data.hasNextPage);
      setProductsData(data.docs);
      setLoading(false);
    }
  }

  // Fetching More Products
  async function fetchMoreProduct(nextPage) {
    setLoading(true);
    let url = `${DOMAIN}/v1/dashboard/products/page/${nextPage}/v3/stats?searchStr=${searchString}`;
    if (impressionIncreasing === true) {
      url = `${DOMAIN}/v1/dashboard/products/page/${nextPage}/v3/stats?impressionsInc=true&searchStr=${searchString}`;
    } else if (impressionIncreasing === false) {
      url = `${DOMAIN}/v1/dashboard/products/page/${nextPage}/v3/stats?impressionsDec=true&searchStr=${searchString}`;
    }

    if (retentionIncreasing === true) {
      url = `${DOMAIN}/v1/dashboard/products/page/${nextPage}/v3/stats?retentionRateInc=true&searchStr=${searchString}`;
    } else if (retentionIncreasing === false) {
      url = `${DOMAIN}/v1/dashboard/products/page/${nextPage}/v3/stats?retentionRateDec=true&searchStr=${searchString}`;
    }

    if (ctaActionRateIncreasing === true) {
      url = `${DOMAIN}/v1/dashboard/products/page/${nextPage}/v3/stats?ctaActionRateInc=true&searchStr=${searchString}`;
    } else if (ctaActionRateIncreasing === false) {
      url = `${DOMAIN}/v1/dashboard/products/page/${nextPage}/v3/stats?ctaActionRateDec=true&searchStr=${searchString}`;
    }
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });

    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setHasNextPage(data.hasNextPage);
      setProductsData([...productsData, ...data.docs]);
      setLoading(false);
    }
  }

  async function getLastStatsUpdated() {
    setLoading(true);
    const url = `${DOMAIN}/v1/dashboard/account/stats/last-updated`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      dispatch({
        type: 'SET_LAST_UPDATED',
        payload: {
          data: data,
        },
      });
      setLoading(false);
    }
  }
  useEffect(() => {
    getLastStatsUpdated();
    getOverallImpressions();
    getOverallRetention();
    getOverallCTAActionRate();
    getMostViewedTimes();
    getTopTrafficSources();
    getAllProductsStats();
  }, []);

  useEffect(() => {
    getAllProductsStats();
  }, [searchString]);

  useEffect(() => {
    if (impressionIncreasing !== null) {
      filterByImpressions();
    }
  }, [impressionIncreasing]);

  useEffect(() => {
    if (retentionIncreasing !== null) {
      filterByRetention();
    }
  }, [retentionIncreasing]);
  useEffect(() => {
    if (ctaActionRateIncreasing !== null) {
      filterByCtaActionRate();
    }
  }, [ctaActionRateIncreasing]);

  // Pop Up States
  const openPopUp = (graphType, isDetail) => {
    // console.log(isDetail);
    dispatch({
      type: 'SET_SHOW_POPUP',
      payload: {
        currentGraphType: graphType,
        showPopUp: true,
        isDetail: isDetail,
      },
    });
    // if (isDetail) {
    //   dispatch({
    //     type: 'SET_SHOW_POPUP',
    //     payload: {
    //       currentGraphType: graphType,
    //       showPopUp: true,
    //       isDetail: true,
    //     },
    //   });
    // } else if(isDetail === undefined) {
    //   dispatch({
    //     type: 'SET_SHOW_POPUP',
    //     payload: {
    //       currentGraphType: graphType,
    //       showPopUp: true,
    //       isDetail: undefined,
    //     },
    //   });
    // }
  };

  const closePopUp = () => {
    dispatch({
      type: 'SET_SHOW_POPUP',
      payload: {
        showPopUp: false,
      },
    });
  };

  if (shouldRedirect) {
    return <Redirect to="/signin" />;
  }

  return (
    <div className="d-flex main-dash-container">
      <NavBar selected="myStats" />
      <div className="w-100p content-width">
        <Fade duration="400">
          {showDetailStats ? (
            <DetailStats
              setShowDetailStats={setShowDetailStats}
              openPopUp={openPopUp}
              closePopUp={closePopUp}
            ></DetailStats>
          ) : (
            <div className="stats-container mr-20">
              <div className="stats-title ml-20 mr-20">Stats</div>
              <div className="stats-wrapper ml-20">
                <h2>Overall Performance</h2>
                <div className="stats-wrapper-cards mb-40">
                  <StatsCard
                    cardsSpinner={cardsSpinner1}
                    icon={EyeIcon}
                    title="Impressions"
                    totalValue={statsValue?.overallImpressions?.value}
                    subtitle={
                      statsValue?.lastUpdated?.overAllProductImpressions
                    }
                    graph={statsValue?.overallImpressions?.graphPlot}
                    onClick={() => openPopUp('overall-impressions', undefined)}
                    tempCondition={false}
                  ></StatsCard>
                  <StatsCard
                    cardsSpinner={cardsSpinner2}
                    icon={RetentionIcon}
                    title="Retention Rate"
                    totalValue={statsValue?.overallRetention?.value}
                    subtitle={statsValue?.lastUpdated?.overAllRetentionRate}
                    graph={statsValue?.overallRetention?.graphPlot}
                    onClick={() =>
                      openPopUp('overall-retention-rate', undefined)
                    }
                    tempCondition={true}
                  ></StatsCard>
                  <StatsCard
                    cardsSpinner={cardsSpinner3}
                    icon={CtaActionIcon}
                    title="CTA Action Rate"
                    totalValue="23,6%"
                    subtitle={statsValue?.lastUpdated?.overAllCtaActionRate}
                    totalValue={statsValue?.overallCTA?.value}
                    graph={statsValue?.overallCTA?.overAllCtaActionRate}
                    onClick={() => openPopUp('cta-action-rates', undefined)}
                    tempCondition={false}
                  ></StatsCard>
                  <StatsCardHeatmap
                    cardsSpinner={cardsSpinner4}
                    graph={statsValue?.mostViewedTimes?.graph}
                    xAxis={statsValue?.mostViewedTimes?.xAxis}
                    yAxis={statsValue?.mostViewedTimes?.yAxis}
                    icon={EyeIconViewed}
                  ></StatsCardHeatmap>
                  <StatsCardGraph
                    cardsSpinner={cardsSpinner5}
                    graph={topOverallTraffic}
                    handleClick={() => openPopUp('top-traffic-sources', false)}
                  ></StatsCardGraph>
                </div>
              </div>
              <div className="stats-product-performance-wrapper ml-20">
                <div className="header">
                  <h1>Product Performance</h1>
                  <h2>
                    Last Updated On {statsValue?.lastUpdated?.ctaActionRate}
                  </h2>
                </div>
                <div className="stats-product-performance-grid-wrapper">
                  <div className="stats-product-performance-input-wrapper">
                    <img src={SearchIcon}></img>
                    <input
                      type="text"
                      placeholder="Search product name..."
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                    />
                  </div>
                  <h2>Total Videos</h2>
                  <button
                    onClick={() => {
                      setPage(1);
                      setProductsData([]);
                      if (productsData.length > 0) {
                        setImpressionIncreasing(!impressionIncreasing);
                      }
                      setRetentionIncreasing(null);
                      setCtaActionRateIncreasing(null);
                    }}
                    className="stats-product-performance-sorting-btn"
                  >
                    Impressions
                    <img
                      src={
                        impressionIncreasing === null
                          ? StatsUpDownIcon
                          : impressionIncreasing === true
                          ? StatsUpIcon
                          : StatsDownIcon
                      }
                    ></img>
                  </button>
                  <button
                    className="stats-product-performance-sorting-btn"
                    onClick={() => {
                      setPage(1);
                      setProductsData([]);
                      if (productsData.length > 0) {
                        setRetentionIncreasing(!retentionIncreasing);
                      }
                      setImpressionIncreasing(null);
                      setCtaActionRateIncreasing(null);
                    }}
                  >
                    Retention Rate
                    <img
                      src={
                        retentionIncreasing === null
                          ? StatsUpDownIcon
                          : retentionIncreasing === true
                          ? StatsUpIcon
                          : StatsDownIcon
                      }
                    ></img>
                  </button>
                  <button
                    className="stats-product-performance-sorting-btn"
                    onClick={() => {
                      setPage(1);
                      setProductsData([]);
                      if (productsData.length > 0) {
                        setCtaActionRateIncreasing(!ctaActionRateIncreasing);
                      }
                      setImpressionIncreasing(null);
                      setRetentionIncreasing(null);
                    }}
                  >
                    CTA Action Rate
                    <img
                      src={
                        ctaActionRateIncreasing === null
                          ? StatsUpDownIcon
                          : ctaActionRateIncreasing === true
                          ? StatsUpIcon
                          : StatsDownIcon
                      }
                    ></img>
                  </button>
                </div>
                <div className="stats-product-performance-products-wrapper">
                  {productsData &&
                    productsData.length > 0 &&
                    productsData.map(
                      (product, index) =>
                        product && (
                          <div
                            className="stats-product-performance-product-card"
                            key={index}
                            onClick={() => {
                              setShowDetailStats(true);
                              dispatch({
                                type: 'SET_ACTIVE_PRODUCT',
                                payload: {
                                  productName: `${product.name}`,
                                  poster: `${product.skills[0].poster}`,
                                  productId: `${product.id}`,
                                  createdDate: `${product.createdAt}`,
                                },
                              });
                            }}
                          >
                            <div className="stats-product-performance-product">
                              {product.skills[0].poster.length > 0 ? (
                                <img src={product.skills[0].poster}></img>
                              ) : (
                                <img src={FallBackImg}></img>
                              )}
                              <div className="stats-product-performance-product-title">
                                <h2>PRODUCT NAME</h2>
                                <h1>{product.name}</h1>
                              </div>
                            </div>
                            <div className="total-videos">
                              {product?.skills?.length === 1
                                ? product?.skills?.length + ' Video'
                                : product?.skills?.length + ' Videos'}
                            </div>
                            <div className="product-values">
                              {product.impressions}
                            </div>
                            <div className="product-values">
                              {product.retentionRate}%
                            </div>
                            <div className="product-values">
                              {product.ctaActionRate}%
                            </div>
                            <button className="arr-btn">
                              <img src={RightArrIcon}></img>
                            </button>
                          </div>
                        )
                    )}
                  {loading && (
                    <div className="product-empty-container">
                      <div className="product-empty-main">
                        <div className="shimmerBG product-empty-card"></div>
                        <div className="product-empty-product-details-wrapper">
                          <div className="shimmerBG product-empty-productname"></div>
                          <div className="shimmerBG product-empty-producttagline"></div>
                        </div>
                      </div>
                      <div className="shimmerBG product-empty-productval"></div>
                      <div className="shimmerBG product-empty-productval"></div>
                      <div className="shimmerBG product-empty-productval"></div>
                      <div className="shimmerBG product-empty-productval"></div>
                    </div>
                  )}
                  {/* <div className="stats-product-performance-product-card"></div> */}
                  <InView
                    as="div"
                    onChange={(inView) => {
                      if (inView && hasNextPage && !loading) {
                        setPage(page + 1);
                        fetchMoreProduct(page + 1);
                      }
                    }}
                    className="stats-product-performance-product-card"
                  ></InView>
                </div>
              </div>
            </div>
          )}
        </Fade>
      </div>
      {statsValue.showPopUp && (
        <StatsPopUpGraph onBack={closePopUp}></StatsPopUpGraph>
      )}
    </div>
  );
};

export default Stats;
