import React from 'react';

const TextAtom = ({ textContent, fontFamily, fontWeight, fontSize, color }) => {
  return (
    <div
      className="text"
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: color,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {textContent}
    </div>
  );
};

export default TextAtom;
