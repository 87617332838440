import React from 'react';
import './statusmsgatom.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const StatusMsgAtom = ({
  LineOne,
  LineTwo,
  linkLabel,
  linkUrl,
  type,
  showDialogMobile,
  setShowDialogMobile,
}) => {
  const messageClass = classnames(
    'statusmsg-atom d-flex space-between center-align',
    {
      'bg-error': type === 'error',
      'bg-blue': type === 'info',
    }
  );
  return (
    <>
      <div className={messageClass}>
        <div className="message">
          {LineOne}
          <br></br>
          <span>{LineTwo}</span>
        </div>
        <Link to={linkUrl} className="status-link">
          {linkLabel}
        </Link>
      </div>
      {showDialogMobile ? (
        <div className="statusmsg-mobile">
          <div className="statusmsg-modal">
            <div className="message">
              {LineOne}
              <br></br>
              <span className="mt-20">{LineTwo}</span>
            </div>
            <div className="btns">
              <button onClick={() => setShowDialogMobile(false)}>
                Try Again
              </button>
              <Link to={linkUrl} className="status-link">
                {linkLabel}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default StatusMsgAtom;
