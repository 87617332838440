import React, { useEffect, useState } from 'react';
import './onetimenotification.scss';

//Assets
import AppAndPlay from '../../assets/app-and-play.svg';
import Close from '../../assets/close-white.svg';
import { getCookie, setCookie } from '../../utils';

const OneTimeNotification = () => {
    
    const [hideBanner, setHideBanner] = useState(getCookie('banner-cookie'));

    const appStore = () => {
        const URL = 'https://apps.apple.com/gb/app/holofy-products/id1532086171'
        window.open(URL, "_blank");
    }

    const playStore = () => {
        const URL = 'https://play.google.com/store/apps/details?id=com.holofy.store.products'
        window.open(URL, "_blank");
    }

    const setOneTimeCookie = () => {
        setCookie('banner-cookie', true, 1000);
        setHideBanner(true);
    }


    return (
        <div className="one-time-main-container">
            {!hideBanner && (
                <div className="one-time-container">
                    <img src={Close} onClick={setOneTimeCookie} />
                    <h4>Download the Holofy App to start filming and publishing Products!</h4>
                    <div className="one-time-container-stores">
                        <img src={AppAndPlay} />
                    </div>
                    <div className="one-time-container-link">
                        <div onClick={appStore} />
                        <div onClick={playStore} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default OneTimeNotification;
