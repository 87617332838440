import React, { useState, useEffect, useRef } from 'react';
import Pulse from 'react-reveal/Pulse';
import cc from 'currency-codes';
import '../PopUpAddNewItem/popupaddnewitem.scss';
import './popupeditproducthashtags.scss';
import { DOMAIN } from '../../constants/base';
import { postReq, putReq } from '../../utils/api';
import { onlyUnique, getUniqueListByObjectKey } from '../../utils';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import Input from '../Input';
//   Assets
import BackImg from '../../assets/back.png';
import minusIcon from '../../assets/minus.png';
import addIconBlack from '../../assets/addIconBlack.png';
import CloseHashtagsBtn from '../../assets/close-hashtags.png';
import dragHandleBtn from '../../assets/drag-handle.png';

const PopUpEditProductHashTag = ({
  productName,
  priceState,
  currencyState,
  hashTagsArray,
  setProductName,
  setPriceState,
  setCurrencyState,
  setHashTagsArray,
  handleBackBtnHashTag,
  selectedCardsProps,
}) => {
  const [enablePrice, setEnablePrice] = useState(priceState ? true : false);
   const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
   

  const [scrollHashTag, setScrollHashTag] = useState(false);

  const [enableHashtags, setEnableHashtags] = useState(
    hashTagsArray.length >= 1 ? true : false
  );

  // local changes
  const [name, setName] = useState(productName);
  const [currency, setCurrency] = useState(currencyState);
  const [price, setPrice] = useState(priceState);
  const [hashTags, setHashTags] = useState(hashTagsArray);

  const handleBackBtn = () => {
    handleBackBtnHashTag();
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() =>
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    );
    return <div ref={elementRef} />;
  };
  // Drag Props Hashtags
  const dragPropsHashtags = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...hashTags];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setHashTags(data);
    },
    nodeSelector: 'li',
    handleSelector: 'button',
    lineClassName: 'sort-line',
  };

  const addHashtag = () => {
    // setHashTagsArray([...hashTagsArray, '']);
    setHashTags([...hashTags, '']);
    setScrollHashTag(true);
  };

  const hashtagChangeHandler = (id, value) => {
    const newHashtagsValue = [...hashTags];
    newHashtagsValue[id] = { tag: value };
    // setHashTagsArray(newHashtagsValue);
    setHashTags(newHashtagsValue);
  };

  const removeHashtag = (id) => {
    var filteredArr = hashTags.filter(function (value, index, arr) {
      return index !== id;
    });
    setHashTags(filteredArr);
  };

  const savePopUpEdit = async () => {
    if (selectedCardsProps.id) {
      const url = `${DOMAIN}/v1/dashboard/products/${selectedCardsProps.id}`;
      let hashtagObj = [];
      let payload = {
        name: name,
      };
      if (enablePrice && (price.length === 0 || price)) {
        payload = {
          ...payload,
          price: price ? parseFloat(price) : 0,
          currency: currency ? currency : 'AED',
        };
      }
      if (enableHashtags && hashTags.length > 0) {
        for (let hashtag of hashTags) {
          if(hashtag?.tag?.length > 0 || hashtag?.length > 0) {
            hashtagObj = [
              ...hashtagObj,
              hashtag.tag?.length > 0 ? hashtag.tag.trim(): hashtag.trim()
            ];
          }
        }
        hashtagObj = hashtagObj.filter(onlyUnique)
        payload = {
          ...payload,
          hashTags: hashtagObj,
        };
      }
      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      const statusResponse = await putReq({
        url,
        payload,
        requestHeaders,
      });
      const { successCode, data } = statusResponse;
      if (successCode === 'SUCC200') {
        setProductName(name);
        if (enableHashtags) {
          setHashTagsArray(hashtagObj);
        }
        if (enablePrice) {
          if (price.length === 0) {
            setPriceState(0);
          } else {
            setPriceState(parseFloat(price));
          }
          setCurrencyState(currency);
        }
        handleBackBtn();
      }
    }
    return;
  };

  const checkHashtagType = (hashtag) => {
    if (hashtag.tag?.length >= 0) return hashtag.tag
    return hashtag
  }
  return (
    <div className="popups-wrapper">
      <Pulse duration="150">
        <div className="product-card-wrapper">
          <div className="top-wrapper">
            <button className="back-button" onClick={handleBackBtn}>
              <img src={BackImg}></img>
            </button>
            <div className="edit-btn-group">
              <button className="button-link ml-15" onClick={savePopUpEdit}>
                Save
              </button>
            </div>
          </div>
          <div className="current-screen-title">Edit Product Details</div>

          <div className="product-actions-container">
            <div className="product-actions-input">
              <Input
                type="text"
                label="PRODUCT NAME"
                labelStyle="uc mb-5"
                placeholder="iPhone 12 pro"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="optional-wrapper">
              {enablePrice ? (
                <>
                  <div className="price-container">
                    <div className="price-inpt-wrapper">
                      <Input
                        customWidth={'90%'}
                        type="text"
                        label="Price"
                        labelStyle="uc mb-5"
                        placeholder="1000"
                        value={price}
                        onChange={(e) => {
                          !isNaN(e.currentTarget.value) &&
                            setPrice(e.target.value);
                        }}
                        inputStyle="mt-2"
                        // info={priceError ? "CAN'T BE BLANK" : ''}
                      />
                      <div className="select-input">
                        <label className="d-ib fs-8 fw-900 uc">CURRENCY</label>
                        <select
                          name="currency"
                          id="currency"
                          value={currency}
                          onChange={(e) => setCurrency(e.target.value)}
                        >
                          {cc.codes().map((code) => (
                            <option key={code} value={code}>
                              {code}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <button
                      className="optional-btn"
                      onClick={() => {
                        setPriceState();
                        setEnablePrice(false);
                      }}
                    >
                      {' '}
                      <img src={minusIcon}></img>
                    </button>
                  </div>
                </>
              ) : (
                <div
                  className="optional-field"
                  onClick={() => setEnablePrice(true)}
                >
                  <button className="optional-btn">
                    <img src={addIconBlack}></img>
                  </button>
                  <div className="title">UPDATE PRICE</div>
                </div>
              )}
            </div>
            <div className="optional-wrapper">
              {enableHashtags ? (
                <div className="hashtags-container">
                  <div className="hashtags-title">
                    <div className="title">#Hashtags</div>
                    <button
                      className="optional-btn"
                      onClick={() => {
                        setEnableHashtags(false);
                      }}
                    >
                      <img src={minusIcon}></img>
                    </button>
                  </div>
                  <p>
                    Help your customers find and filter your content through
                    hashtags. You can add and edit this later.
                  </p>
                  <div className="hashtags-wrapper">
                    <div className="hashtags-title">ADD HASHTAGS</div>
                    <ReactDragListView {...dragPropsHashtags}>
                      <div
                        className={
                          hashTags.length > 2
                            ? 'hashtags-list add-v-scroll'
                            : 'hashtags-list'
                        }
                      >
                        {hashTags.map((hashtag, index) => (
                          <li key={index}>
                            <div className="hashtag-title-wrapper">
                              <button className="drag-handle-btn">
                                <img src={dragHandleBtn}></img>
                              </button>
                              <div className="hash-text">#</div>
                              <input
                                type="text"
                                className="title-input"
                                value={checkHashtagType(hashtag)}
                                onChange={(e) =>
                                  hashtagChangeHandler(index, e.target.value)
                                }
                              ></input>
                            </div>
                            <button
                              className="close-btn"
                              onClick={() => removeHashtag(index)}
                            >
                              <img src={CloseHashtagsBtn}></img>
                            </button>
                          </li>
                        ))}
                        {scrollHashTag && <AlwaysScrollToBottom />}
                      </div>
                    </ReactDragListView>

                    <div className="add-hashtag-wrapper" onClick={addHashtag}>
                      <button className="optional-btn">
                        <img src={addIconBlack}></img>
                      </button>
                      <div className="title">ADD HASHTAGS</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="optional-field"
                  onClick={() => setEnableHashtags(true)}
                >
                  <button className="optional-btn">
                    <img src={addIconBlack}></img>
                  </button>
                  <div className="title">ADD HASHTAGS</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Pulse>
    </div>
  );
};
export default PopUpEditProductHashTag;
