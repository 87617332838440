import React from 'react';
import './switch-atom.scss';

const Switch = ({ toggleVal, handleToggle }) => {
  return (
    <>
      <label className="switch">
        <input
          checked={toggleVal}
          onChange={handleToggle}
          className="switch-input"
          type="checkbox"
        ></input>
        <span
          className={
            toggleVal ? 'switch-slider checked' : 'switch-slider unchecked'
          }
        ></span>
      </label>
    </>
  );
};

export default Switch;
