import React, { useState, useContext, useEffect } from 'react';
import './popupcta.scss';
import BackImg from '../../assets/back.png';
import TextAtom from '../TextAtom';
import ButtonAtom from '../ButtonAtom';
import PhnInputAtom from '../PhnInputAtom';

import Pulse from 'react-reveal/Pulse';
import Input from '../Input';
import {
  isEmpty,
  validateCompany,
  validateEmail,
  getSessionStorage,
} from '../../utils';
import { DOMAIN } from '../../constants/base';
import { currentProductContext } from '../../context';

// Assets
import CTALink from '../../assets/cta-link.png';
import CTAEmail from '../../assets/cta-mail.png';
import CTAPhone from '../../assets/cta-phone.png';
import CTAAddToCart from '../../assets/cta-cart.png';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';

const PopUpCTA = ({ setOpenCTAPopUp, fetchActiveSpaces }) => {
   const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
   
  const [currentProduct, setCurrentProduct] = useContext(currentProductContext);

  // Input Handlers
  const [weblink, setWebLink] = useState(
    currentProduct.currentCTAValues.webLink
  );
  const [CTAphone, setCTAPhone] = useState({
    phone: currentProduct.currentCTAValues.phoneNumber
      ? currentProduct.currentCTAValues.phoneNumber.phone
      : '',
    countryCode: currentProduct.currentCTAValues.phoneNumber
      ? currentProduct.currentCTAValues.phoneNumber.countryCode
      : '',
    countryName: currentProduct.currentCTAValues.phoneNumber
      ? currentProduct.currentCTAValues.phoneNumber.countryName
      : '',
  });
  const [email, setEmail] = useState(currentProduct.currentCTAValues.email);
  const [addToCart, setAddToCart] = useState(
    currentProduct.currentCTAValues.cart
  );
  const [disableSave, setDisableSave] = useState(true);

  // Error States
  const [weblinkError, setWebLinkError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [addToCartError, setAddToCartError] = useState(false);

  // Current Selected CTA
  const [currentCTASelected, setCurrentCTASelected] = useState(
    currentProduct.currentCTAValues.primaryCta
      ? currentProduct.currentCTAValues.primaryCta.ctaKey
      : ''
  );

  // Blur Handler
  const webLinkBlurHandler = () => {
    if (isEmpty(weblink)) {
      setWebLinkError(false);
      setDisableSave(true);
      return;
    } else {
      const isValid = validateCompany(weblink);
      setWebLinkError(!isValid);
      setDisableSave(!isValid);
    }
  };

  const phoneBlurHandler = () => {
    if (CTAphone.phone) {
      if (isEmpty(CTAphone.phone.slice(CTAphone.countryCode.length - 1))) {
        setPhoneError(false);
        setDisableSave(true);
        return;
      } else {
        setPhoneError(
          isEmpty(CTAphone.phone.slice(CTAphone.countryCode.length - 1))
        );
        setDisableSave(false);
      }
    }
  };

  const emailBlurHandler = () => {
    if (isEmpty(email)) {
      setEmailError(false);
      setDisableSave(true);
      return;
    } else {
      const isValid = validateEmail(email);
      setEmailError(!isValid);
      setDisableSave(!isValid);
    }
  };

  const addToCartBlurHandler = () => {
    if (isEmpty(addToCart)) {
      setAddToCartError(false);
      setDisableSave(true);
      return;
    } else {
      const isValid = validateCompany(addToCart);
      setAddToCartError(!isValid);
      setDisableSave(!isValid);
    }
  };
  useEffect(() => {
    switch (currentCTASelected) {
      case 'webLink':
        webLinkBlurHandler();
        break;
      case 'phoneNumber':
        phoneBlurHandler();
        break;
      case 'email':
        emailBlurHandler();
        break;
      case 'cart':
        addToCartBlurHandler();
        break;
    }
  }, [email, CTAphone, weblink, addToCart, currentCTASelected]);

  const onCTASubmit = async () => {
    let payload = {};
    switch (currentCTASelected) {
      case 'webLink':
        payload = {
          webLink: weblink,
          email: '',
          phone: '',
          countryCode: '',
          countryName: '',
          cart: '',
        };
        break;
      case 'email':
        payload = {
          webLink: '',
          email: email,
          phone: '',
          countryCode: '',
          countryName: '',
          cart: '',
        };
        break;
      case 'phoneNumber':
        payload = {
          webLink: '',
          email: '',
          phone: CTAphone.phone
            ? CTAphone.phone.slice(CTAphone.countryCode.length - 1)
            : '',
          countryCode: CTAphone.countryCode,
          countryName: CTAphone.countryName,
          cart: '',
        };
        break;
      case 'cart': {
        payload = {
          webLink: '',
          email: '',
          phone: '',
          countryCode: '',
          countryName: '',
          cart: addToCart,
        };
        break;
      }
    }

    const url = `${DOMAIN}/v1/dashboard/product/${currentProduct.currentProductId}/cta`;
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: userToken.accessToken,
      },
      body: JSON.stringify(payload),
    };
    if (!disableSave) {
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(() => {
          fetchActiveSpaces();
          setOpenCTAPopUp(false);
        });
    }
  };

  return (
    <div className="popups-wrapper">
      <Pulse duration="150">
        <div className="form-card-wrapper">
          <div className="d-flex w-100p">
            <button
              className="back-button"
              onClick={() => setOpenCTAPopUp(false)}
            >
              <img src={BackImg} alt="back"></img>
            </button>
            <h1 className="popup-heading ml-10">CTA's</h1>
          </div>
          <div className="form-wrapper mt-30">
            <TextAtom
              textContent={
                currentProduct.currentProductName
                  ? currentProduct.currentProductName.length > 20
                    ? currentProduct.currentProductName.substring(0, 20) + '...'
                    : currentProduct.currentProductName
                  : "Enter CTA's"
              }
              fontFamily="Montserrat"
              fontWeight="700"
              fontSize="1rem"
              color="#3A3A3A"
            ></TextAtom>

            <div className="input-wrapper mt-20">
              <div className="d-flex space-between center-align">
                <img
                  className={
                    currentCTASelected === 'webLink'
                      ? 'cta-selected mr-20'
                      : 'cta-not-selected mr-20'
                  }
                  src={CTALink}
                  alt="cta icon"
                  onClick={() => setCurrentCTASelected('webLink')}
                ></img>
                <div onClick={() => setCurrentCTASelected('webLink')}>
                  <Input
                    type="text"
                    label="Web Link"
                    labelStyle="uc mb-5"
                    placeholder="www.apple.com"
                    value={weblink}
                    onChange={(e) => {
                      setWebLink(e.target.value);
                    }}
                    info={weblinkError ? 'INVALID LINK' : ''}
                    onClick={() => setCurrentCTASelected('webLink')}
                    disabled={currentCTASelected === 'webLink' ? false : true}
                  ></Input>
                </div>
              </div>
              <div className="d-flex space-between center-align">
                <img
                  className={
                    currentCTASelected === 'phoneNumber'
                      ? 'cta-selected mr-20'
                      : 'cta-not-selected mr-20'
                  }
                  src={CTAPhone}
                  alt="phone"
                  onClick={(e) => setCurrentCTASelected('phoneNumber')}
                ></img>
                <div onClick={() => setCurrentCTASelected('phoneNumber')}>
                  <PhnInputAtom
                    phone={CTAphone}
                    customMargin={true}
                    customWidth="75%"
                    setPhone={setCTAPhone}
                    onBlur={phoneBlurHandler}
                    info={phoneError ? 'BLANK NO' : ''}
                    onClick={() => setCurrentCTASelected('phoneNumber')}
                    disabled={
                      currentCTASelected === 'phoneNumber' ? false : true
                    }
                  ></PhnInputAtom>
                </div>
              </div>
              <div className="d-flex space-between center-align">
                <img
                  className={
                    currentCTASelected === 'email'
                      ? 'cta-selected mr-20'
                      : 'cta-not-selected mr-20'
                  }
                  src={CTAEmail}
                  alt="email icon"
                  onClick={(e) => setCurrentCTASelected('email')}
                ></img>
                <div onClick={() => setCurrentCTASelected('email')}>
                  <Input
                    type="text"
                    label="Email Address"
                    labelStyle="uc mb-5"
                    placeholder="demo@email.com"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    info={emailError ? 'Invalid Email' : ''}
                    onClick={() => setCurrentCTASelected('email')}
                    disabled={currentCTASelected === 'email' ? false : true}
                  ></Input>
                </div>
              </div>
              <div className="d-flex space-between center-align">
                <img
                  className={
                    currentCTASelected === 'cart'
                      ? 'cta-selected mr-20'
                      : 'cta-not-selected mr-20'
                  }
                  src={CTAAddToCart}
                  onClick={(e) => setCurrentCTASelected('cart')}
                ></img>
                <div onClick={() => setCurrentCTASelected('cart')}>
                  <Input
                    type="text"
                    label="Add To Cart Link"
                    labelStyle="uc mb-5"
                    placeholder="www.holofy.io/cart"
                    value={addToCart}
                    onChange={(e) => {
                      setAddToCart(e.target.value);
                    }}
                    info={addToCartError ? 'INVALID LINK' : ''}
                    onClick={() => setCurrentCTASelected('cart')}
                    disabled={currentCTASelected === 'cart' ? false : true}
                  ></Input>
                </div>
              </div>

              <div className="mt-20">
                <ButtonAtom
                  label="SAVE"
                  onClick={onCTASubmit}
                  disabled={disableSave}
                ></ButtonAtom>
              </div>
            </div>
          </div>
        </div>
      </Pulse>
    </div>
  );
};

export default PopUpCTA;
