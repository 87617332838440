import React, { useReducer } from 'react';
import TopBar from '../../components/TopBar';
import MyProductsComponent from '../../containers/MyProducts';
// State Management
import { currentProductContext, UploadContext } from '../../context/';
import productReducer from '../../context/productReducers';
import uploadReducer from '../../context/uploadReducer';

function MyProducts() {
  const initialState = {
    currentMagicLink: '',
    currentProductName: '',
    currentProductId: '',
    currentHashtagValues: [],
    currentCTAValues: [],
    currentSkillValues: [],
    currentBrandName: '',
    currentCurrency: '',
    currentPrice: '',
  };

  const intialUploadState = [];

  const [state, dispatch] = useReducer(productReducer, initialState);
  const [uploadState, uploadDispatch] = useReducer(
    uploadReducer,
    intialUploadState
  );

  return (
    <currentProductContext.Provider value={[state, dispatch]}>
      <UploadContext.Provider value={[uploadState, uploadDispatch]}>
        <TopBar></TopBar>
        <MyProductsComponent />
      </UploadContext.Provider>
    </currentProductContext.Provider>
  );
}

export default MyProducts;
