import React, { useState, useEffect, useRef } from 'react';
import './productscard.scss';
import { Fade } from 'react-reveal';
import getSymbolFromCurrency from 'currency-symbol-map';

// Assets
import CTALink from '../../assets/link-svg.svg';
import CTALinkOff from '../../assets/shape-off.svg';
import VideoPlaceholder from '../../assets/video-placeholder.png';
import Checked from '../../assets/Vector 316checked.svg';

const ProductsCard = (props) => {
  const {
    property: {
      name = '',
      status,
      widgetLink: url,
      skills = [],
      productURL,
      cta,
      id,
      currency,
      brand,
      price,
      productMagicLinks,
      hashTags,
    },
    editMode,
    selectCardMethod,
    selectedCards,
    setOpenEditProductPopUp,
    selectedProduct,
    property,
  } = props;

  const [toggleVal, setToggleVal] = useState(status);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    checkedCards();
  }, [selectedCards.length]);

  useEffect(() => {
    checkOnlineStatus()
  }, [productMagicLinks.length])

  // render videos cards
  const handleBrokenImage = (e) => {
    e.target.src = VideoPlaceholder;
  };

  const checkedCards = () => {
    if (selectedCards.find((i) => i === id)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  };

  const toggleEditProducts = () => {
    setOpenEditProductPopUp(true);
    selectedProduct(property);
  };

  const handleSelect = () => {
    if (editMode) {
      setIsSelected(!isSelected);
      if (isSelected) {
        selectCardMethod(id, false);
      } else if (!isSelected) {
        selectCardMethod(id, true);
      }
    } else if (!editMode) {
      toggleEditProducts();
    }
    return;
  };

  // Check online status of each card *temp implementation Need backend fix*
  const checkOnlineStatus = () => {
    if (productMagicLinks.length) {
      if (productMagicLinks.find((i) => i.status === true)) {
        setToggleVal(true)
      } else {
        setToggleVal(false)
      }
    } else {
      setToggleVal(false)
    }
  }

  const renderVideoCards = () => {
    if (skills.length === 0) {
      return (
        <div className="prod-container-videos">
          No Videos
        </div>
      );
    }
    if (skills.length === 1) {
      return (
        <div className="prod-container-videos">
          <div
            className="prod-container-videos-img center-fix"
          >
            <img
              className="videos-img-rot-single"
              onError={handleBrokenImage}
              src={skills[0]?.poster || VideoPlaceholder}
            />
          </div>
        </div>
      );
    } else if (skills.length === 2) {
      return (
        <div className="prod-container-videos ml-10">
          <div
            className="prod-container-videos-img center-fix"
          >
            <img
              className="videos-img-rot-double-1"
              onError={handleBrokenImage}
              src={skills[0]?.poster || VideoPlaceholder}
            />
          </div>
          <div className="prod-container-videos-img center-fix">
            <img
              className="videos-img-rot-double-2"
              onError={handleBrokenImage}
              src={skills[1]?.poster || VideoPlaceholder}
            />
          </div>
        </div>
      );
    } else if (skills.length === 3) {
      return (
        <div className="prod-container-videos ml-10">
          <div
            className="prod-container-videos-img center-fix"
          >
            <img
              className="videos-img-rot-third-1"
              onError={handleBrokenImage}
              src={skills[0]?.poster || VideoPlaceholder}
            />
          </div>
          <div className="prod-container-videos-img center-fix" />
          <div className="prod-container-videos-img center-fix">
            <img
              className="videos-img-rot-third-2"
              onError={handleBrokenImage}
              src={skills[1]?.poster || VideoPlaceholder}
            />
          </div>
          <div className="prod-container-videos-img center-fix">
            <img
              className="videos-img-rot-third-3"
              onError={handleBrokenImage}
              src={skills[2]?.poster || VideoPlaceholder}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="prod-container-videos ml-10">
          <div
            className="prod-container-videos-img center-fix"
          >
            <img
              className="videos-img-rot1"
              onError={handleBrokenImage}
              src={skills[0]?.poster || VideoPlaceholder}
            />
          </div>
          <div className="prod-container-videos-img center-fix">
            <img
              className="videos-img-rot2"
              onError={handleBrokenImage}
              src={skills[1]?.poster || VideoPlaceholder}
            />
          </div>
          <div className="prod-container-videos-img center-fix">
            <img
              className="videos-img-rot3"
              onError={handleBrokenImage}
              src={skills[2]?.poster || VideoPlaceholder}
            />
          </div>
          <div className="prod-container-videos-img center-fix">
            <img
              className="videos-img-rot4"
              onError={handleBrokenImage}
              src={skills[3]?.poster || VideoPlaceholder}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <Fade duration={150}>
      <div
        className="prod-container"
        onClick={handleSelect}
        style={{
          borderColor: isSelected ? '#0000FF' : '#fff',
        }}
      >
        <div className="d-flex prod-container-header w-100p">
          <div className="prod-container-header-vid">
            {editMode ? (
              <div className="products-custom-checkbox">
                {isSelected && (
                  <div>
                    <img src={Checked} alt="Checked" />
                  </div>
                )}
              </div>
            ) : (
              <span>{`${skills.length} ${
                skills.length === 1 ? 'video' : 'videos'
              }`}</span>
            )}
          </div>
          <div className="prod-container-header-cta">
            <img
              style={{ opacity: toggleVal ? 1 : 0.2 }}
              src={toggleVal ? CTALink : CTALinkOff}
            />
          </div>
        </div>
        {renderVideoCards()}
        <div className="prod-container-desc">
          <h4>
            {price !== 0 && price !== undefined && (
              <>
                <span>{getSymbolFromCurrency(currency)}</span> 
                {price?.toLocaleString()}
              </>
            )}
          </h4>
          <h6>{name.length > 52? name.substring(0,50) + '...': name}</h6>
        </div>
      </div>
    </Fade>
  );
};

export default ProductsCard;
