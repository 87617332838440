import React from 'react';
import SignupComponent from '../../containers/Signup';

function Signup(props) {
  return (
    <>
      <SignupComponent />
    </>
  );
}

export default Signup;
