import React, { useState } from 'react';
import './mobile-input.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Fade } from 'react-reveal';

const PhnInputAtom = ({
  phone,
  setPhone,
  info,
  onBlur,
  customWidth,
  customMargin,
  disabled,
}) => {
  const phoneNumberHandler = (value, country) => {
    setInitialPhoneVal('');
    setPhone({
      phone: value,
      countryCode: `+${country.dialCode}`,
      countryName: country.countryCode.toLowerCase(),
    });
  };
  const [initalPhoneVal, setInitialPhoneVal] = useState(
    phone.phone ? phone.countryCode + phone.phone : ''
  );

  return (
    <div className="mb-20">
      <div
        className="d-flex space-between"
        style={{ width: customWidth ? customWidth : '100%' }}
      >
        <label className="d-ib fs-8 fw-900 mb-5">PHONE NUMBER</label>
        {info && (
          <Fade bottom duration="150">
            <label className={'d-ib fs-8 fw-900 c-error mb-5'}>{info}</label>
          </Fade>
        )}
      </div>
      <PhoneInput
        inputStyle={{
          width: customWidth ? customWidth : '80%',
          border: '0px',
        }}
        country={'gb'}
        value={
          initalPhoneVal.phone !== ''
            ? initalPhoneVal.toString()
            : phone.phone.toString()
        }
        inputProps={{
          name: 'phone',
          required: true,
          autoFocus: false,
        }}
        onBlur={onBlur}
        onChange={phoneNumberHandler}
        containerClass="mobile-input-container"
        inputClass={
          customMargin
            ? 'mobile-input-main margin-25p'
            : 'mobile-input-main margin-20p'
        }
        buttonClass={
          disabled ? 'mobile-input-btn-disabled' : 'mobile-input-btn'
        }
        dropdownClass="mobile-input-dropdown"
        disabled={disabled}
      />
    </div>
  );
};

export default PhnInputAtom;
