import React from 'react';
import HolofyProductLogo from '../../assets/holofy-prodyct-logo.png';
import './product-logo.scss';

const ProductAtom = () => {
  return (
    <div className="product-logo">
      <img src={HolofyProductLogo} alt="Holofy Product" />
    </div>
  );
};

export default ProductAtom;
