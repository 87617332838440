import React from 'react';
import './statoverview.scss';


export default function StatOverview({icon, title, msg, type}) {
  return (
    <div className="stat-overview">
      <div className={type==='video' ? "icon-wrapper round": "icon-wrapper"}>
        <img src={icon} />
      </div>
      <div className="title">{title}</div>
      <div className="msg">{msg}</div>
    </div>
  )
}
