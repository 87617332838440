import FeatureIcon from '../../assets/feature-promote.png';
import FeatureTwo from '../../assets/feature-cross.png';
import FeatureThree from '../../assets/feature-analyse.png';
import FeatureFour from '../../assets/feature-scale.png';

export const Features = [
  {
    id: 1,
    heading: 'Promote',
    title: 'Embed brand content on your webiste',
    subTitle: 'Compatible with 99% of website platforms or custom-built websites, Holofy Products embeds on your existing pages and syncs content contextually.',
    img: FeatureIcon,
    className: 'one',
  },
  {
    id: 2,
    heading: 'Cross Sell',
    title: 'Enable a new shopping experience',
    subTitle: 'The cross-selling experience makes each product video an entry point to your entire inventory, dramatically increasing discoverability.',
    img: FeatureTwo,
    className: 'two',
  },
  {
    id: 3,
    heading: 'Analyse',
    title: 'Measure Performance ',
    subTitle: 'Our cookie-less analytics extract cohort or unique visitor metrics to measure content, product, or inventory performance 24/7.',
    img: FeatureThree,
    className: 'three',
  },
  {
    id: 4,
    heading: 'Scale',
    title: 'Collaborate with your team',
    subTitle: 'The collaborative CMS makes managing thousands of products, with thousands of videos across hundreds of pages a breeze.',
    img: FeatureFour,
    className: 'four',
  },
]