import React, { useEffect, useState, useContext } from 'react';
import './statspopup.scss';
import Pulse from 'react-reveal/Pulse';
import { getReq } from '../../utils/api';
import { getSessionStorage, toolTipDateFormat } from '../../utils';
import { DOMAIN } from '../../constants/base';
import { StatsContext } from '../../context';
import AreaChartComponent from '../../components/AreaChart';
import BarChartComponent from '../../components/BarChart';
// React Date Range

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';

// Assets
import BackBtn from '../../assets/back.png';
import EyeIcon from '../../assets/eye-icon.png';
import StatsCalendar from '../../assets/stats-calendar.png';
import RetentionIcon from '../../assets/retention-stat.svg';
import StopWatch from '../../assets/stopwatch.svg';

const StatsPopUpGraph = ({ onBack }) => {
  const [statsValue] = useContext(StatsContext);
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};

  const [graphData, setGraphData] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });
  // prevent user to select date beyond creation of product
  const [minimumDate, setMinimumDate] = useState();
  const [disbaleFilter, setDisableFilter] = useState(false);
  const [applyFilter, setApplyFilter] = useState(true);

  const [emptyGraph, setEmptyGraph] = useState(false);
  const [trafficData, setTrafficData] = useState([]);
  let graphType = '';

  useEffect(() => {
    console.log('fetched data');
    if (graphData.trafficRes) {
      let sortedData = graphData.trafficRes.sort((s1, s2) =>
        s1.value > s2.value ? -1 : 1
      );
      setTrafficData(sortedData);
    }
  }, [graphData]);

  useEffect(() => {
    if (range.startDate < minimumDate) {
      setDisableFilter(true);
    } else {
      setDisableFilter(false);
    }
  }, [range]);

  // Title
  const generateTitle = () => {
    switch (statsValue.currentGraphType) {
      case 'overall-impressions':
        graphType = 'overall';
        return 'Overall Impressions';
      case 'overall-retention-rate':
        graphType = 'overall';
        return 'Avg. Retention Rates';
      case 'cta-action-rates':
        graphType = 'overall';
        return 'Overall CTA Action Rate';
      case 'top-traffic-sources':
        graphType = 'top-traffic-sources';
        return 'Traffic Source';
      case 'video-performance':
        graphType = 'skill';
        return 'Video Performance';
      case 'product-retention-rate':
        graphType = 'product-retention-rate';
        return 'Avg. Retention Rate';
      case 'traffic-source':
        graphType = 'traffic-source';
        return 'Traffic Sources';
    }
  };

  // Generating Value
  const generateValue = () => {
    switch (statsValue.currentGraphType) {
      case 'overall-impressions':
        return graphData?.totalViews
          ? Number(graphData?.totalViews).toLocaleString()
          : 0;
      case 'overall-retention-rate':
        return graphData.retentionRate >= 0 ? graphData.retentionRate : '';
      case 'cta-action-rates':
        return graphData.avgConversion >= 0 ? graphData.avgConversion : '';
      case 'product-retention-rate':
        return graphData.retentionRate >= 0 ? graphData.retentionRate : '';
    }
  };

  // Tagline
  const generateTagline = () => {
    if (graphData.overview) {
      switch (statsValue.currentGraphType) {
        case 'overall-impressions':
          return 'Impressions';
        case 'overall-retention-rate':
          return 'Avg. Retention Rate';
        case 'cta-action-rates':
          return 'CTA Action Rate';
        case 'product-retention-rate':
          return 'Avg. Retention Rate';
      }
    }
  };

  // Date Range
  const handleDateSelect = (ranges) => {
    setRange(ranges.selection);
  };
  const selectionRange = {
    startDate: range.startDate,
    endDate: range.endDate,
    key: 'selection',
  };

  function formatDate(date, inString) {
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var year = date.getFullYear();
    if (inString) {
      switch (month) {
        case '1':
          return 'JAN ' + day + ', ' + year;
        case '2':
          return 'FEb ' + day + ', ' + year;
        case '3':
          return 'MAR ' + day + ', ' + year;
        case '4':
          return 'APR ' + day + ', ' + year;
        case '5':
          return 'MAY ' + day + ', ' + year;
        case '6':
          return 'JUN ' + day + ', ' + year;
        case '7':
          return 'JUL ' + day + ', ' + year;
        case '8':
          return 'AUG ' + day + ', ' + year;
        case '9':
          return 'SEP ' + day + ', ' + year;
        case '10':
          return 'OCT ' + day + ', ' + year;
        case '11':
          return 'NOV ' + day + ', ' + year;
        case '12':
          return 'DEC ' + day + ', ' + year;
      }
    } else {
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    }
  }

  // Graph Custom
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="custom-tooltip-date">
            {toolTipDateFormat(payload[0].payload.date)}
          </div>
          {statsValue.currentGraphType === 'overall-impressions' && (
            <h1>Impressions</h1>
          )}
          {statsValue.currentGraphType === 'overall-retention-rate' && (
            <h1>Avg. Retention Rate</h1>
          )}
          {statsValue.currentGraphType === 'cta-action-rates' && (
            <h1>CTA Action Rate</h1>
          )}
          {statsValue.currentGraphType === 'product-retention-rate' && (
            <h1>Avg. Retention Rates</h1>
          )}
          {statsValue.currentGraphType === 'product-retention-rate' ? (
            <p className="label">{payload[0].payload.count}%</p>
          ) : (
            <p className="label">{payload[0].payload.count}</p>
          )}
        </div>
      );
    }

    return null;
  };

  function formatXAxis(tickItem) {
    if (typeof tickItem === 'string') {
      return tickItem.slice(8);
    }
  }

  const CustomXAxis = (props) => {
    console.log(props);
    const { x, y, width, height } = props.viewBox;
    console.log(x);
    return (
      <g marginTop="5px">
        <text
          x={x + width}
          y={350}
          dy={16}
          fontSize="10px"
          fontWeight="600"
          color="rgba(0,0,0,0.5)"
          lineHeight="14px"
          position="insideBottomRight"
          textAnchor="end"
        >
          (Date)
        </text>
      </g>
    );
  };
  async function getGraphData(startDate, endDate, currentGraphType) {
    setShowSpinner(true);
    let url = `${DOMAIN}/v1/dashboard/stats/v3/`;
    if (currentGraphType === 'traffic-source') {
      url =
        url +
        `${currentGraphType}/${
          statsValue?.activeProduct?.productId
        }?startDate=${formatDate(startDate)}&endDate=${formatDate(
          endDate
        )}T23:59:59`;
    } else if (currentGraphType === 'product-retention-rate') {
      currentGraphType = 'retention-rate';
      url =
        url +
        `${currentGraphType}/${
          statsValue?.activeProduct?.productId
        }?startDate=${formatDate(startDate)}&endDate=${formatDate(
          endDate
        )}T23:59:59`;
    } else if (currentGraphType === 'top-traffic-sources') {
      url =
        url +
        `product/${currentGraphType}?startDate=${formatDate(
          startDate
        )}&endDate=${formatDate(endDate)}T23:59:59`;
    } else {
      url =
        url +
        `product/${currentGraphType}?startDate=${formatDate(
          startDate
        )}&endDate=${formatDate(endDate)}T23:59:59`;
    }
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const activeSpacesResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = activeSpacesResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setGraphData(data);
      if (data.length < 1) {
        setEmptyGraph(true);
      } else {
        setEmptyGraph(false);
      }
      setShowSpinner(false);
    }
  }

  useEffect(() => {
    if (statsValue.currentGraphType === 'overall-impressions') {
      setRange({
        startDate: new Date(statsValue.overallImpressions.startDate),
        endDate: new Date(statsValue.overallImpressions.endDate),
      });
      setMinimumDate(
        new Date(
          new Date(statsValue.overallImpressions.accountCreated) - 86400000
        )
      );
      getGraphData(
        new Date(statsValue.overallImpressions.startDate),
        new Date(statsValue.overallImpressions.endDate),
        statsValue.currentGraphType
      );
    } else if (statsValue.currentGraphType === 'overall-retention-rate') {
      setRange({
        startDate: new Date(statsValue.overallRetention.startDate),
        endDate: new Date(statsValue.overallRetention.endDate),
      });
      setMinimumDate(
        new Date(
          new Date(statsValue.overallRetention.accountCreated) - 86400000
        )
      );
      getGraphData(
        new Date(statsValue.overallRetention.startDate),
        new Date(statsValue.overallRetention.endDate),
        statsValue.currentGraphType
      );
    } else if (statsValue.currentGraphType === 'cta-action-rates') {
      setRange({
        startDate: new Date(statsValue.overallCTA.startDate),
        endDate: new Date(statsValue.overallCTA.endDate),
      });
      // setMinimumDate(new Date(statsValue.overallRetention.accountCreated));
      setMinimumDate(
        new Date(
          new Date(statsValue.overallRetention.accountCreated) - 86400000
        )
      );
      getGraphData(
        new Date(statsValue.overallCTA.startDate),
        new Date(statsValue.overallCTA.endDate),
        statsValue.currentGraphType
      );
    } else if (statsValue.currentGraphType === 'product-retention-rate') {
      setRange({
        startDate: new Date(statsValue.productRetention.startDate),
        endDate: new Date(statsValue.productRetention.endDate),
      });
      setMinimumDate(
        new Date(new Date(statsValue.activeProduct.createdDate) - 86400000)
      );
      getGraphData(
        new Date(statsValue.productRetention.startDate),
        new Date(statsValue.productRetention.endDate),
        statsValue.currentGraphType
      );
    } else if (statsValue.currentGraphType === 'traffic-source') {
      setRange({
        startDate: new Date(statsValue.productTrafficSources.startDate),
        endDate: new Date(statsValue.productTrafficSources.endDate),
      });
      setMinimumDate(
        new Date(new Date(statsValue.activeProduct.createdDate) - 86400000)
      );
      getGraphData(
        new Date(statsValue.productTrafficSources.startDate),
        new Date(statsValue.productTrafficSources.endDate),
        statsValue.currentGraphType
      );
    } else if (statsValue.currentGraphType === 'top-traffic-sources') {
      setRange({
        startDate: new Date(statsValue.topTrafficSources.startDate),
        endDate: new Date(statsValue.topTrafficSources.endDate),
      });
      setMinimumDate(
        new Date(
          new Date(statsValue.topTrafficSources.accountCreated) - 86400000
        )
      );
      getGraphData(
        new Date(statsValue.topTrafficSources.startDate),
        new Date(statsValue.topTrafficSources.endDate),
        statsValue.currentGraphType
      );
    }
  }, []);

  const handleApplyFilter = () => {
    getGraphData(range.startDate, range.endDate, statsValue.currentGraphType);
    console.log(typeof range.endDate);
    setShowCalendar(false);
  };

  return (
    <div className="statspopup-container">
      {showSpinner && (
        <div
          className="pos-abs z-2"
          style={{
            marginTop:
              statsValue.currentGraphType === 'product-retention-rate'
                ? '50px'
                : '70px',
          }}
        >
          {statsValue.currentGraphType === 'traffic-source' ||
          statsValue.currentGraphType === 'top-traffic-sources' ? (
            <div className="loading-wrapper-graph">
              <div className="shimmerBG loading-traffic-source-wrapper"></div>
            </div>
          ) : statsValue.currentGraphType === 'product-retention-rate' ? (
            <div className="loading-wrapper-graph-videoperformance">
              <div className="loading-top-wrapper-videoperformance one-videoperformance">
                <div className="shimmerBG loading-square"></div>
                <div className="loading-right">
                  <div className="shimmerBG loading-right-top"></div>
                  <div className="shimmerBG loading-right-bottom"></div>
                </div>
              </div>
              <div className="loading-videoperformance-border"></div>
              <div className="loading-top-wrapper-videoperformance two-videoperformance">
                <div className="shimmerBG loading-circle"></div>
                <div className="loading-right">
                  <div className="shimmerBG loading-right-top"></div>
                  <div className="shimmerBG loading-right-bottom"></div>
                </div>
              </div>
              <div className="shimmerBG loading-bottom-wrapper-videoperformance"></div>
            </div>
          ) : (
            <div className="loading-wrapper-graph">
              <div className="loading-top-wrapper">
                <div className="shimmerBG loading-circle"></div>
                <div className="loading-right">
                  <div className="shimmerBG loading-right-top"></div>
                  <div className="shimmerBG loading-right-bottom"></div>
                </div>
              </div>
              <div className="shimmerBG loading-bottom-wrapper"></div>
            </div>
          )}
        </div>
      )}
      <Pulse duration={150}>
        <div className="stats-popup">
          {showCalendar && (
            <div className="stats-calendar-popup">
              <DateRangePicker
                maxDate={new Date()}
                minDate={minimumDate}
                disabledDates={[new Date(minimumDate)]}
                ranges={[selectionRange]}
                onChange={handleDateSelect}
                className="stats-calendar"
                rangeColors={['black']}
                color="black"
              />
              <div className="stats-calendar-apply">
                <div className="stats-calendar-apply-btn-wrapper">
                  <button
                    onClick={() => setShowCalendar(false)}
                    className="stats-calendar-apply-btn"
                  >
                    CLOSE
                  </button>
                  <button
                    onClick={handleApplyFilter}
                    className="stats-calendar-apply-btn-fill"
                    disabled={disbaleFilter ? true : false}
                  >
                    APPLY FILTER
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="stats-popup-header">
            <div className="stats-popup-title">
              <button onClick={onBack}>
                <img src={BackBtn}></img>
              </button>
              <h1>{generateTitle()}</h1>
            </div>
            {graphType === 'skill' ? (
              ''
            ) : (
              <button
                onClick={() => setShowCalendar(!showCalendar)}
                className="stats-popup-calendar-btn"
              >
                <img src={StatsCalendar}></img>
                {range.startDate &&
                  range.endDate &&
                  `${formatDate(range.startDate, true)} - ${formatDate(
                    range.endDate,
                    true
                  )}`}
              </button>
            )}
          </div>
          {/* <div className="stats-popup-last-updated">
            Last Updated: {
              statsValue.currentGraphType === 'overall-impressions' ? statsValue?.lastUpdated?.overAllProductImpressions :
              (statsValue.currentGraphType === 'overall-retention-rate') ? statsValue?.lastUpdated?.overAllRetentionRate  : 
              (statsValue.currentGraphType === 'cta-action-rates') ? statsValue?.lastUpdated?.overAllCtaActionRate :
              statsValue?.lastUpdated?.overAllCtaActionRate
            }
          </div> */}
          {graphType === 'skill' ? (
            <>
              <div className="skill-stat-wrapper">
                {!showSpinner && (
                  <div className="skill-stat-wrapper-content-main">
                    <div className="stats-details">
                      <div className="skill-poster">
                        <img src={statsValue?.skillStats?.poster} />
                      </div>
                      <div className="skill-stats">
                        {statsValue?.skillStats?.name.length > 0 ? (
                          <div className="skill-name">
                            {statsValue?.skillStats?.name}
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="skill-counts">
                          <img src={EyeIcon} /> {statsValue?.skillStats?.views}
                        </div>
                      </div>
                      <div className="sep"></div>
                    </div>

                    <div className="stats-details">
                      <div className="skill-stat-icon">
                        <img src={RetentionIcon} />
                      </div>
                      <div className="skill-stats">
                        <div className="stat-value">
                          {statsValue?.skillStats?.engagement}
                          <span className="perc">%</span>
                        </div>
                        <div className="stat-name">Engagement</div>
                      </div>
                      <div className="sep"></div>
                    </div>

                    <div className="stats-details">
                      <div className="skill-stat-icon">
                        <img src={StopWatch} />
                      </div>
                      <div className="skill-stats">
                        <div className="stat-value">
                          {statsValue?.skillStats?.avgWatchTime}
                          <span className="perc">sec</span>
                        </div>
                        <div className="stat-name">Avg. Watch Time</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {!showSpinner && (
                <div
                  className="stats-popup-content-wrapper"
                  style={{ display: 'block', marginTop: '60px' }}
                >
                  <div
                    className="skill-stats-graph-wrapper"
                    style={{
                      display: 'block',
                      borderTop: 'none',
                      marginTop: '0px',
                      paddingTop: '0px',
                    }}
                  >
                    <h1>Video Timeline Engagement</h1>
                    <div className="graph-wrapper">
                      <AreaChartComponent
                        graph={statsValue?.skillStats?.graphPlot}
                      ></AreaChartComponent>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {graphType === 'product-retention-rate' ||
              graphType === 'traffic-source'
                ? !showSpinner && (
                    <>
                      <div className="product-details">
                        <div className="product-img">
                          <img src={statsValue?.activeProduct?.poster} />
                        </div>
                        <div className="product-name">
                          <div className="title">product name</div>
                          <div className="value">
                            {statsValue?.activeProduct?.productName}
                          </div>
                        </div>
                      </div>
                      {/* <div className="hr-break"></div> */}
                    </>
                  )
                : ''}

              {graphType === 'traffic-source' ||
              graphType === 'top-traffic-sources'
                ? ''
                : !showSpinner && (
                    <div
                      className="stats-popup-content-wrapper"
                      style={{
                        marginTop:
                          graphType === 'product-retention-rate'
                            ? '24px'
                            : '50px',
                      }}
                    >
                      <div className="stats-popup-content-main">
                        <div className="icon-wrapper">
                          {graphType === 'product-retention-rate' ? (
                            <img src={RetentionIcon} />
                          ) : (
                            <img src={EyeIcon}></img>
                          )}
                        </div>
                        <div className="stats-popup-content">
                          <h1>
                            {generateValue()}
                            {statsValue.currentGraphType !==
                              'overall-impressions' && <span>%</span>}
                          </h1>
                          <h2>{generateTagline()}</h2>
                        </div>
                      </div>
                      {graphData.overview && (
                        <div className="stats-popup-content-tagline">
                          <div className="circle"></div>
                          <h2>{generateTagline()}</h2>
                        </div>
                      )}
                    </div>
                  )}
              {graphType === 'traffic-source' ||
              graphType === 'top-traffic-sources' ? (
                <div className="traffic-source-wrapper">
                  <BarChartComponent graph={trafficData}></BarChartComponent>
                </div>
              ) : (
                <div
                  className={
                    graphType === 'product-retention-rate'
                      ? 'stats-popup-graph-wrapper product'
                      : 'stats-popup-graph-wrapper'
                  }
                  style={{
                    height:
                      graphType === 'product-retention-rate' ? '50%' : '60%',
                  }}
                >
                  {showSpinner !== true && (
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        width={500}
                        height={300}
                        data={graphData.overview}
                      >
                        <CartesianGrid strokeDasharray="0" vertical={false} />
                        <XAxis
                          dataKey="date"
                          strokeWidth={0}
                          tick={{ fontSize: 10, fontWeight: '600' }}
                          tickFormatter={formatXAxis}
                          allowDataOverflow={true}
                          interval="preserveStartEnd"
                          // label={{
                          //   value: '(Date)',
                          //   fontSize: '10px',
                          //   fontWeight: '600',
                          //   lineHeight: '14px',
                          //   position: 'insideBottomRight',
                          //   marginTop: '2px',
                          //   fill:'#00000080'
                          //   // offset: '20px'
                          // }}
                          // label={<CustomXAxis/>}
                        ></XAxis>
                        <YAxis
                          strokeWidth={0}
                          tick={{ fontSize: 10, fontWeight: '600' }}
                        />
                        <Tooltip
                          cursor={{ fill: 'transparent' }}
                          offset={0}
                          animationEasing={'ease-in-out'}
                          content={<CustomTooltip />}
                        />
                        <Line
                          type="monotone"
                          dataKey="count"
                          stroke="#000"
                          activeDot={{ r: 4 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  )}

                  <div className="date-legend">(Date)</div>
                </div>
              )}
            </>
          )}
        </div>
      </Pulse>
    </div>
  );
};

export default StatsPopUpGraph;
