import React, { useReducer } from 'react';
import TopBar from '../../components/TopBar';
import MySettings from '../../containers/MySettings';

import memberReducer from '../../context/memberReducer';
import { EditMemberContext } from '../../context';

function Settings() {
  const initialStateMember = {
    currentEditMemberId: '',
    currentEditMemberAvatar: '',
    currentEditMemberName: '',
    currentEditMemberEmail: '',
    currentEditMemberAdmin: '',
    currentEditMemberPhone: {
      phone: '',
      countryCode: '',
      countryName: '',
    },
    currentEditMemberVerified: null,
    currentEditMemberInvitee: null,
  };

  const [memberState, memberDispatch] = useReducer(
    memberReducer,
    initialStateMember
  );

  return (
    <EditMemberContext.Provider value={[memberState, memberDispatch]}>
      <TopBar></TopBar>
      <MySettings />
    </EditMemberContext.Provider>
  );
}

export default Settings;
