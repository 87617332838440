import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { isEmpty } from '../../utils';

import './input.scss';

function Search(props) {
  const {
    type,
    inputStyle,
    label,
    labelStyle,
    placeholder,
    value,
    onChange,
    onBlur,
    onKeyDown,
    info,
    infoStyle,
    isValid = true,
    smallSearchBar,
    handleEnter = () => {},
    prependImg,
    appendImg,
    setCurrentPage,
    setCurrentSearchTerm,
  } = props;

  const [searchText, setSearchText] = useState('');
  const [showAppendImg, setShowAppendImg] = useState(appendImg ? true : false);
  const [calcTextWidth, setCalcTextWidth] = useState('');
  const [closeAlignBool, setCloseAlignBool] = useState(false);

  useEffect(() => {
    if (!isEmpty(searchText)) {
      setShowAppendImg(true);
    } else {
      setShowAppendImg(false);
    }
    onChange(searchText);
  }, [searchText]);

  const labelClass = classnames('d-ib c-gray fs-12 fw-800 ', labelStyle);
  const infoClass = classnames('d-ib c-gray fs-12 fw-800', infoStyle);

  const onEnterKey = (e) => {
    if (e.keyCode === 13) {
      handleEnter();
    }
  };

  const onChangeHandler = (e) => {
    const value = e.target.value;
    setSearchText(value);
    getTextWidth(value);
  };
  const clearSearch = () => {
    setCurrentPage(0);
    setSearchText('');
  };

  //search with follow up icon
  const getTextWidth = (text) => {
    let canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement('canvas'));
    let context = canvas.getContext('2d');
    context.font = '68px montserrat';
    let metrics = context.measureText(text);
    if (metrics.width > 800) {
      setCloseAlignBool(true);
    } else {
      setCloseAlignBool(false);
      setCalcTextWidth(metrics.width);
    }
  };

  return (
    <div>
      {label && (
        <div>
          <label className={labelClass}>{label}</label>
        </div>
      )}
      <div className="pos-rel">
        {prependImg && (
          <img className="pos-abs prependImg" src={prependImg}></img>
        )}

        <input
          id="searchValue"
          type={type}
          className={smallSearchBar ? 'search-bar' : 'search-bar-new'}
          placeholder={placeholder}
          value={searchText}
          onChange={onChangeHandler}
          onBlur={onBlur}
          onKeyDown={onEnterKey}
        ></input>
        {appendImg && showAppendImg && (
          <img
            className={
              smallSearchBar
                ? 'pos-abs appendImgSmall cur-pointer'
                : 'pos-abs appendImg cur-pointer'
            }
            src={appendImg}
            onClick={clearSearch}
            alt="Search Icon"
            style={
              closeAlignBool
                ? { right: 0 }
                : !smallSearchBar
                ? { left: calcTextWidth }
                : { left: 'unset' }
            }
          ></img>
        )}
      </div>
      {info && (
        <div className="mb-10">
          <label className={infoClass}>{info}</label>
        </div>
      )}
    </div>
  );
}

export default Search;
