import React, { useState } from 'react';
import { DOMAIN } from '../../constants/base';
import { getSessionStorage } from '../../utils';
import { Fade } from 'react-reveal';
// Assets
import './deleteproductitem.scss';
import binBtn from '../../assets/bin.png';
import skillPlaceholder from '../../assets/skillPlaceholder.png';

const DeleteProductItem = ({
  imgSrc,
  skillID,
  videoName,
  setAddedVideos,
  addedVideos,
  onChangeInput,
  idx,
}) => {
   const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
   
  const [enableDelete, setEnableDelete] = useState(false);

  const deleteFile = (skillID) => {
    if (addedVideos.length < 1) {
      return;
    }
    const url = `${DOMAIN}/v1/dashboard/skill/${skillID}`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: userToken.accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.successCode === 'SUCC200') {
          setAddedVideos(addedVideos.filter((item) => item.id !== skillID));
        }
        setEnableDelete(false);
      });
  };

  const handleBrokenImage = (e) => {
    e.target.src = skillPlaceholder;
  };

  return (
    <Fade bottom duration={150}>
      <li
        className={
          enableDelete
            ? `d-flex vertical-center video-item-edit video-item-edit-red`
            : 'd-flex vertical-center video-item-edit video-item-edit-white'
        }
      >
        <div className="d-flex vertical-center space-between w-95p">
          <div className="d-flex">
            <div className="image-wrapper">
              <img src={imgSrc} onError={handleBrokenImage}></img>
            </div>
            {!enableDelete && (
              <input
                className="video-name"
                placeholder={videoName}
                onChange={(e) => onChangeInput(idx, e)}
              />
            )}

            {enableDelete && (
              <div className="d-flex center-align ml-5">
                <button onClick={() => deleteFile(skillID)}>DELETE</button>
                <button
                  className="btn-white ml-5"
                  onClick={() => setEnableDelete(false)}
                >
                  BACK
                </button>
              </div>
            )}
          </div>
          {!enableDelete && (
            <button className="del-btn" onClick={() => setEnableDelete(true)}>
              <img src={binBtn}></img>
            </button>
          )}
        </div>
      </li>
    </Fade>
  );
};

export default DeleteProductItem;
