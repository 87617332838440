import React, { useEffect, useState, useContext } from 'react';
import TextAtom from '../TextAtom';
import ButtonAtom from '../ButtonAtom';
import { validateCompany } from '../../utils';
import { Fade } from 'react-reveal';
import Pulse from 'react-reveal/Pulse';
import { DOMAIN } from '../../constants/base';
import { currentProductContext } from '../../context';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';
import { toast } from 'react-toastify';

// Assets
import BackImg from '../../assets/back.png';

const PopUpMagicPairing = ({ setMagicLinkPopUp, fetchActiveSpaces }) => {
  const [currentProduct] = useContext(currentProductContext);
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};

  // Local State
  const [productName] = useState(currentProduct.currentProductName);
  const [magicLink, setMagicLink] = useState(currentProduct.currentMagicLink);
  const [currentMagicLinkError, setCurrentMagicLinkError] = useState(false);
  const [enableDeleteLink, setEnableDeleteLink] = useState(false);

  // Pop Up Magic Link Blur Handler
  const PopUpBlurMagicLink = () => {
    if (enableDeleteLink && magicLink === '') {
      return;
    }
    let isValid = validateCompany(magicLink);
    setCurrentMagicLinkError(!isValid);
  };

  useEffect(() => {
    setEnableDeleteLink(magicLink ? true : false);
  }, []);

  const editMagicLink = (e) => {
    if (validateCompany(magicLink) || (enableDeleteLink && magicLink === '')) {
      const url = `${DOMAIN}/v1/dashboard/product/${currentProduct.currentProductId}`;
      const payload = {
        productURL: magicLink,
      };

      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: userToken.accessToken,
        },
        body: JSON.stringify(payload),
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          const { error } = data;
          if (error === 'ERR409') {
            toast.error('Duplicate Magic Link', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
          }
          fetchActiveSpaces();
          setMagicLinkPopUp(false);
        });
    }
  };

  return (
    <div className="popups-wrapper">
      <ClickAwayContentWrapper onClose={() => setMagicLinkPopUp(false)}>
        <Pulse duration="150">
          <div className="form-card-wrapper">
            <div className="d-flex w-100p">
              <button
                className="back-button"
                onClick={() => setMagicLinkPopUp(false)}
              >
                <img src={BackImg}></img>
              </button>
              <h1 className="popup-heading ml-10">Magic Pairing</h1>
            </div>
            <div className="form-wrapper mt-30">
              <TextAtom
                textContent={
                  productName.length > 20
                    ? productName.substring(0, 20) + '...'
                    : productName
                }
                fontFamily="Montserrat"
                fontWeight="700"
                fontSize="1rem"
                color="#3A3A3A"
              ></TextAtom>
              <div className="input-wrapper mt-20">
                <div className="d-flex vertical-center">
                  <div className="textarea-wrapper d-flex f-col">
                    <div className="d-flex space-between">
                      <label className="d-ib fs-8 fw-900 mb-10">
                        WEB ADDRESS
                      </label>
                      {currentMagicLinkError ? (
                        <Fade bottom duration="150">
                          <span className="c-error fs-8 fw-700">
                            PLEASE ENTER VALID LINK
                          </span>
                        </Fade>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="d-flex vertical-center text-area-wrapper">
                      <textarea
                        placeholder="www.yourwebsite.com/your_product_name"
                        value={magicLink}
                        onChange={(e) => setMagicLink(e.target.value)}
                        onBlur={PopUpBlurMagicLink}
                      ></textarea>
                    </div>

                    <span className="info-text mt-20">
                      It’s important you paste the link to the exact page
                      <br></br> you want the content to appear on your website.
                      <br></br> You can always edit this later.
                    </span>
                  </div>
                </div>
                <div className="mt-20">
                  <ButtonAtom
                    label="SAVE"
                    onClick={(e) => editMagicLink(e)}
                  ></ButtonAtom>
                </div>
              </div>
            </div>
          </div>
        </Pulse>
      </ClickAwayContentWrapper>
    </div>
  );
};

export default PopUpMagicPairing;
