import React, {useContext, useState} from 'react';
import { StatsContext } from '../../context';
import { AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer } from 'recharts';
import './areachart.scss';


// const graphData = [
//   { percentage: 100,  second: 0 },
//   { percentage: 80,   second: 2 },
//   { percentage: 70,   second: 5 },
//   { percentage: 60,  second: 60 },
//   { percentage: 40,  second: 80 },
//   { percentage: 30,  second: 90 },
//   { percentage: 25, second: 100 },
//   { percentage: 15, second: 115 },
//   { percentage: 8,  second: 120 }
// ];

function formatXAxis(tickItem) {
  return tickItem+'%';
}

const renderTooltip = (props) => {
  if (props && props.payload && props.payload[0]) {
    return (
      <div
        style={{
          width: '73px',
          height: '76px',
          borderRadius: '8px',
          padding: '8px 16px',
          color: 'rgba(18, 17, 28, 0.36)',
          letterSpacing: '0.25px',
          fontSize: '10px',
          fontWeight: '600',
          lineHeight: '20px',
          background: '#fff',
          boxShadow: '0px 8px 24px -6px rgba(15, 34, 67, 0.16), 0px 1px 3px rgba(15, 34, 67, 0.12), 0px 0px 1px rgba(15, 34, 67, 0.16)',
        }}
      >
        <div
         style={{
           margin: '0 auto',
           marginTop: '8px'
         }}
         >
           Drop at
         </div>
        <div style={{
          fontWeight: '700',
          fontSize: '10px',
          lineHeight: '16px',
          letterSpacing: '0.4px',
          color: '#000',
          margin: '0 auto',
        }}>
          {props.payload[0].payload.second} sec
        </div>
        <div style={{
          fontSize: '10px',
          lineHeight: '16px',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.87)',
          margin: '0 auto',
          letterSpacing: '0.4px',
        }}>
          {props.payload[0].payload.percentage}%
        </div>
      </div>
    );
  }
  return null;
}

const AreaChartComponent = ({graph}) => {
 
  const [statsValue] = useContext(StatsContext);
  // const [graphData, setGraphData] = useState();
  let graphData ;
  if (graph) {
    graphData = graph;
  }
  // dummyarray for showing x-axis in case of no graph data
  const dummyGraph = [{"percentage": 0, "second": statsValue?.skillStats?.endTimeInSecond}];

  if (graph && graph[0].percentage === 0) {
    return (
      <div className="stats-graph-wrapper">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart 
            data={dummyGraph}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                {/* <stop offset="5%" stopColor="#000" stopOpacity={0.8} />
                <stop offset="50%" stopColor="#000" stopOpacity={0.8} /> */}
                <stop offset="60%" stopColor="#000" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#000" stopOpacity={0.01} />
                <stop offset="100%" stopColor="#000" stopOpacity={0.0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="second"
              type="number"
              tick={{ fontSize: 10, fontWeight: '600' }}
              strokeWidth={0}
              domain={[graphData[0].second, graphData[graphData.length - 1].second]}
            />
            <YAxis
              dataKey="percentage"
              type="number"
              tick={{ fontSize: 10, fontWeight: '600' }}
              tickFormatter={formatXAxis}
              strokeWidth={0}
            />
            <CartesianGrid fill="#ffffff" horizontal={true} vertical={false}/>
            <Area
                  type="monotone"
                  dataKey="percentage"
                  stroke="#000"
                  fillOpacity={1}
                  fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
        <div className="legend">(Sec)</div>
      </div>
    );
  } else {
    if(graphData) {
      return (
        <div className="stats-graph-wrapper">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart 
              data={graphData}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="60%" stopColor="#000" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#000" stopOpacity={0.01} />
                  <stop offset="100%" stopColor="#000" stopOpacity={0.0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="second"
                type="number"
                tick={{ fontSize: 10, fontWeight: '600' }}
                strokeWidth={0}
                domain={[graphData[0].second, graphData[graphData.length - 1].second]}
              />
              <YAxis
                dataKey="percentage"
                type="number"
                tick={{ fontSize: 10, fontWeight: '600' }}
                tickFormatter={formatXAxis}
                strokeWidth={0}
              />
              <CartesianGrid fill="#ffffff" horizontal={true} vertical={false}/>
              <Tooltip 
                cursor={{ fill: 'transparent' }}
                offset={0}
                animationEasing={'ease-in-out'}
                content={renderTooltip} />
              <Area
                    type="monotone"
                    dataKey="percentage"
                    stroke="#000"
                    fillOpacity={1}
                    fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className="legend">(Sec)</div>
        </div>
      ); 
    }
    return <div></div>;
  }
}

export default AreaChartComponent;