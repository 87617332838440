import React, { useState, useEffect, useContext } from 'react';
import './popupeditvideos.scss';
import Pulse from 'react-reveal/Pulse';
import ButtonAtom from '../ButtonAtom';
import TextAtom from '../TextAtom';
import { Storage } from 'aws-amplify';
import { DOMAIN } from '../../constants/base';
import { getSessionStorage, getFormData } from '../../utils';
import { putFormReq, postReq } from '../../utils/api';
import 'react-circular-progressbar/dist/styles.css';
import { useDropzone } from 'react-dropzone';
import { currentProductContext } from '../../context';
import Input from '../../components/Input';
import LoadingSpin from '../../components/LoadingSpin';
import ReactDragListView from 'react-drag-listview/lib/index.js';

//   Assets
import BackImg from '../../assets/back.png';
import DeleteProductItem from '../DeleteProductItem';
import cancelBtn from '../../assets/circular.svg';
import equalDrag from '../../assets/drag-equal.svg';
import circularChecked from '../../assets/circularChecked.svg';
import uploadIcon from '../../assets/upload-icon.png';
import uploadBtn from '../../assets/upload-btn.png';

let StoragePromise;

const PopUpEditVideos = ({
  setOpenEditVideosPopUp,
  fetchActiveProducts,
  addSkill,
}) => {
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};

  const [currentProduct] = useContext(currentProductContext);
  const [currentScreen, setCurrentScreen] = useState('1');
  const [addedVideos, setAddedVideos] = useState(
    currentProduct.currentSkillValues
  );
  const [addMoreVideos, setAddMoreVideos] = useState([]);
  const [productId] = useState(currentProduct.currentProductId);
  const [renameError, setRenameError] = useState(false);
  const [enableUpload, setEnableUpload] = useState(false);

  // Videos Update Process
  const [updateProgress, setUpdateProgress] = useState(0);
  const [renameFileProgress, setRenameFileProgress] = useState(0);

  // Current Upload Progress
  // const [productId, setProductId] = useState(null);
  const [videoUploadIndex, setVideoUploadIndex] = useState(null);
  const [uploadStoppedIds, setUploadStoppedIds] = useState([]);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // useEffect(() => {
  //   if (!addMoreVideos.length) {
  //     return;
  //   }
  //   setFileInfo();
  // }, [addMoreVideos]);

  useEffect(() => {
    videoDurationHandler();
  }, [videoUploadIndex]);

  const handleBackBtn = () => {
    if (currentScreen === '1') {
      setCurrentScreen('1');
      fetchActiveProducts();
      setOpenEditVideosPopUp(false);
    } else if (currentScreen === '2') {
      setCurrentScreen('1');
    } else if (currentScreen === '3') {
      setCurrentScreen('2');
    }
  };

  //   Renaming Files
  const renameFiles = async (currentFileNo = 0) => {
    if (currentFileNo >= addedVideos.length) {
      fetchActiveProducts();
      setOpenEditVideosPopUp(false);
      return;
    } else {
      let payload = {
        name: addedVideos[currentFileNo].rename
          ? addedVideos[currentFileNo].rename
          : addedVideos[currentFileNo].name,
      };
      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      const url = `${DOMAIN}/v1/dashboard/skill/${addedVideos[currentFileNo].id}`;
      const formData = getFormData(payload);
      const editResponse = await putFormReq({
        url,
        payload: formData,
        requestHeaders,
      });
      const { error = '', successCode = '', data = {} } = editResponse;

      if (error === 'ERR403') {
        console.log('Something Happened Bad', error);
        return;
      }
      if (error === 'ERR404') {
        console.log('User Not Found');
        return;
      }
      if (successCode === 'SUCC200') {
        const fileNo = currentFileNo + 1;
        const progress = fileNo / addedVideos.length;
        setRenameFileProgress(progress * 100);
        renameFiles(currentFileNo + 1);
        setShowLoader(false);
        setDisabled(false);
      }
    }
  };

  const videoUploadFinishHandler = () => {
    setOpenEditVideosPopUp(false);
    fetchActiveProducts();
  };

  const videoDurationHandler = () => {
    let currentVideo = addMoreVideos[videoUploadIndex];
    if (currentVideo) {
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.addEventListener('loadedmetadata', function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        FilesUpload(Math.round(duration), currentVideo);
      });
      if (!currentVideo.videoDuration) {
        video.src = URL.createObjectURL(currentVideo);
      }
    }
  };

  //   Adding More Videos
  const FilesUpload = (duration, currentVideo) => {
    if (videoUploadIndex === addMoreVideos.length) {
      videoUploadFinishHandler();
      return;
    }
    try {
      console.log('file upload started ', videoUploadIndex);
      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      let url = `${DOMAIN}/v1/dashboard/product/skill/name`;
      const req = {
        url,
        payload: {
          position: addedVideos.length + videoUploadIndex + 1,
          id: productId,
          name: currentVideo.rename,
        },
        requestHeaders,
      };
      postReq(req).then((fileName) => {
        if (fileName.data) {
          const { skillId, path } = fileName.data;
          let videoExt = currentVideo.type.split('/')[1];
          if (videoExt !== 'mov' && videoExt !== 'mp4') {
            videoExt = videoExt.includes('.mp4') ? 'mp4' : 'mov';
          }
          StoragePromise = Storage.put(`${path}.${videoExt}`, currentVideo, {
            contentType: `video/${videoExt}`,
            progressCallback(progress) {
              let lp = (progress.loaded / progress.total) * 100;
              if (setOpenEditVideosPopUp) {
                setLoadingPercentage(lp);
              }
            },
          })
            .then(() => {
              console.log('files done uploading ', videoUploadIndex);
              url = `${DOMAIN}/v1/dashboard/product/skill/${skillId}/update-path`;
              const newReq = {
                url,
                requestHeaders,
                payload: {
                  path,
                  videoExt,
                  videoDuration: duration,
                },
              };
              postReq(newReq);
              if (videoUploadIndex < addMoreVideos.length - 1) {
                console.log('go next');
                setLoadingPercentage(0);
                setVideoUploadIndex(videoUploadIndex + 1);
              } else {
                videoUploadFinishHandler();
              }
            })
            .catch((e) => {
              console.log('error uploading', e);
            });
        }
      });
    } catch (e) {
      console.log('some error');
    }
  };

  const checkFileNameAndUpdate = () => {
    setShowLoader(true);
    setDisabled(true);
    fetchActiveProducts();
    renameFiles();
    skillPosition();
  };

  // New Videos Handlers

  // Dropping Videos
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setCurrentScreen('1');

      return;
    }
    setAddMoreVideos(addMoreVideos.concat(acceptedFiles));
    setCurrentScreen('2');
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.mp4,.mov',
    maxSize: 524288000,
  });

  // Delete Videos
  const DeleteFile = (value) => {
    setAddMoreVideos(addMoreVideos.filter((item, index) => index !== value));
  };

  // Rename Video Files
  const addMoreVideosRenameFileHandler = (index, event) => {
    let files = [...addMoreVideos];
    files[index].rename = event.target.value;
    setAddMoreVideos(files);
  };

  const videoRenameHandler = (index, event) => {
    let files = [...addedVideos];
    files[index].rename = event.target.value;
    setAddedVideos(files);
  };

  // Checking Upload
  const CheckUpload = () => {
    let renameFlag = false;
    for (let i = 0; i < addMoreVideos.length; i++) {
      if (!addMoreVideos[i].rename) {
        setRenameError(true);
        renameFlag = true;
        break;
      }
    }
    if (!renameFlag) {
      setRenameError(false);
      setCurrentScreen('3');
      if (videoUploadIndex === null) {
        setVideoUploadIndex(0);
      }
    }
  };

  // const setFileInfo = () => {
  //   setEnableUpload(false);
  //   const videos = [...addMoreVideos];
  //   addMoreVideos.forEach((file, index) => {
  //     var video = document.createElement('video');
  //     video.preload = 'metadata';
  //     video.onloadedmetadata = function () {
  //       window.URL.revokeObjectURL(video.src);
  //       var duration = video.duration;
  //       videos[index].videoDuration = Math.round(duration);
  //       if (index === addMoreVideos.length - 1) {
  //         setAddMoreVideos(videos);
  //         setEnableUpload(true);
  //       }
  //     };
  //     if (!file.videoDuration) {
  //       video.src = URL.createObjectURL(file);
  //     }
  //   });
  // };

  const OnCanelUpload = (i) => {
    if (i !== videoUploadIndex) {
      const cancl = [...uploadStoppedIds];
      cancl.push(i);
      DeleteFile(i);
      setUploadStoppedIds(cancl);
    }
  };

  const OnCanelUploadAll = () => {
    setUploadStoppedIds(
      addMoreVideos.map((x, i) => (i > videoUploadIndex ? i : -1))
    );
    setAddedVideos([]);
    setOpenEditVideosPopUp(false);
  };

  useEffect(() => {
    // logic here
    // setCountryCodes(cc.code())
    return () => {
      setUploadStoppedIds(addMoreVideos.map((x, i) => i));
    };
  }, []);

  // Drag Handler
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...addMoreVideos];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setAddMoreVideos(data);
    },
    nodeSelector: 'li',
    handleSelector: 'img',
    lineClassName: 'sort-line',
  };

  // Drag Handler
  const addedVideosDragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...addedVideos];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setAddedVideos(data);
    },
    nodeSelector: 'li',
    handleSelector: 'img',
    lineClassName: 'sort-line',
  };

  const skillPosition = () => {
    const skillIdPosition = {};
    if (addedVideos) {
      const positionData = [...addedVideos];
      for (let i = 0; i < positionData.length; i++) {
        skillIdPosition[positionData[i].id] = i + 1;
      }
    }
    sortingVideos(skillIdPosition);
  };

  const sortingVideos = async (skillIdPosition) => {
    let payload = {
      skillIdPosition,
    };
    let url = `${DOMAIN}/v1/dashboard/product/skill/${productId}/position`;
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: userToken.accessToken,
      },
      body: JSON.stringify(payload),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then(() => {
        console.log('success');
        setShowLoader(false);
        setDisabled(false);
      });
  };

  return (
    <div className="popups-wrapper">
      <Pulse duration={150}>
        <div className="form-card-wrapper">
          <div className="d-flex w-100p">
            <button className="back-button" onClick={handleBackBtn}>
              <img src={BackImg}></img>
            </button>
            <div className="current-step-wrapper">
              <div
                className={
                  currentScreen === '1' || currentScreen === '2'
                    ? 'circle active'
                    : 'circle'
                }
              ></div>
              <div
                className={
                  currentScreen === '3' || currentScreen === '4'
                    ? 'circle active'
                    : 'circle'
                }
              ></div>
            </div>
          </div>
          {currentScreen === '1' && (
            <>
              {addSkill ? (
                <div className="form-wrapper mt-20">
                  <div className="d-flex f-col h-center">
                    <img className="w-60p" src={uploadIcon}></img>
                    <div className="mt-40 text-center">
                      <TextAtom
                        textContent="Upload Videos"
                        fontFamily="Montserrat"
                        fontWeight="700"
                        fontSize="1rem"
                        color="#3A3A3A"
                      ></TextAtom>
                      <p className="fs-12 text-center mt-20 c-dblue fw-700">
                        Up to 450 MB per Video File<br></br>
                        <br></br> Upload up to 10 Files at Once <br></br>
                        <br></br> Supported File Formats: .mov, .mp4
                      </p>
                    </div>
                    <div className="floating-btn mt-20" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <img src={uploadBtn}></img>
                      <div className="hover-ani hover-ripple-out" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="form-wrapper mt-20">
                  <div className="d-flex f-col">
                    <div className="mt-20">
                      <TextAtom
                        textContent="Added Videos"
                        fontFamily="Montserrat"
                        fontWeight="700"
                        fontSize="1rem"
                        color="#3A3A3A"
                      ></TextAtom>
                    </div>

                    <div className="videos-list-edit mt-20">
                      <ReactDragListView {...addedVideosDragProps}>
                        <ul>
                          {addedVideos.map((file, index) => (
                            <DeleteProductItem
                              videoName={file.name}
                              skillID={file.id}
                              key={index}
                              setAddedVideos={setAddedVideos}
                              addedVideos={addedVideos}
                              imgSrc={file.poster}
                              onChangeInput={videoRenameHandler}
                              idx={index}
                            ></DeleteProductItem>
                          ))}
                        </ul>
                      </ReactDragListView>
                    </div>
                    <div className="mt-20 w-100p">
                      <ButtonAtom
                        label="SAVE"
                        onClick={() => checkFileNameAndUpdate()}
                        showLoader={showLoader}
                        disabled={disabled}
                      ></ButtonAtom>
                    </div>
                    <div
                      style={{ outline: 'none' }}
                      className="mt-20"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <button className="link-btn-cta">ADD MORE VIDEOS</button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {currentScreen === '2' ? (
            <div className="form-wrapper">
              <div className="d-flex f-col h-center">
                <div className="text-left mt-40 w-100p">
                  <TextAtom
                    textContent="Upload Videos"
                    fontFamily="Montserrat"
                    fontWeight="700"
                    fontSize="1rem"
                    color="#3A3A3A"
                  ></TextAtom>
                </div>
                <ReactDragListView {...dragProps}>
                  <div className="videos-list">
                    <ul>
                      {addMoreVideos.map((file, index) => (
                        <li key={index}>
                          <img className="equal-drag" src={equalDrag}></img>
                          <Input
                            type="text"
                            label="VIDEO NAME"
                            labelStyle="uc mb-5"
                            placeholder={file.name}
                            info={renameError ? 'PLEASE NAME YOUR VIDEO' : ''}
                            value={file.rename}
                            tabIndex={10 + index}
                            disabled={
                              videoUploadIndex !== null &&
                              index <= videoUploadIndex
                            }
                            onChange={(event) =>
                              addMoreVideosRenameFileHandler(index, event)
                            }
                          />
                          <button>
                            {index === videoUploadIndex ? (
                              <div className="upload-loading-spin">
                                <LoadingSpin />
                              </div>
                            ) : (
                              <img
                                src={
                                  index < videoUploadIndex
                                    ? circularChecked
                                    : cancelBtn
                                }
                                onClick={() => {
                                  index >= videoUploadIndex &&
                                    OnCanelUpload(index);
                                }}
                              ></img>
                            )}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </ReactDragListView>
                <ButtonAtom
                  label="CONTINUE"
                  onClick={() => CheckUpload()}
                  disabled={addMoreVideos.length > 0 ? false : true}
                ></ButtonAtom>
                <div
                  style={{ outline: 'none' }}
                  className="mt-20"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <button className="link-btn-cta">ADD MORE VIDEOS</button>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {currentScreen === '3' ? (
            <div className="form-wrapper mt-20">
              <div className="d-flex f-col h-center">
                {/* <div className="mt-20">
                  <CircularProgressbarWithChildren
                    value={updateProgress}
                    styles={{
                      root: {},
                      path: {
                        stroke: 'rgba(0, 41, 255, 1)',
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                      },
                      background: {
                        fill: 'rgba(211, 211, 211, 1)',
                      },
                    }}
                  >
                    <div style={{ fontSize: 30 }}>
                      <strong>{`${Math.round(updateProgress)}%`}</strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </div> */}
                <div className="text-left mt-40 w-100p">
                  <TextAtom
                    textContent="Videos Uploading.."
                    fontFamily="Montserrat"
                    fontWeight="700"
                    fontSize="1rem"
                    color="#3A3A3A"
                  ></TextAtom>
                </div>
                <div className="videos-list-uploading">
                  <ul>
                    {addMoreVideos.map((video, i) => (
                      <li>
                        <div className="list-upload-container">
                          <div className="list-upload-container-item">
                            <div className="list-upload-container-item-desc">
                              <h4>{video.rename}</h4>
                              <span>
                                {videoUploadIndex === i
                                  ? parseInt(loadingPercentage || 0)
                                  : i < videoUploadIndex
                                  ? '100'
                                  : '0'}
                                %
                              </span>
                            </div>
                            <div className="list-upload-container-item-loader">
                              <div
                                style={{
                                  width: `${
                                    videoUploadIndex === i
                                      ? loadingPercentage
                                      : i < videoUploadIndex
                                      ? 100
                                      : 0
                                  }%`,
                                }}
                                className="list-upload-container-item-loader-child"
                              />
                            </div>
                          </div>
                          <button>
                            {i === videoUploadIndex ? (
                              <div className="upload-loading-spin">
                                <LoadingSpin />
                              </div>
                            ) : (
                              <img
                                src={
                                  i < videoUploadIndex
                                    ? circularChecked
                                    : cancelBtn
                                }
                                onClick={() => {
                                  i >= videoUploadIndex && OnCanelUpload(i);
                                }}
                              ></img>
                            )}
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-20 video-uploading-text">
                  <h1>Videos Uploading..</h1>
                  <p>
                    Please don’t close this window until upload is complete.
                    <br></br>
                    <br></br>
                    Pressing the back button will stop the uploading process.
                  </p>
                </div>
                <div className="mt-20">
                  <button
                    className="link-btn-cta c-error"
                    onClick={OnCanelUploadAll}
                  >
                    CANCEL UPLOAD
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {/* {currentScreen === '4' ? (
            <div className="form-wrapper mt-20">
              <div className="d-flex f-col h-center">
                <div className="mt-20">
                  <CircularProgressbarWithChildren
                    value={renameFileProgress}
                    styles={{
                      root: {},
                      path: {
                        stroke: 'rgba(0, 41, 255, 1)',
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                      },
                      background: {
                        fill: 'rgba(211, 211, 211, 1)',
                      },
                    }}
                  >
                    <div style={{ fontSize: 30 }}>
                      <strong>{`${Math.round(renameFileProgress)}%`}</strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div className="mt-20 video-uploading-text">
                  <h1>Videos Updating..</h1>
                  <p>
                    Please don’t close this window until upload is complete.
                    <br></br>
                    <br></br>
                    Pressing the back button will stop the uploading process.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )} */}
        </div>
      </Pulse>
    </div>
  );
};

export default PopUpEditVideos;
