import React, { useState, useEffect, useRef, useMemo } from 'react';
import './popupeditproduct.scss';
import Pulse from 'react-reveal/Pulse';
import { DOMAIN } from '../../constants/base';
import { validateCompany, getUniqueListByObjectKey } from '../../utils';
import SwitchAtom from '../../components/SwitchAtom';
import getSymbolFromCurrency from 'currency-symbol-map';
import PopUpEditProductsSkill from '../../components/PopUpEditProductsSkill';
import { useDropzone } from 'react-dropzone';
import VideoUploader from '../VideoUploader';
import { postReq, putReq } from '../../utils/api';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import { debounce } from '../../utils';

//   Assets
import BackImg from '../../assets/back.png';
import EditIcon from '../../assets/editnew.svg';
import BreadcrumbIcon from '../../assets/breadcrumb-arr.png';
import CTALink from '../../assets/link-svg.svg';
import CTALinkOff from '../../assets/off-link.svg';
import Add from '../../assets/add-union.svg';
import AddIcon from '../../assets/Button_Circular.svg';
import DeleteIcon from '../../assets/delete-btn.svg';
import ReplaceIcon from '../../assets/replace-icon.png';
import Remove from '../../assets/close-pair.svg';

import PopUpEditProductHashTag from '../PopUpEditProductHashTag';
import LoadingSpin from '../../components/LoadingSpin';

const PopUpEditProduct = ({
  setOpenEditProductPopUp,
  fetchActiveProducts,
  selectedCardsProps,
}) => {
  const [editProducts, setEditProducts] = useState(false);
  const [magicPairLinks, setMagicPairLinks] = useState(
    selectedCardsProps.productMagicLinks
  );
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};

  const [holofyRefId, setHolofyRefId] = useState(0);
  const [cardSkills, setCardSkills] = useState(selectedCardsProps.skills);
  const [toggleSkillFalse, setToggleSkillFalse] = useState(false);
  const [hashTagsArray, setHashTagsArray] = useState(
    selectedCardsProps.hashTags
  );
  const [hashTagsValue, setHashTagsValue] = useState('');
  const [videoUploaded, setVideoUploaded] = useState(0);
  const [editHashTag, setEditHashTag] = useState(false);
  const [isReplace, setIsReplace] = useState(false);

  // State For Handling API CALLS.
  const [magicLinkEdited, setMagicLinkEdited] = useState(false);

  // Status Messages
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorType, setErrorType] = useState('');
  const [deleteStatus, setDeleteStatus] = useState(false);

  const [currentErrorIndex, setCurrentErrorIndex] = useState([]);

  //Form stats
  const [productName, setProductName] = useState(selectedCardsProps.name);
  const [priceState, setPriceState] = useState(selectedCardsProps.price);
  const [currencyState, setCurrencyState] = useState(
    selectedCardsProps.currency
  );

  const { skills, widgetLink, id, holofyRef } = selectedCardsProps;
  const { DragColumn } = ReactDragListView;

  const [scrollMagicLink, setScrollMagicLink] = useState(false);
  const [scrollToUpload, setScrollToUpload] = useState(false);

  const [linkToggle, setLinkToggle] = useState(true);
  const [linkToggleIndex, setLinkToggleIndex] = useState([]);
  const [linkToggleError, setLinkToggleError] = useState('');

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() =>
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    );
    return <div ref={elementRef} />;
  };

  useEffect(() => {
    if (videoFiles.length > 0) {
      setScrollToUpload(true);
    }
  }, [videoFiles]);

  useEffect(() => {
    setHolofyRefId(holofyRef.match(/\d/g).join(''));
  }, []);

  useEffect(() => {
    if (editProducts) {
      setCurrentErrorIndex([]);
      setLinkToggleIndex([]);
    }
  }, [editProducts]);

  const handleBackBtn = () => {
    if (videoFiles.length === 0) {
      nameAndSortReq();
      setOpenEditProductPopUp(false);
      fetchActiveProducts();
    }
  };

  const handleBackBtnHashTag = () => {
    setEditHashTag(false);
  };

  const toggleEditProducts = () => {
    setEditProducts(!editProducts);
  };

  const checkHashtagType = (hashtag) => {
    if (hashtag.tag?.length >= 0) return hashtag.tag;
    return hashtag;
  };

  /* start 
  Magic link related methods and api calls
  */

  const magicLinksMethod = (value, index) => {
    setMagicLinkEdited(true);
    let array = [...magicPairLinks];
    array[index].productURL = value;
    setMagicPairLinks(array);
  };

  //check for duplicate magic link
  const searchMagicLink = async (value, index) => {
    if (validateCompany(value)) {
      const url = `${DOMAIN}/v1/dashboard/product/${id}/magiclink/search`;
      const payload = {
        productURL: value,
      };
      const requestHeaders = {
        authorization: userToken.accessToken,
      };

      const statusResponse = await postReq({
        url,
        payload,
        requestHeaders,
      });

      const { error, successCode } = statusResponse;
      if (error === 'ERR409') {
        setErrorMsg(
          'You have already paired a product to this URL. Please try another.'
        );
        setErrorType('error');
        setCurrentErrorIndex([...currentErrorIndex, index]);
      }
      if (successCode === 'SUCC200') {
        setErrorMsg(undefined);
        if (currentErrorIndex.includes(index)) {
          let arr = currentErrorIndex.filter((item) => item !== index);
          setCurrentErrorIndex(arr);
        }
      }
    } else {
      setErrorMsg('Magiclink is Invalid');
      setErrorType('error');
      if (!currentErrorIndex.includes(index)) {
        setCurrentErrorIndex([...currentErrorIndex, index]);
      }
    }
  };

  const debouncedWebPageSearchPair = useMemo(() => {
    return debounce(searchMagicLink, 300);
  }, []);

  const toggleMagicLink = async (magicLinkUrl, magicLinkId, index) => {
    let array = [...magicPairLinks];
    let status = '';
    array.find((item) => {
      if (item.id === magicLinkId) {
        if (item.status === true) {
          item.status = false;
          status = false;
        } else {
          item.status = true;
          status = true;
        }
      }
    });
    setMagicPairLinks(array);
    if (id) {
      const url = `${DOMAIN}/v1/dashboard/product/${id}/magiclink/${magicLinkId}/status`;
      const payload = {
        status: status,
        productURL: magicLinkUrl,
      };
      const requestHeaders = {
        authorization: userToken.accessToken,
      };

      const statusResponse = await putReq({
        url,
        payload,
        requestHeaders,
      });

      const { error, successCode } = statusResponse;
      if (successCode === 'SUCC200') {
        setEditProducts(false);
        setLinkToggle(true);
        let arr = currentErrorIndex.filter((el) => {
          return !linkToggleIndex.includes(el);
        });
        setCurrentErrorIndex(arr);
      }
      if (error === 'ERR409') {
        setLinkToggle(false);
        setErrorMsg(undefined);
        setLinkToggleError(
          'You have already paired a product to this URL. Please try another.'
        );
        setLinkToggleIndex([...linkToggleIndex, index]);
        setCurrentErrorIndex([...currentErrorIndex, index]);
      }
    }
  };

  const addMagicLink = () => {
    setMagicPairLinks([
      ...magicPairLinks,
      {
        status: true,
        url: '',
        holofyRef: parseFloat(holofyRefId),
      },
    ]);
    setScrollMagicLink(true);
  };

  const removeMagicLink = (id) => {
    setMagicLinkEdited(true);
    var filteredArr = magicPairLinks.filter(function (value, index, arr) {
      return index !== id;
    });
    setMagicPairLinks(filteredArr);
  };

  //save maginc link
  const editMagicLinks = async () => {
    if (id && magicLinkEdited) {
      let magicLinksObj = [];
      let uniqueMagicPairLinks = getUniqueListByObjectKey(
        magicPairLinks,
        'productURL'
      );
      uniqueMagicPairLinks = uniqueMagicPairLinks.filter(
        (obj) => obj.productURL !== undefined
      );
      setMagicPairLinks(uniqueMagicPairLinks);
      for (const key of uniqueMagicPairLinks) {
        magicLinksObj = [
          ...magicLinksObj,
          {
            status: key.status,
            url: key.productURL,
            holofyRef: parseFloat(holofyRefId),
          },
        ];
      }
      const url = `${DOMAIN}/v1/dashboard/product/${id}/magiclink`;
      const payload = {
        productURL: magicLinksObj,
      };
      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      const statusResponse = await putReq({
        url,
        payload,
        requestHeaders,
      });
      const { successCode, data } = statusResponse;
      if (successCode === 'SUCC200') {
        setMagicPairLinks(data.magicLinksIds);
      }
    }
  };
  /* end
    magic link related methods 
  */

  const savePopUpEdit = () => {
    nameAndSortReq();
    editMagicLinks();
    setEditProducts(false);
  };

  const selectSkillMethod = (id, check) => {
    let array = [...selectedSkill];
    if (check) {
      array.push(id);
    } else if (!check) {
      let index = array.indexOf(id);
      array.splice(index, 1);
    }
    setSelectedSkill(array);
  };

  const deleteSkill = (skillIndex) => {
    setDeleteStatus(true);
    if (skillIndex === selectedSkill.length) {
      updateSkillsMethod();
      setDeleteStatus(false);
      return;
    }
    const url = `${DOMAIN}/v1/dashboard/product/skill/${selectedSkill[skillIndex]}`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: userToken.accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.successCode === 'SUCC200') {
          deleteSkill(skillIndex + 1);
        }
      });
  };

  const updateSkillsMethod = () => {
    let newArray = cardSkills.filter(
      (item) => !selectedSkill.includes(item.id)
    );
    setCardSkills(newArray);
    setSelectedSkill([]);
    setToggleSkillFalse(true);
  };

  // File Utilities
  const DeleteFile = (skillId, fileNumber) => {
    const url = `${DOMAIN}/v1/dashboard/skill/${skillId}`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: userToken.accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setVideoUploaded(videoUploaded - 1);
        const newData = videoFiles.filter((item) => item.id !== fileNumber);
        setVideoFiles(newData);
      });
  };

  // Handling Drag & Drop
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      // setErrorMsg(true);
      return;
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      acceptedFiles[i].id = videoFiles.length + i + 1;
    }
    setVideoFiles(videoFiles.concat(acceptedFiles));
    // setShowVideosUpload(true);
  };

  function ButtonDropZone(props) {
    const { getRootProps, getInputProps } = useDropzone({
      noDragEventsBubbling: true,
      onDrop,
      accept: '.mp4,.mov',
      maxSize: 524288000,
    });

    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <button className="button-edit-link">
          <img src={Add}></img> ADD Video
        </button>
      </div>
    );
  }

  const { getRootProps, getInputProps } = useDropzone({
    noDragEventsBubbling: true,
    onDrop,
    accept: '.mp4,.mov',
    maxSize: 524288000,
  });

  // Renaming And Sorting Req
  const nameAndSortReq = async () => {
    const url = `${DOMAIN}/v1/dashboard/product/skill/${selectedCardsProps.id}/position`;
    let payload = {
      skillIdPosition: cardSkills.map((videoFile, index) => {
        if (videoFile.rename) {
          return {
            skillId: videoFile.id,
            position: index + 1,
            name: videoFile.rename,
          };
        } else {
          return {
            skillId: videoFile.id,
            position: index + 1,
          };
        }
      }),
    };

    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const statusResponse = await putReq({
      url,
      payload,
      requestHeaders,
    });
    const { successCode } = statusResponse;
    if (successCode === 'SUCC200') {
      return;
    }
  };

  // Video Uploader
  const dragPropsVideoUploader = {
    onDragEnd(fromIndex, toIndex) {
      if (videoFiles.length < 1 && editProducts) {
        const data = [...cardSkills];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        setCardSkills(data);
      }
    },
    nodeSelector: '.video-container-item',
    handleSelector: '.video-container-item',
    lineClassName: 'sort-line-video-upload',
  };

  return (
    <div className="popups-wrapper">
      <Pulse duration="150">
        <div className="product-card-wrapper drag-presentation">
          <div className="top-wrapper">
            <button className="back-button" onClick={handleBackBtn}>
              <img src={BackImg}></img>
            </button>
            <div className="edit-btn-group">
              {editProducts ? (
                <>
                  {selectedSkill.length > 0 ? (
                    <>
                      <button
                        className="button-delete-link"
                        onClick={() => deleteSkill(0)}
                        disabled={selectedSkill.length === 0}
                      >
                        {' '}
                        {deleteStatus ? <LoadingSpin /> : ''}
                        <img src={DeleteIcon}></img> DELETE
                      </button>
                      {selectedSkill.length === 1 && (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <button
                            className="button-edit-link ml-15"
                            onClick={() => {
                              setIsReplace(true);
                            }}
                          >
                            <img src={ReplaceIcon}></img> Replace
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <ButtonDropZone />
                  )}
                  <button
                    className="button-link ml-15"
                    disabled={errorMsg ? true : false}
                    onClick={savePopUpEdit}
                  >
                    Save
                  </button>
                </>
              ) : (
                <button
                  className="button-edit-link"
                  onClick={toggleEditProducts}
                >
                  {' '}
                  <img src={EditIcon}></img> EDIT
                </button>
              )}
            </div>
          </div>
          <span className="total-videos">
            {cardSkills.length} {cardSkills.length > 1 ? 'videos' : 'video'}
          </span>
          <DragColumn {...dragPropsVideoUploader}>
            <div
              className="video-container"
              style={{
                pointerEvents: deleteStatus ? 'none' : 'unset',
                opacity: deleteStatus ? 0.7 : 1,
              }}
            >
              {cardSkills.map((item) => {
                return (
                  <PopUpEditProductsSkill
                    key={item.id}
                    item={item}
                    editProducts={editProducts}
                    widgetLink={widgetLink}
                    selectSkillMethod={selectSkillMethod}
                    toggleSkillFalse={toggleSkillFalse}
                    setToggleSkillFalse={setToggleSkillFalse}
                  />
                );
              })}
              {videoFiles.length > 0 &&
                videoFiles.map((file) => (
                  <VideoUploader
                    file={file}
                    key={file.id}
                    fileNumber={file.id}
                    id={id}
                    DeleteFile={DeleteFile}
                    skillsLength={skills.length}
                    videoUploaded={videoUploaded}
                    setVideoUploaded={setVideoUploaded}
                    setCardSkills={setCardSkills}
                    setVideoFiles={setVideoFiles}
                    editProducts={editProducts}
                    isReplace={isReplace}
                    setIsReplace={setIsReplace}
                    replaceSkillId={selectedSkill[0]}
                  ></VideoUploader>
                ))}
              {scrollToUpload && <AlwaysScrollToBottom />}
            </div>
          </DragColumn>
          {editProducts && (
            <span className="click-drag-text">CLICK & DRAG TO REORDER</span>
          )}

          <div className="bottom-wrapper">
            <div
              style={{
                border: editProducts
                  ? '1px solid #fff'
                  : '1px solid transparent',
              }}
              className={editProducts ? 'upload-info editable' : 'upload-info'}
              onClick={
                editProducts
                  ? () => {
                      setEditHashTag(!editHashTag);
                    }
                  : undefined
              }
            >
              {editProducts && (
                <img
                  className="product-edit-icon"
                  src={EditIcon}
                  alt="EditIcon"
                />
              )}
              {priceState !== 0 && priceState !== undefined && (
                <div className="product-cost">
                  <span className="currency-type">
                    {getSymbolFromCurrency(currencyState)}
                  </span>
                  {priceState?.toLocaleString()}
                </div>
              )}
              <h1 className="product-name">{productName}</h1>
              <div className="hashtags-info-wrapper">
                {hashTagsArray.map((item, index) => (
                  <div className="hashtag">
                    <div key={index} className="hashtag-info">
                      #{checkHashtagType(item)}
                    </div>
                    {index < hashTagsArray.length - 1 ? (
                      <img
                        src={BreadcrumbIcon}
                        style={{ marginRight: '6px' }}
                      ></img>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="magic-pair-container">
              <div className="magic-pair-heading">
                <img src={editProducts ? CTALinkOff : CTALink} alt="Cta" />
                <h4>Published to</h4>
              </div>
              <div
                className={
                  magicPairLinks.length > 4
                    ? 'magic-pair-input add-v-scroll'
                    : 'magic-pair-input'
                }
              >
                {magicPairLinks.map((item, index) => (
                  <div>
                    <div
                      key={index}
                      className={
                        currentErrorIndex.includes(index)
                          ? 'input-container has-error'
                          : 'input-container'
                      }
                    >
                      <input
                        type="text"
                        placeholder={item.productURL}
                        onChange={(e) => {
                          magicLinksMethod(e.target.value, index);
                          debouncedWebPageSearchPair(e.target.value, index);
                        }}
                        disabled={!editProducts}
                        style={{
                          backgroundColor:
                            item.status === true ? '#fff' : '#E2E2E2',
                        }}
                      />
                      <div>
                        {editProducts ? (
                          <img
                            src={Remove}
                            onClick={() => removeMagicLink(index)}
                            alt="Remove"
                          />
                        ) : (
                          <SwitchAtom
                            toggleVal={
                              item.status === true &&
                              !linkToggleIndex.includes(index)
                                ? true
                                : false
                            }
                            handleToggle={() => {
                              toggleMagicLink(item.productURL, item.id, index);
                            }}
                          ></SwitchAtom>
                        )}
                      </div>
                    </div>
                    {currentErrorIndex.includes(index) &&
                      !linkToggleIndex.includes(index) && (
                        <span className="input-container__error">
                          {errorMsg}
                        </span>
                      )}
                    {linkToggleIndex.includes(index) &&
                      !linkToggle &&
                      !editProducts && (
                        <span className="input-container__error">
                          {linkToggleError}
                        </span>
                      )}
                  </div>
                ))}
                {scrollMagicLink && <AlwaysScrollToBottom />}
              </div>
              {editProducts && (
                <div className="magic-pair-add" onClick={addMagicLink}>
                  <img src={AddIcon} alt="addIconBlack" />
                  <span>ADD MORE PAGES</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Pulse>
      {editHashTag && (
        <PopUpEditProductHashTag
          productName={productName}
          priceState={priceState}
          currencyState={currencyState}
          hashTagsArray={hashTagsArray}
          setProductName={setProductName}
          setPriceState={setPriceState}
          setCurrencyState={setCurrencyState}
          setHashTagsArray={setHashTagsArray}
          handleBackBtnHashTag={handleBackBtnHashTag}
          selectedCardsProps={selectedCardsProps}
        ></PopUpEditProductHashTag>
      )}
    </div>
  );
};

export default PopUpEditProduct;
