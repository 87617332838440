import React, { useEffect, useContext, useState } from 'react';
import './statsdetail.scss';
import LoadingSpin from '../../components/LoadingSpin';
import { getReq } from '../../utils/api';
import StatsPopUpGraph from '../../components/StatsPopUpGraph';
import { StatsContext } from '../../context';
import { DOMAIN } from '../../constants/base';
import EyeIcon from '../../assets/eye-icon.png';
import DollarIcon from '../../assets/dollar-action.png';
import RetentionIcon from '../../assets/stats-retention.png';
import RightArrIcon from '../../assets/right-arr.png';
import StopWatch from '../../assets/stopwatch.svg';
import BackImg from '../../assets/back.png';
import FallBackImg from '../../assets/skillPlaceholder.png';
import VideoStatsCard from '../../components/VideoStatsCard';
import BarChartComponent from '../../components/BarChart';
import { toolTipDateFormat } from '../../utils';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const DetailStats = ({ setShowDetailStats, openPopUp, closePopUp }) => {
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
  const [statsValue, dispatch] = useContext(StatsContext);
  const [skillList, setSkillList] = useState([]);
  const [topProductTraffic, setTopProductTraffic] = useState([]);

  // Loading
  const [loadingProductPerformance, setLoadingProductPerformance] =
    useState(true);
  const [loadingVideoPerformance, setLoadingVideoPerformance] = useState(true);
  const [loadingTrafficPerformance, setLoadingTrafficPerformance] =
    useState(true);
  const [loadingSkills, setLoadingSkills] = useState(true);

  useEffect(() => {
    getProductPerformance();
    getProductSkillsList();
    getTrafficSourcesForProduct();
    getRetentionRate();
  }, []);

  function formatXAxis(tickItem) {
    if (typeof tickItem === 'string') {
      return tickItem.slice(8);
    }
  }

  // Graph Custom
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="custom-tooltip-date">
            {toolTipDateFormat(payload[0].payload.date)}
          </div>
          <h1>Impressions</h1>
          <p className="label">{payload[0].payload.count}</p>
          <h1 style={{ color: '#00DC58' }}>CTA Action Rate</h1>
          <p className="label" style={{ color: '#00DC58' }}>
            {payload[0].payload.ctaClick}
          </p>
        </div>
      );
    }

    return null;
  };

  // click on video performance card
  const handleVideoPerformance = (skillId) => {
    getSkillStatsByProduct(skillId);
    openPopUp('video-performance', undefined);
  };

  async function getProductSkillsList() {
    const url = `${DOMAIN}/v1/dashboard/stats/v3/video-performance/${statsValue?.activeProduct?.productId}`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const skillsResponse = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = skillsResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }

    if (successCode === 'SUCC200') {
      setLoadingSkills(false);
      setLoadingVideoPerformance(false);
      dispatch({
        type: 'SET_PRODUCT_VIDEO_PERFORMANCE',
        payload: {
          avgWatchTime: data.overallAvgWatchTime,
          avgRetentionRate: data.overallRetentionRate,
          skills: data.videoPerformance,
        },
      });
      setSkillList(data.videoPerformance);
    }
  }

  // Product Performance for impression and cta action rate
  async function getProductPerformance() {
    const url = `${DOMAIN}/v1/dashboard/stats/v3/product-performance/${statsValue?.activeProduct?.productId}`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const productPerformanceResponse = await getReq({
      url,
      requestHeaders,
    });
    const {
      error = '',
      successCode = '',
      data = {},
    } = productPerformanceResponse;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setLoadingProductPerformance(false);
      dispatch({
        type: 'SET_PRODUCT_IMPRESSION_CTA',
        payload: {
          graphPlot: data.overview,
          impression: data.totalViews,
          ctaRate: data.avgConversion,
          name: data.name,
        },
      });
    }
  }

  // get skill details of the product to render graph
  async function getSkillStatsByProduct(skillId) {
    const url = `${DOMAIN}/v1/dashboard/stats/v3/video-performance/${statsValue?.activeProduct?.productId}/skill/${skillId}`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const skillStats = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = skillStats;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      dispatch({
        type: 'SET_SKILL_STATS_POPUP',
        payload: {
          name: data.videoPerformance[0].name,
          poster: data.videoPerformance[0].poster,
          views: data.videoPerformance[0].viewCount,
          engagement: data.videoPerformance[0].engagement,
          avgWatchTime: data.videoPerformance[0].avgWatchTime,
          graphPlot: data.videoPerformance[0].secondPercentageArray,
          endTimeInSecond: data.videoPerformance[0].endTimeInSecond,
        },
      });
    }
  }
  // Traffic Sources
  async function getTrafficSourcesForProduct() {
    const url = `${DOMAIN}/v1/dashboard/stats/v3/traffic-source/${statsValue?.activeProduct?.productId}`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const trafficSources = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = trafficSources;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      setLoadingTrafficPerformance(false);
      let sortedData = data.trafficRes.sort((s1, s2) =>
        s1.value > s2.value ? -1 : 1
      );
      dispatch({
        type: 'SET_TRAFFIC_SOURCES_BY_PRODUCT',
        payload: {
          startDate: data.startDate,
          endDate: data.endDate,
          graphPlot: sortedData,
        },
      });
      if (data.trafficRes.length >= 4) {
        setTopProductTraffic(sortedData.slice(0, 3));
      } else {
        setTopProductTraffic(sortedData);
      }
    }
  }

  // get retention rate stats
  async function getRetentionRate() {
    const url = `${DOMAIN}/v1/dashboard/stats/v3/retention-rate/${statsValue?.activeProduct?.productId}`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const retentionRateByProduct = await getReq({
      url,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = retentionRateByProduct;
    if (error === 'ERR500') {
      console.log('Something went wrong. Please try after sometime.');
      return;
    }
    if (successCode === 'SUCC200') {
      dispatch({
        type: 'SET_RETENTION_RATE_BY_PRODUCT',
        payload: {
          value: data.retentionRate === null ? 0 : data.retentionRate,
          graphPlot: data.overview,
          startDate: data.startDate,
          endDate: data.endDate,
        },
      });
    }
  }

  return (
    <div className="d_stats-container">
      <div className="d_stats-container__header">
        <div
          className="d_stats-hide"
          onClick={() => {
            setShowDetailStats(false);
          }}
        >
          <img src={BackImg} />
        </div>
        <div className="product-img">
          {statsValue?.activeProduct?.poster.length > 0 ? (
            <img src={statsValue?.activeProduct?.poster}></img>
          ) : (
            <img src={FallBackImg}></img>
          )}
        </div>
        <div className="product-name">
          <div className="title">product name</div>
          <div className="value">{statsValue?.activeProduct?.productName}</div>
        </div>
      </div>
      <div className="d_stats-container__grid">
        <div className="card-chart performance">
          {loadingProductPerformance ? (
            <div className="loading-product-performance">
              <div className="shimmerBG loading-product-performance-topbar"></div>
              <div className="loading-product-performance-items-wrapper">
                <div className="loading-product-performance-item">
                  <div className="shimmerBG loading-product-performance-circle"></div>
                  <div className="loading-product-performance-rect-wrapper">
                    <div className="shimmerBG loading-product-performance-rect-one"></div>
                    <div className="shimmerBG loading-product-performance-rect-two"></div>
                  </div>
                </div>
                <div className="loading-product-performance-item">
                  <div className="shimmerBG loading-product-performance-circle"></div>
                  <div className="loading-product-performance-rect-wrapper">
                    <div className="shimmerBG loading-product-performance-rect-one"></div>
                    <div className="shimmerBG loading-product-performance-rect-two"></div>
                  </div>
                </div>
              </div>
              <div className="shimmerBG loading-product-performance-bottombar"></div>
            </div>
          ) : (
            <>
              {' '}
              <div className="title-one">product performance</div>
              <div className="card-chart__params">
                <div className="param">
                  <div className="param-icon">
                    <img src={EyeIcon} />
                  </div>
                  <div className="param-details">
                    <div className="param-stat">
                      {statsValue?.impressionCTARate?.impression}
                    </div>
                    <div className="param-name">Impressions</div>
                  </div>
                </div>
                <div className="param">
                  <div className="param-icon">
                    <img
                      src={DollarIcon}
                      style={{ width: '20px', height: '20px' }}
                    />
                  </div>
                  <div className="param-details">
                    <div className="param-stat">
                      {statsValue?.impressionCTARate?.ctaRate}
                      <span className="perc">%</span>
                    </div>
                    <div className="param-name">CTA Action Rate</div>
                  </div>
                </div>
                <div className="legend-wrap">
                  <div className="legend one">Impression</div>
                  <div className="legend two">CTA Action Rate</div>
                </div>
              </div>
              <div className="line-graph-wrapper">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={statsValue?.impressionCTARate?.graphPlot}>
                    <CartesianGrid strokeDasharray="0" vertical={false} />
                    <XAxis
                      dataKey="date"
                      strokeWidth={0}
                      tick={{ fontSize: 10, fontWeight: '600' }}
                      tickFormatter={formatXAxis}
                      allowDataOverflow={true}
                      interval="preserveStartEnd"
                    />
                    <YAxis
                      strokeWidth={0}
                      dataKey="count"
                      tick={{ fontSize: 10, fontWeight: '600' }}
                    />
                    {statsValue?.impressionCTARate?.ctaRate > 0 ||
                    statsValue?.impressionCTARate?.impression > 0 ? (
                      <Tooltip
                        cursor={{ fill: 'transparent' }}
                        offset={0}
                        animationEasing={'ease-in-out'}
                        content={<CustomTooltip />}
                      />
                    ) : (
                      ''
                    )}
                    {statsValue?.impressionCTARate?.ctaRate > 0 ||
                    statsValue?.impressionCTARate?.impression > 0 ? (
                      <Line
                        type="monotone"
                        dataKey="count"
                        stroke="#000"
                        activeDot={{ r: 4 }}
                      />
                    ) : (
                      ''
                    )}
                    {statsValue?.impressionCTARate?.ctaRate > 0 ||
                    statsValue?.impressionCTARate?.impression > 0 ? (
                      <Line
                        type="monotone"
                        dataKey="ctaClick"
                        stroke="#00DC58"
                        activeDot={{ r: 4 }}
                      />
                    ) : (
                      ''
                    )}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </>
          )}
        </div>
        <div className="card-chart video-performance">
          <div className="title-one">video performance</div>
          <div className="dstats-card-wrapper">
            <div
              className="dstats-card"
              onClick={() => openPopUp('product-retention-rate', undefined)}
              style={{ cursor: 'pointer' }}
            >
              {loadingVideoPerformance ? (
                <div className="video-performance-loading">
                  <div className="shimmerBG video-performance-loading-circle"></div>
                  <div>
                    <div className="shimmerBG video-performance-loading-rect-one"></div>
                    <div className="shimmerBG video-performance-loading-rect-two"></div>
                  </div>
                </div>
              ) : (
                <>
                  {' '}
                  <div className="dstats-card__header">
                    <div className="icon">
                      <img src={RetentionIcon} />
                    </div>
                    <div className="icon more-icon">
                      <img src={RightArrIcon} />
                    </div>
                  </div>
                  <div className="param-details">
                    <div className="param-stat">
                      {statsValue?.productVideoPerformance?.avgRetentionRate}
                      <span className="perc">%</span>
                    </div>
                    <div className="param-name">Avg. Retention Rates</div>
                  </div>
                </>
              )}
            </div>

            <div className="dstats-card">
              {loadingVideoPerformance ? (
                <div className="video-performance-loading">
                  <div className="shimmerBG video-performance-loading-circle"></div>
                  <div>
                    <div className="shimmerBG video-performance-loading-rect-one"></div>
                    <div className="shimmerBG video-performance-loading-rect-two"></div>
                  </div>
                </div>
              ) : (
                <>
                  {' '}
                  <div className="dstats-card__header">
                    <div className="icon">
                      <img src={StopWatch} />
                    </div>
                  </div>
                  <div className="param-details">
                    <div className="param-stat">
                      {statsValue?.productVideoPerformance?.avgWatchTime} sec
                    </div>
                    <div className="param-name">Avg. Watch Time</div>
                  </div>
                </>
              )}
            </div>
          </div>
          <VideoStatsCard
            skillsLoading={loadingSkills}
            skills={skillList.sort(
              (l1, l2) => l1.retentionRate - l2.retentionRate
            )}
            setSkills={setSkillList}
            showCompleteStats={handleVideoPerformance}
          ></VideoStatsCard>
        </div>
        <div
          className="card-chart traffic-sources"
          onClick={() => {
            openPopUp('traffic-source', true);
          }}
          style={{ cursor: 'pointer' }}
        >
          {loadingTrafficPerformance ? (
            <div className="traffic-performance-loading">
              <div className="shimmerBG traffic-performance-loading-rect-one"></div>
              <div className="shimmerBG traffic-performance-loading-rect-two"></div>
            </div>
          ) : (
            <>
              {' '}
              <div className="title-one">traffic sources</div>
              <div className="traffic-sources__more">
                <img src={RightArrIcon} />
              </div>
              <div className="graph-wrapper">
                <BarChartComponent
                  graph={topProductTraffic}
                ></BarChartComponent>
              </div>
            </>
          )}
        </div>
        {statsValue.showPopUp && (
          <StatsPopUpGraph onBack={closePopUp}></StatsPopUpGraph>
        )}
      </div>
    </div>
  );
};

export default DetailStats;
