import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import './loginapple.scss';
import AppleIconwhite from '../../assets/apple-icon-white.png';
import { DOMAIN, APPLE_CLIENT_ID } from '../../constants/base';

const LoginApple = ({ ...rest }) => {
  return (
    <>
      <AppleSignin
        authOptions={{
          clientId: `${APPLE_CLIENT_ID}`,
          redirectURI: `${DOMAIN}/v1/dashboard/product/user/login/sso/apple/callback`,
          scope: 'name email',
          usePopup: false,
          nonce: 'nonce',
        }}
        uiType="dark"
        onSuccess={(response) =>
          console.log('Response from Apple Login', response)
        }
        onError={(error) => console.error('Error from Apple Login', error)}
        {...rest}
        render={(props) => (
          <button className="apple-auth-btn" {...props}>
            <img src={AppleIconwhite}></img>
            <div className="apple-auth-text">CONTINUE WITH APPLE</div>
          </button>
        )}
      />
    </>
  );
};

export default LoginApple;
