import React from 'react';
import LoadingSpin from '../../components/LoadingSpin';
import './statscardheatmap.scss';

const StatsCardHeatmap = ({ icon, graph, xAxis, yAxis, cardsSpinner }) => {
  let newGraphData = graph.map((arr) =>
    arr.map((ele) => (ele === 0 ? 2 : ele + 1))
  );
  return (
    <div className="stats-card-heatmap">
      {cardsSpinner && (
        <div className="pos-abs">
          <div className="loading-card-wrapper">
            <div className="loading-top-wrapper">
              <div className="shimmerBG loading-circle"></div>
              <div className="shimmerBG loading-top-rectangle"></div>
            </div>
            <div className="shimmerBG loading-bottom-heatmap-rectangle"></div>
          </div>
        </div>
      )}
      {!cardsSpinner && (
        <div className="stats-card-heatmap-content">
          <div className="stats-card-heatmap-header">
            <div className="icon-wrapper">
              <img src={icon}></img>
            </div>
            Most Viewed Times
          </div>
          <div className="stats-card-heatmap-graph">
            {yAxis.map((row, index) => (
              <div className="stats-card-heatmap-grid" key={row}>
                <div className="stats-card-heatmap-grid-cell">{row}</div>
                {newGraphData[index].map((value, idx) => (
                  <div
                    key={idx}
                    className="stats-card-heatmap-grid-cell-color"
                    style={{ backgroundColor: `rgba(0,0,0,${value / 100})` }}
                  ></div>
                ))}
              </div>
            ))}
            <div className="stats-card-heatmap-grid">
              <div className="stats-card-heatmap-grid-cell-x-axis"></div>
              {xAxis.map((value) => (
                <div
                  key={value}
                  className="stats-card-heatmap-grid-cell-x-axis"
                >
                  {value}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatsCardHeatmap;
