import React, { useContext, useState } from 'react';
import './popupremovemember.scss';
import { Redirect } from 'react-router-dom';
import { DOMAIN } from '../../constants/base';
import { postReq } from '../../utils/api';
import { getSessionStorage, removeSessionStorage } from '../../utils';
import Avatar from 'react-avatar';
import { EditMemberContext } from '../../context';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';
import Pulse from 'react-reveal/Pulse';
import CheckboxTick from '../../assets/checkbox-tick.svg';

const PopUpRemoveMember = ({
  setRemoveMemberPopUp,
  fetchActiveMembers,
  isUserProfileEdit,
}) => {
   const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
   const userDetails = getSessionStorage('ss-user') || {};
   
  const [currentMember, dispatch] = useContext(EditMemberContext);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { admin } = userDetails;

  const removeMemberHandler = async () => {
    const url = `${DOMAIN}/v1/dashboard/product/user/${
      isUserProfileEdit ? 'delete' : 'remove-member'
    }`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const memberPayload = {
      id: currentMember.currentEditMemberId,
      invitee: currentMember.currentEditMemberInvitee,
    };
    const userPayload = {
      entity: 'product',
    };
    const editResponse = await postReq({
      url,
      payload: isUserProfileEdit ? userPayload : memberPayload,
      requestHeaders,
    });
    const { error = '', successCode = '', data = {} } = editResponse;
    if (error === 'ERR403') {
      // setStatusMsg("Error Removing The Member");
      // setStatusMsgType('error');
      console.log('Error Removing The Member');
      return;
    }
    if (error === 'ERR404') {
      // setStatusMsg('Error Removing The Member');
      // setStatusMsgType('error');
      console.log('Error Removing The Member');
      return;
    }
    if (successCode === 'SUCC200') {
      if (isUserProfileEdit) {
        localStorage.removeItem('ss-auth');
        removeSessionStorage('ss-user');
        setShouldRedirect(true);
      } else {
        setRemoveMemberPopUp(false);
        fetchActiveMembers();
      }
    }
  };

  if (shouldRedirect) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <div className="popups-wrapper">
      <ClickAwayContentWrapper onClose={() => setRemoveMemberPopUp(false)}>
        <Pulse duration="150">
          {isUserProfileEdit ? (
            <div className="remove-card-wrapper">
              <div className="form-wrapper">
                <h1 className="mt-10">
                  Are you sure you want to delete your account?
                </h1>
                <p className="mt-5">
                  Deleting your account as an admin will permanently delete your
                  and your team’s content. This cannot be undone.
                </p>
                <div className="w-100p d-flex vertical-center pos-rel">
                  <input
                    id="checkmark"
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  {isChecked && (
                    <img className="checkbox-tick" src={CheckboxTick}></img>
                  )}
                  <h1 className="checkbox-label">I Understand</h1>
                </div>
                <div className="remove-btn-wrapper mt-70 w-100p">
                  <button
                    className="white"
                    onClick={() => setRemoveMemberPopUp(false)}
                  >
                    GO BACK
                  </button>

                  <button
                    disabled={!isChecked}
                    style={{
                      opacity: isChecked ? 1 : 0.5,
                      cursor: isChecked ? 'pointer' : 'none',
                    }}
                    onClick={removeMemberHandler}
                  >
                    DELETE MY ACCOUNT
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="remove-card-wrapper">
              <div className="form-wrapper">
                {currentMember.currentEditMemberAvatar ? (
                  <div className="image-wrapper">
                    <img src={currentMember.currentEditMemberAvatar}></img>
                  </div>
                ) : (
                  <Avatar
                    name={currentMember.currentEditMemberName}
                    size="142"
                    round={true}
                    color="black"
                    maxInitials={1}
                    textSizeRatio={3}
                    textMarginRatio={0}
                  />
                )}

                <h1 className="mt-30">
                  {admin
                    ? `Are you sure you want to remove ${currentMember.currentEditMemberName} from your
            team?`
                    : "You Don't have the permision to remove members"}
                </h1>
                {admin ? (
                  <p className="mt-20">
                    Your team member will lose access to all content, stats and
                    will no longer be able to make contributions.
                  </p>
                ) : (
                  ''
                )}

                <div className="remove-btn-wrapper mt-70 w-100p">
                  <button
                    className="white"
                    onClick={() => setRemoveMemberPopUp(false)}
                  >
                    GO BACK
                  </button>
                  {admin ? (
                    <button onClick={removeMemberHandler}>REMOVE</button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          )}
        </Pulse>
      </ClickAwayContentWrapper>
    </div>
  );
};

export default PopUpRemoveMember;
