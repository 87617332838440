import React, { useContext } from 'react';
import { StatsContext } from '../../context';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts';
import './barchart.scss';

import NoStatAvailable from '../../assets/no-stats.svg';
import StatOverview from '../StatOverview';

// const testData = [
//   { source: 'amazon', value: 50, views: 10 },
//   { source: 'abc.com', value: 40, views: 9 },
//   { source: 'flip', value: 30, views: 6 },
//   { source: 'okd.com', value: 20, views: 3 },
//   { source: 'others', value: 10, views: 2 },
//   { source: 'sds.com', value: 5, views: 2 },
//   { source: 'asds.com', value: 1, views: 2 },
//   { source: 'amazon', value: 50, views: 10 },
//   { source: 'abc.com', value: 40, views: 9 },
//   { source: 'flip', value: 30, views: 6 },
//   { source: 'okd.com', value: 20, views: 3 },
//   { source: 'others', value: 10, views: 2 },
//   { source: 'sds.com', value: 5, views: 2 },
//   { source: 'asds.com', value: 1, views: 2 },
// ];

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const fireOffset = value <= 5;
  const offset = fireOffset ? -20 : 5;
  return (
    <text
      x={x + width - offset}
      y={y + height - 16}
      fill={fireOffset ? '#000' : '#fff'}
      textAnchor="end"
      fontSize="10px"
      fontWeight="700"
    >
      {value}%
    </text>
  );
};

const BarChartComponent = ({ graph }) => {
  const [statsValue] = useContext(StatsContext);
  if (graph) {
    let minHeight;
    if (graph.length <= 3) {
      minHeight = 170;
    } else {
      minHeight = graph.length * 60;
    }
    if (minHeight < 430 && statsValue.showPopUp) {
      if (statsValue.isDetail === undefined) {
        minHeight = minHeight;
      } else if (statsValue.isDetail) {
        minHeight = 380;
      } else {
        minHeight = 480;
      }
    }
    let op = 1.2;
    if (graph && graph.length < 1) {
      return (
        <div className="stats-card-graph-wrapper">
          <StatOverview
            icon={NoStatAvailable}
            title="No traffic yet"
            msg="All traffic sources accessing your video will be here."
            type="product"
          ></StatOverview>
        </div>
      );
    } else {
      return (
        <div className="stats-card-graph-wrapper">
          {graph && (
            <ResponsiveContainer width="100%" height={minHeight}>
              <BarChart layout="vertical" data={graph}>
                <CartesianGrid
                  strokeDasharray="0"
                  horizontal={true}
                  vertical={false}
                  height={50}
                />
                <XAxis
                  dataKey="value"
                  type="number"
                  tick={{ fontSize: 10, fontWeight: '600' }}
                  strokeWidth={0}
                  domain={[0, 3]}
                />
                <YAxis
                  dataKey="source"
                  type="category"
                  width={80}
                  tick={{ fontSize: 10, fontWeight: '600' }}
                  strokeWidth={0}
                />
                <Bar
                  dataKey="value"
                  barSize={40}
                  fill={`rgba(0,0,0)`}
                  radius={[0, 5, 5, 0]}
                >
                  <LabelList dataKey="value" content={renderCustomizedLabel} />
                  {graph &&
                    graph.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={`rgba(0,0,0, ${(op = (op - 0.2 || 0.2).toFixed(
                          1
                        ))}`}
                      />
                    ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      );
    }
  } else return;
};

export default BarChartComponent;
