import { EDIT_MEMBER, REMOVE_MEMBER } from './types.js';

const editMember = (
  { id, avatar, name, email, admin, phone, verified, invitee, owner },
  state
) => {
  const newCurrentMember = {
    ...state,
    currentEditMemberId: id,
    currentEditMemberAvatar: avatar,
    currentEditMemberName: name,
    currentEditMemberEmail: email,
    currentEditMemberAdmin: admin,
    currentEditMemberPhone: phone,
    currentEditMemberVerified: verified,
    currentEditMemberInvitee: invitee,
    currentEditMemberOwner: owner,
  };
  return {
    ...newCurrentMember,
  };
};

const removeMember = ({ id, avatar, name, invitee }, state) => {
  const newCurrentMember = {
    ...state,
    currentEditMemberId: id,
    currentEditMemberAvatar: avatar,
    currentEditMemberName: name,
    currentEditMemberInvitee: invitee,
  };
  return {
    ...newCurrentMember,
  };
};

export default (state, action) => {
  switch (action.type) {
    case EDIT_MEMBER:
      return editMember(action.payload, state);
    case REMOVE_MEMBER:
      return removeMember(action.payload, state);
    default:
      return state;
  }
};
