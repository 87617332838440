import React, { useState, useEffect } from 'react';
import './mySettings.scss';
import { Redirect, useHistory } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import { getSessionStorage, setSessionStorage } from '../../utils';
import { isEmpty } from '../../utils';
import { DOMAIN } from '../../constants/base';
import { getReq, putReq } from '../../utils/api';
import SwitchAtom from '../../components/SwitchAtom';
import { Fade } from 'react-reveal';

//Assets
import CopyCode from '../../assets/copy-code.svg';
import PlayBtn from '../../assets/playbtn.svg';
import squarespace from '../../assets/squarespacevideo.png';
import wix from '../../assets/wixvideo.png';
import shopify from '../../assets/shopifyvideo.png';
import webflow from '../../assets/webflowvideo.png';
import WidgetDemoImg from '../../assets/products-size.png';
import BottomMarker from '../../assets/bottommarker.png';
import SideMarker from '../../assets/sidemarker.png';
import EyeIconGrey from '../../assets/eye-icon-grey.png';
import Checked from '../../assets/Vector 316checked.svg';
import Eye from '../../assets/eye-svg.svg';

// External links for tutorials
import {
  TUTORIAL_LINK,
  WIX_LINK,
  SQUARE_SPACE_LINK,
  SHOPIFY_LINK,
  WEBFLOW_LINK,
} from '../../constants/base';

//Core component functionality
function MySettings() {
  const [embedCode, setEmbedCode] = useState([]);
  const [isEmbedCodeLoading, setEmbedCodeLoading] = useState(true);
  const [isCodeCopied, setCodeCopied] = useState(false);
  const [widgetStatus, setWidgetStatus] = useState([]);
  const [isStatusLoading, setStatusLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};
  const userDetails = getSessionStorage('ss-user') || {};

  const [widgetSettings, setWidgetSettings] = useState({
    address: true,
    position: false,
    price: true,
    view: true,
    bottomPX: 0,
    sidePX: 0,
    widgetSize: 's',
    hashTag: true,
  });
  const [widgetUpdating, setWidgetUpdating] = useState(false);
  const [opacityHanlderBottom, setOpacityHanlderBottom] = useState(false);
  const [opacityHanlderSide, setOpacityHanlderSide] = useState(false);

  const { portfolioVisibility } = userDetails;

  // Portfolio Visibility State
  const [portfolioVisible, setPortfolioVisible] = useState(portfolioVisibility);

  const isMountedRef = React.useRef(null);

  //Redirect if no userfound
  useEffect(() => {
    isMountedRef.current = true;
    if (isEmpty(userToken)) {
      setShouldRedirect(true);
      return;
    }
    fetchtEmbedCodeData();
    // fetchWidgetStatus();
    fetchTVProperties();
    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isStatusLoading) {
        updateTVPostions();
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [widgetSettings.bottomPX, widgetSettings.sidePX]);

  if (shouldRedirect) {
    return <Redirect to="/signin" />;
  }
  //Fetching code widget data
  async function fetchtEmbedCodeData() {
    const url = `${DOMAIN}/v1/dashboard/product/embed-code`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const embedCodeResponse = await getReq({
      url,
      requestHeaders,
    });
    const { successCode, data } = embedCodeResponse;
    if (successCode === 'SUCC200' && isMountedRef.current) {
      setEmbedCode(data.result);
      setEmbedCodeLoading(false);
      formatCode();
    }
  }
  //Fetching installation status
  async function fetchWidgetStatus() {
    const url = `${DOMAIN}/v1/dashboard/isLive`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const statusResponse = await getReq({
      url,
      requestHeaders,
    });
    const { successCode, data } = statusResponse;
    if (successCode === 'SUCC200' && isMountedRef.current) {
      setWidgetStatus(data);
      setStatusLoading(false);
    }
  }
  //fetching tv properties
  async function fetchTVProperties() {
    const url = `${DOMAIN}/v1/dashboard/account/widget-settings/product`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const statusResponse = await getReq({
      url,
      requestHeaders,
    });
    const { successCode, data } = statusResponse;
    if (successCode === 'SUCC200' && isMountedRef.current) {
      setWidgetSettings(data.widgetSettings);
    }
  }

  async function updateTVProperties(widgetObj) {
    setWidgetUpdating(true);
    const url = `${DOMAIN}/v1/dashboard/account/widget-settings/product`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const statusResponse = await putReq({
      url,
      payload: {
        ...widgetObj,
        bottomPX:
          widgetObj.bottomPX === '' || widgetObj.bottomPX === undefined
            ? 70
            : parseInt(widgetObj.bottomPX),
        sidePX:
          widgetObj.sidePX === '' || widgetObj.sidePX === undefined
            ? 10
            : parseInt(widgetObj.sidePX),
      },
      requestHeaders,
    });
    const { successCode } = statusResponse;
    if (successCode === 'SUCC200') {
      setWidgetUpdating(false);
      return true;
    }
    return false;
  }

  async function updateTVPostions() {
    setWidgetUpdating(true);
    const url = `${DOMAIN}/v1/dashboard/account/widget-settings/product`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    let payload = {
      sidePX:
        widgetSettings.sidePX === '' || widgetSettings.bottomPX === undefined
          ? 10
          : parseInt(widgetSettings.sidePX),
      bottomPX:
        widgetSettings.bottomPX === '' || widgetSettings.bottomPX === undefined
          ? 70
          : parseInt(widgetSettings.bottomPX),
    };
    const statusResponse = await putReq({
      url,
      payload,
      requestHeaders,
    });
    const { successCode } = statusResponse;
    if (successCode === 'SUCC200') {
      setWidgetUpdating(false);
      return true;
    }
    return false;
  }

  //Formatting the fetched response
  const formatCode = () => {
    let pre = document.getElementsByTagName('pre');
    let preLength = pre.length;
    for (let i = 0; i < preLength; i++) {
      pre[i].innerHTML =
        '<span class="line-number"></span>' +
        pre[i].innerHTML +
        '<span class="cl"></span>';
      let num = pre[i].innerHTML.split(/\n/).length;
      for (let j = 0; j < num; j++) {
        let lineNumber = pre[i].getElementsByTagName('span')[0];
        lineNumber.innerHTML += '<span>' + (j + 1) + '</span>';
      }
    }
  };

  // Handling Number Inputs And Limiting To 3 digits
  const handleNumberChangeBottom = (e) => {
    if (e.target.value.length) {
      setOpacityHanlderBottom(true);
    } else {
      setOpacityHanlderBottom(false);
    }
    setWidgetSettings({
      ...widgetSettings,
      bottomPX:
        e.target.value.length <= 2
          ? !isNaN(e.target.value)
            ? e.target.value
            : widgetSettings.bottomPX
          : widgetSettings.bottomPX,
    });
  };
  const handleNumberChangeSide = (e) => {
    if (e.target.value.length) {
      setOpacityHanlderSide(true);
    } else {
      setOpacityHanlderSide(false);
    }
    setWidgetSettings({
      ...widgetSettings,
      sidePX:
        e.target.value.length <= 2
          ? // e.target.value.length <= 2
            !isNaN(e.target.value)
            ? e.target.value
            : widgetSettings.sidePX
          : widgetSettings.sidePX,
    });
  };
  //Copying the code
  const doCopyCode = (e) => {
    navigator.clipboard.writeText(embedCode).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
        setCodeCopied(true);
        setTimeout(() => {
          setCodeCopied(false);
        }, 3000);
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  const handleWidgetSettings = (pos, val) => {
    let newWidgetSettings = { ...widgetSettings };
    const oldWidgetSettings = { ...widgetSettings };
    switch (pos) {
      case 'position':
        const newPosition = val === 'left' ? true : false;
        newWidgetSettings.position = newPosition;
        break;
      default:
        newWidgetSettings[pos] = !newWidgetSettings[pos];
        break;
    }
    setWidgetSettings(newWidgetSettings);
    updateTVProperties(newWidgetSettings).then((res) => {
      if (!res) {
        setWidgetSettings(oldWidgetSettings);
      }
    });
  };

  async function updateWidgetSize(size) {
    setWidgetSettings({
      ...widgetSettings,
      widgetSize: size,
    });
    const url = `${DOMAIN}/v1/dashboard/account/widget-settings/product`;
    const requestHeaders = {
      authorization: userToken.accessToken,
    };
    const statusResponse = await putReq({
      url,
      payload: {
        widgetSize: size,
      },
      requestHeaders,
    });
    const { successCode } = statusResponse;
    if (successCode === 'SUCC200') {
      return true;
    }
    return false;
  }

  return (
    <div className="d-flex main-dash-container">
      <NavBar selected="mySettings" />
      <div className="content-width d-flex">
        <Fade duration={200}>
          <aside className="d-flex asideContainer hideAlert mt-20 ml-20">
            <div className="pr-20">
              <div className="d-flex vertical-center mb-20 fw-900 fs-18 mt-20">
                TV Settings
              </div>

              <div className="fw-900 fs-24 mt-43">
                <div className="d-flex f-wrap f-col w-100p">
                  <div className="widget-wrapper">
                    {!userDetails.admin && (
                      <div
                        title="Requires admin access"
                        className="disable-settings"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      ></div>
                    )}
                    <h1 className="">Customise Widget</h1>
                    <div className="mt-20 br-10 bg-sgray pt-15 pl-20 pr-20 pb-15 customise-wrapper">
                      <h1>DESKTOP WIDGET SIZE</h1>
                      <div className="d-flex widget-size-container space-between">
                        <div className="d-flex flex-col justify-end vertical-center">
                          <span className="widget-size-h">S</span>
                          <div className="widget-size-img-container">
                            <img src={WidgetDemoImg} alt="Widget Sample" />
                          </div>
                          <div
                            onClick={() => updateWidgetSize('s')}
                            className="widget-custom-checkbox"
                          >
                            {widgetSettings.widgetSize === 's' && (
                              <div>
                                <img src={Checked} alt="Checked" />
                              </div>
                            )}
                          </div>
                          <h4 className="widget-dimension">96x130px</h4>
                        </div>
                        <div className="d-flex flex-col justify-end vertical-center">
                          <span className="widget-size-h">M</span>
                          <div className="widget-size-img-container">
                            <img src={WidgetDemoImg} alt="Widget Sample" />
                          </div>
                          <div
                            onClick={() => updateWidgetSize('m')}
                            className="widget-custom-checkbox"
                          >
                            {widgetSettings.widgetSize === 'm' && (
                              <div>
                                <img src={Checked} alt="Checked" />
                              </div>
                            )}
                          </div>
                          <h4 className="widget-dimension">145x236px</h4>
                        </div>
                        <div className="d-flex flex-col justify-end vertical-center">
                          <span className="widget-size-h">L</span>
                          <div className="widget-size-img-container">
                            <img src={WidgetDemoImg} alt="Widget Sample" />
                          </div>
                          <div
                            onClick={() => updateWidgetSize('l')}
                            className="widget-custom-checkbox"
                          >
                            {widgetSettings.widgetSize === 'l' && (
                              <div>
                                <img src={Checked} alt="Checked" />
                              </div>
                            )}
                          </div>
                          <h4 className="widget-dimension">190x358px</h4>
                        </div>
                      </div>
                      <div className="position-wrapper mt-25 d-flex f-col space-between">
                        <div>
                          <h1>POSITION</h1>
                          <div className="toggle-wrapper d-flex mt-5">
                            <button
                              className={`${
                                widgetSettings.position && 'active'
                              } toggle`}
                              data-id="position"
                              onClick={() =>
                                handleWidgetSettings('position', 'left')
                              }
                            >
                              LEFT
                            </button>
                            <button
                              className={`${
                                !widgetSettings.position && 'active'
                              } toggle`}
                              data-id="position"
                              onClick={() =>
                                handleWidgetSettings('position', 'right')
                              }
                            >
                              RIGHT
                            </button>
                          </div>
                        </div>
                        {/* <div className="property d-flex space-between mb-2">
                        <div className="d-flex f-col">
                          <h1 className="views-heading">Show Views</h1>
                          <h3 className="views-wrapper">
                            {" "}
                            <img src={EyeIconGrey}></img>111
                          </h3>
                        </div>

                        <SwitchAtom
                          toggleVal={widgetSettings.view}
                          handleToggle={() => handleWidgetSettings("view")}
                        ></SwitchAtom>
                      </div> */}
                        <div className="pos-input-wrapper d-flex mt-20">
                          <div className="pos-wrapper">
                            <h1>
                              BOTTOM <img src={BottomMarker}></img>
                            </h1>
                            <div className="inpt-container">
                              <input
                                className="inpt mt-5"
                                type="text"
                                maxlength="3"
                                placeholder="100"
                                defaultValue={'0'}
                                value={widgetSettings.bottomPX}
                                onChange={(e) => handleNumberChangeBottom(e)}
                              ></input>
                              <span
                                style={{
                                  opacity: opacityHanlderBottom ? 1 : 0.2,
                                }}
                              >
                                PX
                              </span>
                            </div>
                          </div>
                          <div className="pos-wrapper ml-5">
                            <h1>
                              <img src={SideMarker}></img> SIDE
                            </h1>
                            <div className="inpt-container">
                              <input
                                className="inpt mt-5"
                                type="text"
                                maxlength="3"
                                placeholder="100"
                                value={widgetSettings.sidePX}
                                onChange={(e) => handleNumberChangeSide(e)}
                              ></input>
                              <span
                                style={{
                                  opacity: opacityHanlderSide ? 1 : 0.2,
                                }}
                              >
                                PX
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tvproperties-wrapper mt-40">
                      <div className="properties-wrapper">
                        <div className="property space-between d-flex">
                          <div>
                            <h1>Show Product Name</h1>
                            <h2 className="mt-8">e.g. Airpods Max</h2>
                          </div>
                          <SwitchAtom
                            toggleVal={widgetSettings.name}
                            handleToggle={() => handleWidgetSettings('name')}
                          ></SwitchAtom>
                        </div>
                        <div className="property d-flex space-between mt-20">
                          <div>
                            <h1>Show Price</h1>
                            <h2 className="mt-8">$900</h2>
                          </div>
                          <SwitchAtom
                            toggleVal={widgetSettings.price}
                            handleToggle={() => handleWidgetSettings('price')}
                          ></SwitchAtom>
                        </div>
                        <div className="property d-flex mt-20 space-between">
                          <div>
                            <h1>Show Views</h1>
                            <h2 className="mt-8">
                              <img src={Eye} alt="eye" />
                              &nbsp; 111
                            </h2>
                          </div>
                          <SwitchAtom
                            toggleVal={widgetSettings.view}
                            handleToggle={() => handleWidgetSettings('view')}
                          ></SwitchAtom>
                        </div>
                        <div className="property d-flex mt-20 space-between">
                          <div>
                            <h1>#Hashtag Navigation</h1>
                            <div className="beta-feature">(BETA)</div>
                          </div>
                          <SwitchAtom
                            toggleVal={widgetSettings.hashTag}
                            handleToggle={() => handleWidgetSettings('hashTag')}
                          ></SwitchAtom>
                        </div>
                      </div>
                    </div>
                    {/* <div className="d-flex mt-10 space-between">
                    <div className="widget-image mt-10">
                      <img
                        src={WidgetDemoImg}
                        style={{ opacity: widgetUpdating ? 0.4 : 1 }}
                      ></img>
                      {widgetUpdating && (
                        <div className="loader">
                          <LoadingSpinner h="20px"></LoadingSpinner>
                        </div>
                      )}
                    </div>
                  </div> */}
                  </div>
                  {/* <div className="logo-wrapper mt-30">
                  <h1>Logo</h1>
                  <div className="d-flex mt-20">
                    <div className="droplogo-wrapper">
                      <button>
                        <img src=""></img>
                      </button>
                    </div>
                    <div className="details-wrapper ml-20">
                      <div className="detail">
                        <h1>MAXIMUM SIZE</h1>
                        <span>300 x 300 px</span>
                      </div>
                      <div className="detail mt-10">
                        <h1>FILE TYPE</h1>
                        <span>PNG Transparent</span>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </aside>
          <div
            className="w-100p ml-25 mt-20 mr-20"
            style={{ minWidth: '650px' }}
          >
            <div className="d-flex fs-18 fw-900 mb-43 mt-20">TV Snippet</div>
            {/* {isStatusLoading? (
                  <div className="d-flex f-col center-align h-100vh">
                  Getting the installation status & code of your widget..
                  </div>
                  ):(
                  <div>
                  {widgetStatus ? ( 
                    <div className="cur-pointer d-flex center-align fs-14 bg-alert-green br-5 mb-20 fw-900 c-white alertBox" > 
                    <img src={Tick} className="pr-12"></img>
                    The video widget has been installed in your website or web app
                  </div>
                  ) :( 
                    <div className="cur-pointer d-flex center-align fs-14 pr-20 bg-alert-yellow br-5 mb-20 fw-900 c-white alertBox"> 
                    <img src={Alert} className="pl-20 pr-12"></img>
                    The video widget has not been installed in your website or web app
                  </div>
                  )}   
                </div>
                )}  */}
            {isEmbedCodeLoading ? (
              <div className="d-flex f-col center-align h-100vh">
                Getting the code widget to copy..
              </div>
            ) : (
              <div className="d-flex pos-rel fs-12">
                <pre className="code w-100p">
                  <code>{embedCode}</code>
                </pre>
                <div
                  className="pos-abs d-flex center-align bg-white c-black p-10 w-120 cc-btn"
                  onClick={doCopyCode}
                >
                  <div className="cur-pointer d-flex center-align">
                    <img src={CopyCode} className="pr-12" alt="Copy Icon"></img>
                    {isCodeCopied ? 'COPIED' : 'COPY CODE'}{' '}
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex fs-18 fw-900 mb-30 mt-20">
              Installation Tutorials
            </div>
            <div className="space-between d-flex">
              <div
                className="tutorial-card"
                style={{ backgroundImage: `url(${squarespace})` }}
              >
                {/* squarespace */}
                <a
                  href="https://videos.products.holofy.io/accountWidget/index-lgodub0xa9.html?refNo=2919&fullScreenView=true"
                  target="_blank"
                >
                  <img className="mr-10" src={PlayBtn}></img>PLAY VIDEO
                </a>
              </div>
              <div
                className="tutorial-card ml-20"
                style={{ backgroundImage: `url(${webflow})` }}
              >
                <a
                  href="https://videos.products.holofy.io/accountWidget/index-lgodub0xa9.html?refNo=2917&fullScreenView=true"
                  target="_blank"
                >
                  <img className="mr-10" src={PlayBtn}></img>PLAY VIDEO
                </a>
              </div>
              <div
                className="tutorial-card ml-20"
                style={{ backgroundImage: `url(${wix})` }}
              >
                <a
                  href="https://videos.products.holofy.io/accountWidget/index-lgodub0xa9.html?refNo=2918&fullScreenView=true"
                  target="_blank"
                >
                  <img className="mr-10" src={PlayBtn}></img>PLAY VIDEO
                </a>
              </div>
              <div
                className="tutorial-card ml-20"
                style={{ backgroundImage: `url(${shopify})` }}
              >
                <a
                  href="https://videos.products.holofy.io/accountWidget/index-lgodub0xa9.html?refNo=2916&fullScreenView=true"
                  target="_blank"
                >
                  <img className="mr-10" src={PlayBtn}></img>PLAY VIDEO
                </a>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default MySettings;
