import { loadStripe } from '@stripe/stripe-js';
import { postReq } from './api';


const stripeKey =
  process.env.REACT_APP_ENV === 'production'
    ? `${process.env.REACT_APP_STRIPE_KEY_PROD}`
    : `${process.env.REACT_APP_STRIPE_KEY_STAGING}`;


const stripePromise = loadStripe(stripeKey);

export const handleSubscription = async (
  domain, userToken, userDetails
) => {
  const DOMAIN = domain;
  const stripe = await stripePromise;
  const url = `${DOMAIN}/v1/dashboard/stripe/create-checkout-session`;
  const requestHeaders = {
    authorization: userToken.accessToken,
  };
  const payload = {
    priceId: 'pay_as_you_go',
    entity: 'product',
    email: userDetails.email,
  };

  const response = await postReq({
    url,
    payload,
    requestHeaders,
  });

  // When the customer clicks on the button, redirect them to Checkout.
  const result = await stripe.redirectToCheckout({
    sessionId: response.sessionId,
  });

  if (result.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
  }
};