import React, { useState } from 'react';
import Input from '../../components/Input';
import ButtonAtom from '../../components/ButtonAtom';
import { isEmpty } from '../../utils';
import { DOMAIN } from '../../constants/base';
import { postReq } from '../../utils/api';
import './marketingpage.scss';
import { toast } from 'react-toastify';

const MarketingPage = () => {
  const userToken = JSON.parse(localStorage.getItem('ss-auth')) || {};

  const [key, setKey] = useState('');
  const [templateID, setTemplateID] = useState('');
  const [entity, setEntity] = useState('Product');

  const [btnDisabled, setBtnDisabled] = useState(false);

  //   Errors
  const [keyErr, setKeyErr] = useState(false);
  const [templateIdErr, setTemplateIdErr] = useState(false);

  // Blur Handler
  const keyBlurHandler = () => {
    if (isEmpty(key)) {
      setKeyErr(true);
    }
  };
  const templateIDBlurHandler = () => {
    if (isEmpty(templateID)) {
      setTemplateIdErr(true);
    }
  };

  const sendData = async () => {
    setBtnDisabled(true);
    if (!isEmpty(key) && !isEmpty(entity)) {
      const url = `${DOMAIN}/v1/dashboard/account/send-email`;
      let payload = {
        key,
        templateID,
        entity,
      };

      const requestHeaders = {
        authorization: userToken.accessToken,
      };
      const emailResp = await postReq({
        url,
        payload,
        requestHeaders,
      });
      const { error = '', successCode = '', data = {} } = emailResp;
      console.log(error, successCode, data);
      if (successCode === 'SUCC200') {
        setBtnDisabled(!btnDisabled);
      }
      if (error === 'ERR401') {
        setBtnDisabled(!btnDisabled);
        toast('Error While Sending Email', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } else {
      if (isEmpty(key)) {
        setKeyErr(true);
      }
      if (isEmpty(templateID)) {
        setTemplateIdErr(true);
      }
    }
  };
  return (
    <div className="markeing-page-container">
      <h1>Marketing</h1>
      <div className="marketing-form">
        <Input
          type="text"
          label="KEY"
          labelStyle="uc mb-5"
          placeholder="Enter Key"
          value={key}
          onChange={(e) => setKey(e.target.value)}
          info={keyErr ? "CAN'T BE BLANK" : ''}
          onBlur={keyBlurHandler}
        />
        <Input
          type="text"
          label="TEMPLATE ID"
          labelStyle="uc mb-5"
          placeholder="Enter Tempalte Id"
          value={templateID}
          onChange={(e) => setTemplateID(e.target.value)}
          info={templateIdErr ? "CAN'T BE BLANK" : ''}
          onBlur={templateIDBlurHandler}
        />
        <div className="select-input d-flex f-col mt-5">
          <label className="d-ib fs-8 fw-900 uc">Entity</label>
          <select
            name="entity"
            id="entity"
            value={entity}
            onChange={(e) => setEntity(e.target.value)}
          >
            <option key={'Product'} value={'Product'}>
              PRODUCT
            </option>
            <option key={'Space'} value={'Space'}>
              SPACE
            </option>
          </select>
        </div>
        <div className="mt-10">
          <ButtonAtom
            label="SEND"
            onClick={sendData}
            disabled={btnDisabled}
          ></ButtonAtom>
        </div>
      </div>
    </div>
  );
};

export default MarketingPage;
