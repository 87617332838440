import React from 'react';
import './progressbar.scss';

const ProgressBar = ({ percentage, min, max }) => {
  let widt = (percentage - min) * 136 / (max-min);
  return (
    <div className="progress-bar-wrapper">
      <div
        className="current-progress"
        style={{ width: `${widt}px`}}
      ></div>
    </div>
  );
};

export default ProgressBar;
