import React from 'react';
import './statscardgraph.scss';

// assets
import WorldIcon from '../../assets/traffic-stat.svg';
import BarChartComponent from '../BarChart';
import RightArrIcon from '../../assets/right-arr.png';

const StatsCardGraph = ({ graph, handleClick, cardsSpinner }) => {
  return (
    <div
      className="stats-card-graph"
      onClick={cardsSpinner ? '' : handleClick}
      style={{
        cursor: cardsSpinner ? 'not-allowed' : 'pointer',
        background: cardsSpinner
          ? 'rgba(255, 255, 255, 0.5)'
          : 'rgba(255, 255, 255, 0.72)',
      }}
    >
      {cardsSpinner && (
        <div className="pos-abs">
          <div className="loading-card-wrapper-graph">
            <div className="loading-top-wrapper">
              <div className="shimmerBG loading-circle"></div>
              <div className="shimmerBG loading-top-rectangle"></div>
            </div>
            <div className="shimmerBG loading-bottom-rectangle"></div>
          </div>
        </div>
      )}
      {!cardsSpinner && (
        <div className="stats-card-graph-content">
          <div className="stats-card-graph-header">
            <div className="d-flex vertical-center">
              <div className="icon-wrapper">
                <img src={WorldIcon}></img>
              </div>
              <div className="stats-card-graph-header-title">
                Top Traffic Sources
              </div>
            </div>
            <img
              className="stats-card-graph-header-img"
              src={RightArrIcon}
            ></img>
          </div>
          <BarChartComponent graph={graph}> </BarChartComponent>
        </div>
      )}
    </div>
  );
};

export default StatsCardGraph;
