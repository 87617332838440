import React, { useState } from 'react';
import './memberitem.scss';
import { Zoom } from 'react-reveal';
import Avatar from 'react-avatar';
import { getSessionStorage } from '../../utils';
import ClickAwayListener from 'react-click-away-listener';

// Assets
import SettingIcon from '../../assets/Settings.png';
import ProductsInfoIcon from '../../assets/products-info-icon.png';
import EditIcon from '../../assets/edit-icon.png';
import AdminIcon from '../../assets/change-membertype-icon.png';
import RemoveIcon from '../../assets/remove-icon.png';
import CloseBtnIcon from '../../assets/close-btn-white.png';

const MemberItem = ({
  onClick,
  isMemberAdmin,
  name,
  verified,
  totalProducts,
  handleEditMember,
  handleRemoveMember,
  handleRole,
  avatar,
  owner,
}) => {
  
  const userDetails = getSessionStorage('ss-user') || {};
   
  const { admin } = userDetails;

  const [showSettingMenu, setShowSettingMenu] = useState(false);
  const handleClickAway = () => {
    setShowSettingMenu(false);
  };
  return (
    <div
      className={
        verified
          ? 'member-item-wrapper mt-20 member-item-veri'
          : 'member-item-wrapper mt-20 member-item-not-veri'
      }
    >
      {admin && !owner ? (
        <>
          {' '}
          <button
            className="setting-btn"
            onClick={() => setShowSettingMenu(!showSettingMenu)}
          >
            <img src={showSettingMenu ? CloseBtnIcon : SettingIcon}></img>
          </button>
          {showSettingMenu ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Zoom center duration={50}>
                <div className="setting-menu">
                  <button onClick={handleEditMember}>
                    <img className="mr-10" src={EditIcon}></img> EDIT DETAILS
                  </button>
                  {verified && !owner && (
                    <button onClick={handleRole}>
                      <img className="mr-10" src={AdminIcon}></img>{' '}
                      {!isMemberAdmin ? `MAKE ADMIN` : `MAKE MEMBER`}
                    </button>
                  )}

                  <button onClick={handleRemoveMember}>
                    <img className="mr-10" src={RemoveIcon}></img> REMOVE MEMBER
                  </button>
                </div>
              </Zoom>
            </ClickAwayListener>
          ) : (
            ''
          )}
        </>
      ) : (
        !verified && (
          <>
            {' '}
            <button
              className="setting-btn"
              onClick={() => setShowSettingMenu(!showSettingMenu)}
            >
              <img src={showSettingMenu ? CloseBtnIcon : SettingIcon}></img>
            </button>
            {showSettingMenu ? (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Zoom center duration={50}>
                  <div className="setting-menu">
                    <button onClick={handleEditMember}>
                      <img className="mr-10" src={EditIcon}></img> EDIT DETAILS
                    </button>
                    {verified && !owner && (
                      <button onClick={handleRole}>
                        <img className="mr-10" src={AdminIcon}></img>{' '}
                        {!isMemberAdmin ? `MAKE ADMIN` : `MAKE MEMBER`}
                      </button>
                    )}

                    <button onClick={handleRemoveMember}>
                      <img className="mr-10" src={RemoveIcon}></img> REMOVE
                      MEMBER
                    </button>
                  </div>
                </Zoom>
              </ClickAwayListener>
            ) : (
              ''
            )}
          </>
        )
      )}

      <div
        className="member-item"
        typeof="button"
        onClick={admin && !owner ? onClick : !verified && onClick}
        style={{ opacity: verified ? 1 : 0.3 }}
      >
        {avatar ? (
          <img src={avatar} style={{ objectFit: 'cover' }}></img>
        ) : (
          <Avatar
            name={name}
            value=""
            size="58"
            round={true}
            color="black"
            maxInitials={1}
            textSizeRatio={2}
            style={{ fontWeight: '700' }}
          />
        )}

        <div className="content">
          <h1>{name}</h1>
          <span
            className={
              isMemberAdmin && verified
                ? owner
                  ? 'bg-blue'
                  : 'bg-black'
                : !verified
                ? 'bg-black'
                : 'bg-lgray'
            }
          >
            {isMemberAdmin
              ? verified
                ? owner
                  ? 'OWNER'
                  : 'ADMIN'
                : 'INVITATION PENDING'
              : verified
              ? 'MEMBER'
              : 'INVITATION PENDING'}
          </span>
          <div className="products-info">
            <img src={ProductsInfoIcon}></img> {totalProducts}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberItem;
