import React, { useRef } from 'react';
import VideoMp4 from '../../assets/dashvideo.mp4';
import VideoWebm from '../../assets/dashvideo.webm';
import dashFallbackImage from '../../assets/dashVideoFallbackImage.jpeg';
import './video.scss';

const VideoAtom = ({ FallbackImg }) => {
  const videoParentRef = useRef();
  return (
    <video
      ref={videoParentRef}
      autoPlay
      loop
      muted
      playsInline
      preload="auto"
      className="video-component"
    >
      <source src={VideoMp4} type="video/mp4"></source>
      <source src={VideoWebm} type="video/webm"></source>
      <img
        src={FallbackImg ? FallbackImg : dashFallbackImage}
        title="Your browser does not support the <video> tag"
        alt="Fallback for video"
      />
    </video>
  );
};

export default VideoAtom;
